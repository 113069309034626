import { getYear } from 'date-fns'
import sanitizeHtml from "sanitize-html"
import { Config, Entreprise, Reference, ReferenceIDCC, References, Service } from "./referencesAPI"
import store from "app/store"
import { RootState } from 'app/rootReducer'


export function references(): References | null {
    return store.getState().referencesState.fulfilled
}

export function referencesIdcc(): ReferenceIDCC[] | null {
    return store.getState().referencesIdccState.fulfilled
}

export function findService(state: RootState, id: string): Service {
    return state.referencesState.fulfilled?.SEL_SERVICE_EN_LIGNE?.find(s => { return id === s.Id })!
}

export function findCivilite(id: string): Reference | undefined {
    return references()?.REF_CIVILITE?.find(ref => { return id === ref.Id })
}

export function findCSP(id: string): Reference | undefined {
    return references()?.REF_TYPE_CSP?.find(ref => { return id === ref.Id })
}

export function findCategorieAction(id: string): Reference | undefined {
    return references()?.REF_CATEGORIE_ACTION?.find(ref => { return id === ref.Id })
}

export function findNatureContrat(id: string): Reference | undefined {
    return references()?.REF_NATURE_CONTRAT?.find(ref => { return id === ref.Id })
}

export function findTypeDossier(id: string): Reference | undefined {
    return references()?.REF_TYPE_DOSSIER?.find(ref => { return id === ref.Id })
}

export function getAllDispositifFO(): Reference[] | undefined {
    return references()?.REF_SUPPORT_FO
}

export function findDispositifFO(id: string): Reference | undefined {
    return getAllDispositifFO()?.find(ref => { return id === ref.Id })
}

export function getAllEtatDossierFO(): Reference[] | undefined {
    return references()?.REF_ETAT_DOSSIER_FO
}

export function findEtatDossierFO(id: string): Reference | undefined {
    return getAllEtatDossierFO()?.find(ref => { return id === ref.Id })
}

export function getAllTypeFormation(): Reference[] | undefined {
    return references()?.REF_TYPE_FORMATION
}

export function findTypeFormation(id: string): Reference | undefined {
    return getAllTypeFormation()?.find(ref => { return id === ref.Id })
}

export function getAllStatutFactureFO(): Reference[] | undefined {
    return references()?.REF_ETAT_FACTURE_FO
}

export function findEtatFactureFO(id: string): Reference | undefined {
    return references()?.REF_ETAT_FACTURE_FO?.find(ref => { return id === ref.Id })
}

export function findEmetteurType(id: string): Reference | undefined {
    return references()?.REF_TYPE_OF?.find(ref => { return id === ref.Id })
}

export function findTypeFacture(id: string): Reference | undefined {
    return references()?.REF_TYPE_DOSSIER?.find(ref => { return id === ref.Id })
}

export function findDiplome(id: string, refs?: References): Reference | undefined {
    return (refs ?? references())?.REF_DIPLOME.find(ref => { return id === ref.Id })
}

export function findModeReglement(id: string): Reference | undefined {
    return references()?.REF_TYPE_MODEREGLEMENT.find(ref => { return id === ref.Id })
}

export function getAllModeReglement(): Reference[] | undefined {
    return references()?.REF_TYPE_MODEREGLEMENT
}

export function getAllTypeCampus(): Reference[] | undefined {
    return references()?.REF_CAMPUS_TYPE
}

export function getAllTypeModalatiteCampus(): Reference[] | undefined {
    return references()?.REF_CAMPUS_MODALITE
}

export function getAllActivePays(): Reference[] | undefined {
    return references()?.REF_PAYS.filter(pays => pays.IsActiveFO__c).sort((p1, p2) => p1.Libelle__c.localeCompare(p2.Libelle__c))
}

export function configStore(): Config | null {
    return store.getState().configState.fulfilled
}

export function findDispositifsEligiblesParBranche(entreprise?: Entreprise | null): string[] {
    if (entreprise === undefined || entreprise === null)
        return []
    const entrepriseDispositifs = configStore()?.DispositifsEligiblesParBranche?.find(dispositif => {
        return dispositif.BrancheId === entreprise.Branche__c
    })
    return (entrepriseDispositifs?.DispositifFOIds ?? [])
}

export function sanitizeHtmlEditor(valueHtml: any ): string {
    return sanitizeHtml(valueHtml, {
        allowedTags: ["ol", "li", "ul", "h1", "h2", "h3", "h4", "h5", "h6", "p", "b", "span", "a", "strong", "i", "em", "u"],
        allowedAttributes: {
            'span': ["style"],
        },
        allowedStyles: {
            '*': {
                'text-decoration': [/^underline$/],
            }
        }
    })
}


export const DISPOSITIF_PDC = '1'
export const DISPOSITIF_ACN = '2'
export const DISPOSITIF_CDIC = '3'
export const DISPOSITIF_VAE = '4'
export const DISPOSITIF_PROA = '5'
export const DISPOSITIF_CONTRAT_PROFESSIONNALISATION = '6'
export const DISPOSITIF_CONTRAT_PROFESSIONNALISATION_X = '30'
export const DISPOSITIF_TUT = '7'
export const DISPOSITIF_CONTRAT_APPRENTISSAGE = '8'
export const DISPOSITIF_FMA = '9'
// export const DISPOSITIF_DIAG_IND = '10'
// export const DISPOSITIF_DIAG_COLL = '11'
export const DISPOSITIF_PACC_RH = '12'
export const DISPOSITIF_CSCA = '13'
export const DISPOSITIF_CSCM = '14'
export const DISPOSITIF_BILAN_COMPETENCE = '15'
export const DISPOSITIF_TPE_PME = '20'
export const DISPOSITIF_VAEI = '31'

export const ETAT_DOSSIER_BROUILLON = '1'
export const ETAT_DOSSIER_EN_COURS = '2'
export const ETAT_DOSSIER_INCOMPLET = '3'
export const ETAT_DOSSIER_ACCORDE = '4'
export const ETAT_DOSSIER_REFUSE = '5'
export const ETAT_DOSSIER_CLOTURE = '6'
export const ETAT_DOSSIER_RUPTURE = '7'
export const ETAT_DOSSIER_ANNULE = '8'

export const ETAT_DOSSIER_BO_ARRIVE = '1'

export const ETAT_FACTURE_EN_COURS = '1'
export const ETAT_FACTURE_INCOMPLET = '2'
export const ETAT_FACTURE_PAYE = '3'
export const ETAT_FACTURE_REFUSE = '4'
export const ETAT_FACTURE_SUPPRIME = '5'
export const ETAT_FACTURE_SOUMISE = '6'
export const ETAT_FACTURE_A_TRANSMETTRE = '8'

export const TYPE_FACTURE_FACTURE = '1'
export const TYPE_FACTURE_AVOIR = '2'

export const TYPE_EMETTEUR_ADHERENT = '1'
export const TYPE_EMETTEUR_PRESTATAIRE = '2'

export const TYPE_CONTRAT_PREMIER = '9'
export const TYPE_CONTRAT_NOUVEAU1 = '10'
export const TYPE_CONTRAT_NOUVEAU2 = '11'
export const TYPE_CONTRAT_NOUVEAU3 = '12'
export const TYPE_CONTRAT_MODIFICATION_SITUATION_JURIDIQUE = '13'

export const NATURE_CONTRAT_CDI = '1'
export const NATURE_CONTRAT_CDD = ['2', '5', '6']
export const NATURE_CONTRAT_NON_DEFINI = '4'

export const CSP_CADRE = '3'
export const CSP_ETAM = '4'

export const TYPE_FORMATION_INTERNE = '1'
export const TYPE_FORMATION_INTER = '2'
export const TYPE_FORMATION_INTRA = '3'

export const SITUATION_FORMATION_CFA = '23'
export const SITUATION_FORMATION_PRO = '23'

export const TYPE_COUT_PEDAGOGIQUE = '1'
export const TYPE_COUT_REMUNERATION = '2'
export const TYPE_COUT_PRESTATIONS = '12'
export const TYPE_COUT_ACCOMPAGNEMENT_CPROX = '58'
export const TYPE_COUT_FORFAIT = '59'
export const TYPE_COUT_AFEST = '60'

export const TYPE_MONTANT_SMIC = '120'
export const TYPE_MONTANT_SMC = '121'

export const SITUATION_HANDICAP = '124'
export const SITUATION_HANDICAP_NON = '123'

export const RECONNAISSANCE_HANDICAP = '1'

export const HANDICAP_OUI = '1'

export const SITUATION_SALARIE = '5'
export const SITUATION_SALARIE_RECHERCHE_EMPLOI = '6'
export const SITUATION_SALARIE_INACTIF = '7'
export const SITUATION_CAPP = '16'
export const SITUATION_CPRO = '17'

export const TYPE_DEROGATION_MOINS_16ANS = '59'
export const TYPE_DEROGATION_PLUS_29ANS = '60'
export const TYPE_DEROGATION_REDUCTION_DUREE = '61'
export const TYPE_DEROGATION_22 = '62'

export const TYPE_BRANCHE_BUREAU_ETUDE = '22'
export const TYPE_BRANCHE_QUALITE_AIR = '24'
export const TYPE_BRANCHE_AGENTS_GENERAUX_ASSURANCE = '25'
export const TYPE_BRANCHE_COURTIERS_ASSURANCE = '19'
export const TYPE_BRANCHE_ASSISTANCE = '26'
export const TYPE_BRANCHE_ASSURANCE = '18'
export const TYPE_BRANCHE_CREDIT_MUTUEL = '10'
export const TYPE_BRANCHE_CAISSE_EPARGNE = '23'
export const TYPE_BRANCHE_BANQUE_POPULAIRE = '13'
export const TYPE_BRANCHE_BANQUE = '16'


export const MOTIF_ANNULER =  ['LICENCIEMENT', 'DEMISSION', 'ACTION INTERROMPUE', 'RUPTURE CONVENTIONNELLE','AUTRES']

export const MOTIF_RUPTURE =  ['RUPTURE AU COURS DE LA PERIODE PROBATOIRE', 'COMMUN ACCORD', 'PRONONCEE PAR LE CONSEIL DES PRUD\'HOMMES', 'PRONONCEE PAR LE CONSEIL DES PRUD\'HOMMES (POUR L\'APPRENTI)','CAS DE FORCE MAJEURE','LIQUIDATION JUDICIAIRE DE L\'ENTREPRISE','OPPOSITION PREFECTORALE','DEMISSION DE L\'APPRENTI','APRES OBTENTION DU DIPLOME OU TITRE PREPARE','RUPTURE PRESCRITE PAR UN SERVICE DE CONTROLE DE L\'ETAT','LICENCIEMENT POUR FAUTRE GRAVE, FORCE MAJEURE,INAPTITUDE DE L\'APPRENTI, DECES EMPLOYEUR MAITRE D\'APPRENTISSAGE DANS UNE ENTREPRISE UNIPERSONNELLE','LICENCIEMENT POUR INAPTITUDE DE L\'APPRENTI','MANQUEMENT DE L\'UNE DES PARTIES A SES OBLIGATIONS','LICENCIEMENT A LA SUITE DE L\'EXCLUSION DEFINITIVE DE L\'APPRENTI PAR LE CFA','INDEFINI']

export const SEXE_MASCULIN = '1'
export const SEXE_FEMININ = '2'

export const ACTU_IMG =  ['alternance.jpg', 'collecte.jpg', 'evenement.jpg', 'financement.jpg','formation.jpg','media.jpg','recrutement.jpg','reseau.jpg','rh.jpg']

export const TYPE_QUALIFICATION_RECONNUE = '5'
export const TYPE_QUALIFICATION_RNCP = '7'
export const TYPE_QUALIFICATION_CQP = '3'
export const TYPES_QUALIFICATIONS_RNCP = [TYPE_QUALIFICATION_RNCP, '23']
export const TYPE_QUALIFICATIONS_CPRO = ['3', '5', '7']
export const TYPE_QUALIFICATIONS_VAEI = ['31', '32', '33', '34', '35'] // , '36']
export const TYPE_QUALIFICATIONS_VAEI_RNCP = ['31', '34']

export const TYPE_CERTIFICATION_RNCP = '28'

export const TYPE_STAGE_INTER = '1'
export const TYPE_STAGE_INTRA = '2'

export const MODALITE_STAGE_PRESENTIEL = '1'
export const MODALITE_STAGE_MIXTE = '2'
export const MODALITE_STAGE_EN_LIGNE = '3'
export const MODALITE_STAGE_CLASSE_VIRTUELLE = '4'

export const STATUT_PERSONNE_ENTREE_FORMATION_SALARIE = '220'

interface DateStats {
    id : string
    label : string
}
export const yearStats = getYear(new Date())
export  const PERIODE_STATS = [
        {id:yearStats +'-01-01',label:"L'année en cours"},
        {id:yearStats-1 +'-01-01',label:"L'année précédente"},
        {id:yearStats-2 +'-01-01',label:"Les 2 dernières années"},
        {id:yearStats-3 +'-01-01',label:"Les 3 dernières années"}
    ] as DateStats[]

export const CIBLE_ENTREPRISE="cib-003"
export const CIBLE_PRESTATAIRE="cib-001|cib-005|cib-006"

export const TYPE_ORIGIN_ENTREPRISE = 'WEB MAE'
export const TYPE_ORIGIN_PRESTATAIRE = 'WEB MAP'
export const CHAMP_CERFA="CHAMP-CERFA"
export const CHAMP_NON_CERFA="NON"
export const TYPE_MODIFICATION_VIDE="Vide"
export const TYPE_MODIFICATION_CORRECTION="Correction"
export const TYPE_MODIFICATION_AVENANT="Avenant"
export const NIR_HIDE="*************"

export const FORMATION_CERTIFIANTE_NON = "624"
export const FORMATION_CERTIFIANTE_OUI = "625"
export const OBJECTIF_GENERAL_OF_PERFECTIONNEMENT = "263"
export const OBJECTIF_GENERAL_OF_CERTIFICATION = "266"
export const OBJECTIF_GENERAL_FORMATION_QUALIFIANTES_CERTIFIANTES = "257"
export const OBJECTIF_GENERAL_FORMATION_COMPETENCES = "259"
export const OBJECTIF_GENERAL_FORMATION_AUTRES = "260"

export const PAYS_FRANCE = "1"

export const PRODUITS_ACTU = {

    '1': '14174|14175|14178|14180|14181|14182|14183|14184|14193',
    '2': '14179',
    '3':'',
    '4': '14176',
    '5': '14177',
    '6': '14189',
    '7': '14191',
    '8': '14190',
    '9': '14191',
    '10': '14194',
    '11': '14194',
    '12': '14194',
    'all':'all'

} as any

export function findFilterActu(listeFiltre: string[] ): string {
    var dataFiltre=""
    // eslint-disable-next-line array-callback-return
    listeFiltre.map(item => {
        if(PRODUITS_ACTU[item])
        dataFiltre=dataFiltre +'|'+ PRODUITS_ACTU[item]
    })
    return dataFiltre.replace(/^./, "")
}

export const INTITULE_STAGE_BRANCHE_ETUDE = [
    { Id: 'PR1 - Structurer et développer les pratiques RH', LibelleReduit__c: 'PR1 - Structurer et développer les pratiques RH' } as any,
    { Id: 'PR2 - Mobilier l’AFEST et de nouvelles approches de formation', LibelleReduit__c: 'PR2 - Mobilier l’AFEST et de nouvelles approches de formation' } as any,
    { Id: 'PR3 - Renforcer les dynamiques professionnelles', LibelleReduit__c: 'PR3 - Renforcer les dynamiques professionnelles' } as any,
    { Id: 'PR4 - Accompagner la transformation digitale des entreprises', LibelleReduit__c: 'PR4 - Accompagner la transformation digitale des entreprises' } as any,
    { Id: 'PRX spécifique BE – Recrutement', LibelleReduit__c: 'PRX spécifique BE – Recrutement' } as any,
    { Id: 'PRX spécifique BE – Fidélisation', LibelleReduit__c: 'PRX spécifique BE – Fidélisation' } as any,
    { Id: 'PRX spécifique BE – QVT', LibelleReduit__c: 'PRX spécifique BE – QVT' } as any,
    { Id: 'PRX spécifique BE – Télétravail', LibelleReduit__c: 'PRX spécifique BE – Télétravail' } as any,
    { Id: 'PRX spécifique BE – AFEST', LibelleReduit__c: 'PRX spécifique BE – AFEST' } as any,
    { Id: 'PRX spécifique BE – Transition numérique', LibelleReduit__c: 'PRX spécifique BE – Transition numérique' } as any,
    { Id: 'PRX spécifique BE – Transition écologique', LibelleReduit__c: 'PRX spécifique BE – Transition écologique' } as any,
]

export const INTITULE_STAGE_AUTRE_BRANCHE_ETUDE = [
    { Id: 'PR1 - Structurer et développer les pratiques RH', LibelleReduit__c: 'PR1 - Structurer et développer les pratiques RH' } as any,
    { Id: 'PR2 - Mobilier l’AFEST et de nouvelles approches de formation', LibelleReduit__c: 'PR2 - Mobilier l’AFEST et de nouvelles approches de formation' } as any,
    { Id: 'PR3 - Renforcer les dynamiques professionnelles', LibelleReduit__c: 'PR3 - Renforcer les dynamiques professionnelles' } as any,
    { Id: 'PR4 - Accompagner la transformation digitale des entreprises', LibelleReduit__c: 'PR4 - Accompagner la transformation digitale des entreprises' } as any,
]

export const PARCOURS_STRATEGIQUES_TPE_PME = "10"
export const CONTEXTE_SPECIFIQUE_FNE = "12"
export const CONTEXTE_SPECIFIQUE_CPF = "16"
export const CONTEXTE_SPECIFIQUE_AIRBUS = "17"
export const CONTEXTE_SPECIFIQUE_AIRBUS_FNE = "18"
export const CONTEXTE_SPECIFIQUE_AIRBUS_CPF = "19"
export const CONTEXTE_SPECIFIQUE_FORMATION_COURT = "20"
export const CONTEXTE_SPECIFIQUE_SECTEUR_AUTOMOBILE = "28"

export const TYPE_ENTREPRISE = 'EntrepriseCliente'
export const TYPE_ORGANISME = 'OrganismeFormation'
export const TYPE_ENTREPRISE_PROSPCT = 'Entreprise cliente'
export const TYPE_ORGANISME_PROSPCT = 'Organisme de formation'

export const DEMANDE_EN_COURS_MODIFICATION_DATES = 9
export const DEMANDE_EN_COURS_ANNULATION = 11
export const DEMANDE_EN_COURS_RUPTURE = 15
export const DEMANDE_EN_COURS_CORRECTION_CONTRAT = 5
export const DEMANDE_EN_COURS_CLOTURE = 26
export const DEMANDE_EN_COURS_ANNULATION_SUBROGATION = 27
export const DEMANDE_EN_COURS_AJOUT_SUBROGATION = 28
