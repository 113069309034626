import { AppRoute } from 'app/AppModuleProvider'
import { MyPublicTemplate } from 'atlas-ds'
import { BrowserRouter as Router, Switch } from 'react-router-dom'
import { Cgu } from './Cgu'
import { CompteSupprime } from './CompteSupprime'
import { CreerMonCompte } from './CreerMonCompte'
import { CreerMonCompteSucces } from './CreerMonCompteSucces'
import { CreerMonCompteValidationEmail } from './CreerMonCompteValidationEmail'
import { MotDePasseOublie } from './MotDePasseOublie'
import { MotDePasseOublieValidationEmail } from './MotDePasseOublieValidationEmail'
import { ReinitialiserMotDePasse } from './ReinitialiserMotDePasse'
import { ReinitialiserMotDePasseConfirmation } from './ReinitialiserMotDePasseConfirmation'

export const nil = (null as unknown)

export const createEmailState = (email: string) => { return {
    email
}}
export type EmailState = ReturnType<typeof createEmailState>


export const MamcRoutes = [
    <AppRoute exact key="public-conditions-utilisation" path="/public/conditions-utilisation" component={ Cgu } />,

    <AppRoute exact key="creer-mon-compte" path="/creer-mon-compte" component={ CreerMonCompte } />,
    <AppRoute exact key="creer-mon-compte-validation-email" path="/creer-mon-compte-validation-email" component={ CreerMonCompteValidationEmail } />,
    <AppRoute exact key="creer-mon-compte-succes" path="/creer-mon-compte-succes/:guid" component={ CreerMonCompteSucces } />,

    <AppRoute exact key="mot-de-passe-oublie" path="/mot-de-passe-oublie" component={ MotDePasseOublie } />,
    <AppRoute exact key="mot-de-passe-oublie-validation-email" path="/mot-de-passe-oublie-validation-email" component={ MotDePasseOublieValidationEmail } />,
    <AppRoute exact key="reinitialiser-mot-de-passe" path="/reinitialiser-mot-de-passe/:token" component={ ReinitialiserMotDePasse } />,
    <AppRoute exact key="reinitialiser-mot-de-passe-confirmation" path="/reinitialiser-mot-de-passe-confirmation" component={ ReinitialiserMotDePasseConfirmation } />,

    <AppRoute exact key="compte-supprime" path="/compte-supprime" component={CompteSupprime} />
]


export default function MamcApp() {
    return (
        <Router>
            <Switch>
                <MyPublicTemplate>
                    { MamcRoutes }
                </MyPublicTemplate>
            </Switch>
        </Router>
    )
}
