import { AppDossierModule } from 'pages/detail-dossier/modules'
import { MetadataProA as Metadata } from 'pages/dossier-proa/ProAFormMetadata'
import { actionsEntrepriseRelanceAnnulerSubrogation, actionsEntrepriseRelanceCloture, actionsEntrepriseRelanceDemanderSubrogation } from 'pages/detail-dossier/relanceff/entreprise'
import { actionsPrestataireRelanceAnnulation, actionsPrestataireRelanceAnnulerSubrogation, actionsPrestataireRelanceCloture } from 'pages/detail-dossier/relanceff/prestataire'
import { DetailDossierExtension } from 'pages/detail-dossier/DetailDossierContrat'
import { actionsAnnulerDossier, actionsEntrepriseDeposerFacture } from 'pages/detail-dossier/modules/entreprise'
import { actionsPrestataireAccordeDeposerFacture } from 'pages/detail-dossier/modules/prestataire'


const dossierProAModule: AppDossierModule = {
    name: 'PROA',
    dispositifId: Metadata.dispositif,
    metadata: { 
        entreprise: { create: Metadata } 
    },
    extensions: {
        entreprise: {
            DetailDossierContrat: {
                actions: (dossier, actions) => {
                    actionsAnnulerDossier(dossier, actions)
                    actionsEntrepriseDeposerFacture(dossier, actions)
                    
                    actionsEntrepriseRelanceCloture(dossier, actions)               
                    actionsEntrepriseRelanceDemanderSubrogation(dossier, actions)
                    actionsEntrepriseRelanceAnnulerSubrogation(dossier, actions)
                },
            },
        } as DetailDossierExtension,
        prestataire: {
            DetailDossierContrat: {
                actions: (dossier, actions) => {
                    actionsPrestataireAccordeDeposerFacture(dossier, actions)
                    
                    actionsPrestataireRelanceCloture(dossier, actions)
                    actionsPrestataireRelanceAnnulerSubrogation(dossier, actions)
                    actionsPrestataireRelanceAnnulation(dossier, actions)
                },
            },
        } as DetailDossierExtension,
    }
}

export default dossierProAModule