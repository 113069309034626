import { DossierDetail, postRelanceRuptureDossier, RuptureDossierInfo } from 'api/dossierAPI'
import { DEMANDE_EN_COURS_RUPTURE } from 'api/references'
import { AtlasHtml, AtlasInfo, AtlasSpacer, MyForm, MyModal } from 'atlas-ds'
import { checkDetailAction } from 'pages/detail-common/Detail'
import { DossierRelanceActionProps } from '.'
import DossierRelanceAction from './DossierRelanceAction'
import { UseFormReturn, useForm, Form } from '@dsid-opcoatlas/reform'
import CheckboxField from 'components/reform/inputs/CheckboxField'
import { OptionalBoolean } from 'components/yop/constraints'
import { createRuptureInfo, RuptureFormMetadata, RuptureInfo } from 'pages/dossier/metadata/RuptureFormMetadata'
import { nil } from 'slices/dossierCommon'
import Button from 'components/reform/Button'
import { Yop } from '@dsid-opcoatlas/yop'
import { useState } from 'react'
import { useSimpleYopSchema } from 'pages/dossier/metadata/FormMetadata'
import { SimpleFormElements } from 'pages/dossier/metadata/FormElements'
import { Document, pdf } from '@react-pdf/renderer'
import { buildCerfaRupture } from 'components/annuler/generateCerfa'
import { useSelector } from 'react-redux'
import { RootState } from 'app/rootReducer'

export function DossierRelanceRupture(props: DossierRelanceActionProps & { formMetadata: RuptureFormMetadata }) {
    const { fulfilled: refs } = useSelector((state: RootState) => state.referencesState)
    const { profil } = useSelector((state: RootState) => state.profilState)
    const authName = `${profil?.Contact?.Email ?? ''}`

    const dossier = checkDetailAction(props)
    if (!dossier) return null

    const infoShown = () => !props.formMetadata.showInfoStep || !!localStorage.getItem(props.formMetadata.showInfoStep)
    const [step, setStep] = useState(infoShown() ? 2 : 1)

    const initialValues = props.formMetadata.createRuptureInfo ? props.formMetadata.createRuptureInfo(dossier) : createRuptureInfo(dossier)
    const validationSchema = useSimpleYopSchema(props.formMetadata, initialValues)

    const informationModal: Parameters<typeof DossierRelanceAction>[0]["firstStepModal"] = (actionProps) => <StepInformationRupture
        formMetadata={ props.formMetadata }
        dossier={ dossier }
        next={ () => setStep(2)}
        close={ actionProps.onClose }
    />

    const onCancel = (form: UseFormReturn<any>) => {
        form.reset()
        setStep(infoShown() ? 2 : 1)
    }

    const postRupture = async (dossier: DossierDetail, formValues: any) => {
        const instance = pdf(<Document />)            
        instance.updateContainer(buildCerfaRupture(props.formMetadata, formValues, dossier?.NumeroDossier__c, authName))
        const blob = await instance.toBlob()

        return await props.formMetadata.postRupture(dossier?.NumeroDossier__c, refs!, formValues, blob)
    }

    return <DossierRelanceAction
        dossier={ dossier }
        id="rupture"
        label="Informer de la rupture du contrat"
        disabled={ props.formMetadata.disabled ? props.formMetadata.disabled(dossier) : false }
        modalTitle={ `Confirmez-vous la rupture du ${props.formMetadata.nomDispositif} n°${dossier.NumeroDossier__c}\xa0?`}
        of={ props.of }
        firstStepModal={ step === 1 ? informationModal : undefined }
        typeDemande={ DEMANDE_EN_COURS_RUPTURE }
        successMessage="Votre rupture a bien été prise en compte. Elle sera traitée dans les meilleurs délais"
        formContent={ <>
            <AtlasInfo type="warning" title="Déclaration de rupture dématérialisée">
                La déclaration de rupture est entièrement dématérialisée. Le document de rupture n'est donc pas à ajouter dans les documents.
            </AtlasInfo>

            <SimpleFormElements metadata={ props.formMetadata } />
        </> }
        params={ values => values }
        doAction={ postRupture }
        initialValues={ initialValues }
        validationSchema={ validationSchema }
        onCancel={ onCancel }
    />
}

interface StepInformationRuptureProps<R extends RuptureInfo = RuptureInfo> {
    formMetadata: RuptureFormMetadata<R>
    dossier: DossierDetail
    close: () => void
    next: () => void
}

function StepInformationRupture<R extends RuptureInfo = RuptureInfo>(props: StepInformationRuptureProps<R>) {

    const initialValues = { valcheck: nil as boolean | null }

    const validationSchema = Yop.object({
        valcheck: OptionalBoolean,
    })

    const onClose = (resetForm: Function) => {
        resetForm()
        props.close()
    }

    const sendInformation = (context: UseFormReturn<typeof initialValues>) => {
        if (context.values!.valcheck)
            localStorage.setItem(props.formMetadata.showInfoStep, 'true')
        
        context.setSubmitting(false)
        props.next()
    }

    const form = useForm({
        initialValues,
        validationSchema,
        dispatchEvent: false,
        onSubmit: sendInformation,
    })

    return (
        <MyModal onClose={ () => onClose(form.reset) } label="Informations importantes">
            <AtlasHtml>
                <p >Le contrat peut être rompu par écrit par
                    l'employeur ou par l'apprenti dans les 45 jours. Cette rupture doit intervenir avant
                    la fin des 45 premiers jours de formation pratique dans l'entreprise, même
                    s'ils ne sont pas consécutifs. </p>

                <p >Après 45 jours, le contrat peut être rompu&nbsp;dans l'un des cas suivants :</p>

                <ul>
                    <li>D'un commun accord entre l'employeur et
                        l'apprenti.
                    </li>
                    <li>Par l'apprenti qui doit saisir&nbsp;
                        <a target="_blank" rel="noopener noreferrer" href="https://www.service-public.fr/particuliers/vosdroits/F31633">
                            le médiateur.
                        </a>

                        Il informe ensuite son
                        employeur après un délai minimal de <u>5&nbsp;jours calendaires :
                            Correspond à la totalité des jours du calendrier de l'année civile, du 1er
                            janvier au 31 décembre, y compris les jours fériés ou chômés</u>. La
                        rupture du contrat a lieu après un délai minimal de 7 jours calendaires
                        après information de l'employeur.</li>
                    <li>Par l'apprenti qui obtient son diplôme avant le
                        terme fixé initialement à condition d'en informer par écrit son employeur
                        1 mois à l'avance.</li>
                    <li>Par l'employeur pour faute grave,
                        inaptitude,&nbsp;<u>force majeure: Événement exceptionnel, imprévisible et
                            irrésistible justifiant de s'exonérer d'une obligation, d'un engagement ou
                            d'une responsabilité (par exemple&nbsp;: catastrophe naturelle pour un
                            contrat d'assurance, décès du salarié pour un contrat de travail...)</u>&nbsp;et
                        exclusion définitive de l'apprenti du CFA, en respectant la procédure
                        de&nbsp;
                        <a target="_blank" rel="noopener noreferrer" href="https://www.service-public.fr/particuliers/vosdroits/F2839">
                            licenciement pour motif personnel
                        </a>
                    </li>
                </ul>
                <p>Suite à la rupture de son contrat, l'apprenti qui n'a pas trouvé un nouvel employeur peut poursuivre sa formation théorique pendant 6 mois en CFA. Dans ce cas, il bénéficie du statut de stagiaire de la formation professionnelle. Il conserve ses droits sociaux.</p>
                <p><strong>Toute rupture anticipée du contrat ou de la période d’apprentissage doit faire l’objet d’un document écrit, notifié au directeur du CFA et à Atlas. Pour informer Atlas, l'entreprise ou le CFA transmet les éléments via ce formulaire.</strong></p>
            </AtlasHtml>
            
            <AtlasSpacer />

            <Form context={ form } noValidate autoComplete="off">
                <MyForm>
                    <CheckboxField name={`valcheck`} label="Ne plus afficher" />

                    <MyForm.Actions>
                        <Button submit={true} spinner={{spinning: form.submitting}}>Valider</Button>
                    </MyForm.Actions>
                </MyForm>
            </Form>
        </MyModal>
    )
}
