import { AtlasButton, MyModal } from 'atlas-ds'

export default function AucuneEntrepriseError() {
    return (
        <MyModal
            isError
            label="Aucune entreprise n'est attachée à votre compte !"
            okButton={<AtlasButton onClick={ () => document.location = "/?contexte=compte" }>
                Gérer mon compte
            </AtlasButton>}
        >
            <p>Vous allez être redirigé vers la gestion de votre compte.</p>
        </MyModal>
    )
}
