import axios from 'axios'
import log from 'loglevel'
import { format, startOfToday, subYears } from 'date-fns'
import { BaseSearchQuery2, emptyPage, getRequestConfig, Page, SuccessResponse } from './baseAPI'
import { getDocumentSalesforce, openDocumentSalesforce, PieceDTO as FacturePieceDTO, PieceTypeDTO as FacturePieceTypeDTO } from 'api/documentAPI'
import { DossierSendStatus, Facture } from 'api/dossierAPI'
import { DATE_FORMAT, SIREN_ADH } from 'app/constants'
import store from 'app/store'
import { ETAT_FACTURE_A_TRANSMETTRE, ETAT_FACTURE_EN_COURS, ETAT_FACTURE_REFUSE, ETAT_FACTURE_SOUMISE, findDispositifsEligiblesParBranche, findEtatFactureFO, getAllDispositifFO } from './references'
import { DownloadDocumentDetail, openDocumentAlfresco } from './documentAPI'
import { AppType } from 'slices/contextSlice'
import { entreprise } from './entreprise'


export { emptyPage } from 'api/baseAPI'

export type { Page } from 'api/baseAPI'

export type { Facture } from 'api/dossierAPI'
export type { PieceDTO as FacturePieceDTO, PieceTypeDTO as FacturePieceTypeDTO } from 'api/documentAPI'


export interface FacturesSearchQuery extends BaseSearchQuery2 {
    recherche?: string
    etatFactureFo?: string[]
    dateDebut?: string
    dateFin?: string
    typeFacture?: string
    organisme?: string
    alerte?: string
    demande?: string
}

export interface FactureDetail extends Facture {
    Emetteur__c?: string
}

export interface FactureSendStatus {
    Id?: string | null
    IdHeroku: number | null
    Externalid_Heroku__c: string | null
    EnCoursTransmission__c: boolean | null
    ErreurTransmission__c: string | null
}

export async function getFactures(query: FacturesSearchQuery): Promise<Page<Facture>> {
    if (query.etatFactureFo && query.etatFactureFo.length === 1 && query.etatFactureFo[0] === ETAT_FACTURE_A_TRANSMETTRE)
        return emptyPage<Facture>()

    const requestConfig = await getRequestConfig()

    const params = getSearchFacturesParams(query, requestConfig.headers['Atlas-Context'])
    params.append('page', query.page.toFixed(0))
    params.append('par-page', (query.parPage ?? 20).toFixed(0))
    requestConfig.params = params

    const { data, headers } = await axios.get<Facture[]>('/factures/recherche', requestConfig)

    data.forEach(facture => {
        facture.EtatFactureFORef = findEtatFactureFO(facture.EtatFactureFO__c)
        // facture.EmetteurTypeRef = findEmetteurType(facture.EmetteurType__c);
        // facture.TypeRef = findTypeFacture(facture.Type__c);
    })

    const totalCount = parseInt(headers['x-total-count'], 10) || data.length
    const alertCount = parseInt(headers['x-alertes-total-count'], 10) || 0

    log.debug('Fetch factures SOR ' + params.get('page') + '/' + params.get('par-page') + ' => ' + data.length + ' items / ' + totalCount + ' results')

    return {
        alertCount,
        totalCount,
        items: data
    }
}

export async function getFacturesSelfe(query: FacturesSearchQuery): Promise<Page<Facture>> {
    const requestConfig = await getRequestConfig()

    const params = getSearchFacturesSelfeParams(query)

    if (!params.has("fo_status") || !params.has("bo_status"))
        return emptyPage<Facture>()

    params.append('page', query.page2.toFixed(0))
    params.append('per-page', (query.parPage2 ?? 20).toFixed(0))

    requestConfig.params = params

    const { data } = await axios.get<any>('/factures/selfe', requestConfig)
    
    const items = data.data.map((f: any) => {
        return {
            Id: f.id,
            Name: f.id,
            RefFactureClient__c: f.invoice_number,
            NumeroDossier__c: f.fafiec_id,
            DateReception__c: f.invoice_date,
            DateEmission__c: f.upload_date,
            EtatFactureFORef: { Id: 1, Libelle__c: f.fo_status === 'pending' ? 'A transmettre' : (f.bo_status === 'refused' ? 'Refusée' : 'Soumise') },
            EtatFactureFO__c: f.fo_status === 'pending' ? ETAT_FACTURE_EN_COURS : (f.bo_status === 'refused' ? ETAT_FACTURE_REFUSE : ETAT_FACTURE_EN_COURS),
            MontantFactureHT__c: f.ht,
            MontantTTC__c: f.ttc,
        }
    })

    const totalCount = data.meta.pagination.total
    const alertCount = data.meta.pagination.alerts ?? 0

    log.debug('Fetch factures Selfe ' + params.get('page') + '/' + params.get('per-page') + ' => ' + items.length + ' items / ' + totalCount + ' results')

    return {
        alertCount,
        totalCount,
        items,
    }
}

export async function getFacturesSelfeRIBValid(siren: string): Promise<boolean> {
    const requestConfig = await getRequestConfig()

    const { data } = await axios.get<any>(`/entreprises/${siren}/transactions`, requestConfig)
    return data.success
}

export async function getFacture(id: string): Promise<FactureDetail> {
    const requestConfig = await getRequestConfig();

    const params = new URLSearchParams();
    params.append('utilisateur', store.getState().profilState.profil?.Contact.GuidIAM ?? '*');
    params.append(SIREN_ADH, store.getState().contextState.entreprise?.SIREN__c ?? '');
    requestConfig.params = params;

    const { data } = await axios.get<FactureDetail>('factures/' + id, requestConfig);

    data.EtatFactureFORef = findEtatFactureFO(data.EtatFactureFO__c)

    return data
}

export async function exportFactures(query: FacturesSearchQuery, email: string): Promise<string> {
    const requestConfig = await getRequestConfig()

    const params = getSearchFacturesParams(query, requestConfig.headers['Atlas-Context'])
    params.append('mail', email)

    getSearchFacturesSelfeParams(query).forEach( (v,k) => params.append(k, v))

    await axios.post('/factures/exporter', params, requestConfig)

    return `Le fichier de données est en cours d'export et vous sera envoyé par email à l'adresse ${email}`
}

function getSearchFacturesParams(query: FacturesSearchQuery, contexte: string): URLSearchParams {

    const params = new URLSearchParams()

    const entreprise = store.getState().contextState.entreprise
    if (entreprise) {
        const parBranche = findDispositifsEligiblesParBranche(entreprise)
        const dispositifIds = (getAllDispositifFO() ?? []).filter(d => parBranche.includes(d.Id)).map(d => d.Id)

        dispositifIds.forEach(dispositifId => {
            params.append('dispositif-fo', dispositifId)
        })
    }

    if (query.tri) {
        params.append('tri', query.tri)
        params.append('ordre', query.ordre ?? 'desc')
    }

    if (query.recherche)
        params.append('recherche', query.recherche.trim())

    if (query.etatFactureFo && query.etatFactureFo.length > 0) {
        query.etatFactureFo.forEach(value => {
            params.append('etat-facture-fo', value)
        })
    }

    if (query.typeFacture)
        params.append('type-facture', query.typeFacture)

    if (contexte === AppType.Entreprise)
        params.append('type-emetteur', 'EntrepriseCliente')
    // if (query.emetteur)
    //     params.append('type-emetteur', query.emetteur === TYPE_EMETTEUR_ADHERENT ? 'EntrepriseCliente' : 'OrganismeFormation')

    if (query.organisme) {
        params.append('etablissementId', query.organisme.indexOf('/') ? query.organisme.split('/')[0] : query.organisme)
    }
    
    const today = startOfToday()
    params.append('date-debut', query.dateDebut ?? format(subYears(today, 5), DATE_FORMAT))
    params.append('date-fin', query.dateFin ?? format(today, DATE_FORMAT))
    params.append(SIREN_ADH, entreprise?.SIREN__c ?? '')

    if (import.meta.env.REACT_APP_RELANCE === 'true') {
        if (query.alerte != null && query.alerte !== '') {
            params.append('alerte', query.alerte === 'oui' ? 'true' : 'false')
        }
        if (query.demande != null && query.demande !== '') {
            params.append('demande-en-cours', query.demande === 'oui' ? 'true' : 'false')    
        }
    }
    
    return params
}

function getSearchFacturesSelfeParams(query: FacturesSearchQuery): URLSearchParams {

    const context = store.getState().contextState

    const params = new URLSearchParams()

    if (query.recherche)
        params.append('invoice_number', query.recherche.trim())

    if (query.etatFactureFo && query.etatFactureFo.length > 0) {
        const foStatus = new Set<string>()
        const boStatus = new Set<string>()
        if (query.etatFactureFo.includes(ETAT_FACTURE_A_TRANSMETTRE)) {
            foStatus.add('pending')
        }
        if (query.etatFactureFo.includes(ETAT_FACTURE_SOUMISE)) {
            foStatus.add('validated')
            boStatus.add('pending')
            boStatus.add('todo')
        }
        if (query.etatFactureFo.includes(ETAT_FACTURE_REFUSE)) {
            foStatus.add('validated')
            boStatus.add('refused')
        }

        foStatus.forEach(s => params.append('fo_status', s))
        boStatus.forEach(s => params.append('bo_status', s))
    } else {
        params.append('fo_status', 'pending')
        params.append('fo_status', 'validated')
        params.append('bo_status', 'pending')
        params.append('bo_status', 'todo')
        params.append('bo_status', 'refused')
    }

    params.append('siret_type', context.app === 'entreprise' ? 'A' : 'O')
    params.append("siren", context.entreprise?.SIREN__c ?? '')
    if (query.organisme) {
        params.append('siret', query.organisme.indexOf('/') ? query.organisme.split('/')[1] : query.organisme)
    } else {
        // TODO Vérifier que ça filtre bien par SIREN
        params.append('siret', '')
    }
    
    if (query.typeFacture)
        params.append('type', query.typeFacture === '2' ? 'avoir' : 'facture')

    const today = startOfToday()
    params.append('upload_date_from', query.dateDebut ?? format(subYears(today, 5), DATE_FORMAT))
    params.append('upload_date_to', query.dateFin ?? format(today, DATE_FORMAT))

    return params
}

export async function getFactureDocumentTypes(idFacture: string): Promise<FacturePieceTypeDTO[]> {
    const requestConfig = await getRequestConfig()
    const { data } = await axios.get<FacturePieceTypeDTO[]>('/factures/' + idFacture + '/document-types', requestConfig)
    return data
}

export async function getFactureDetailFactures(idFacture: string): Promise<FacturePieceDTO[]> {
    const requestConfig = await getRequestConfig();
    const { data } = await axios.get<FacturePieceDTO[]>('/factures/' + idFacture + '/documents', requestConfig)
    return data
}
    
export async function downloadFactureDoc(doc: FacturePieceDTO, facture: Facture | null) {
    if (doc.ContentVersionId) {
        return getDocumentSalesforce(facture?.Dossier?.NumeroDossier__c ?? '', doc.ContentVersionId).then(response => {
            openDocumentSalesforce(doc.fichier, response)
        })
    } else if (doc.source === 'GED' && facture?.Id) {
        let idNode = doc.Id.split('/')
        return getDocFactureViaGed(facture.Id, idNode[3]).then(response => {
            openDocumentAlfresco(doc.fichier, response)
        })
    }
    return Promise.resolve()
}

export async function loadFacturePieces(idFacture: string): Promise<FacturePieceDTO[]> {
    const pieceTypes = await getFactureDocumentTypes(idFacture)

    const pieces = await getFactureDetailFactures(idFacture)
    pieces?.forEach(piece => {
        const pieceType = pieceTypes?.find(pt => pt.nom === piece.type)
        if (pieceType) {
            piece.description = pieceType.description
            piece.obligatoire = pieceType.obligatoire
            piece.actif = true
        } else {
            piece.description = ''
            piece.obligatoire = false
            piece.actif = false
        }
    })
    return pieces
}

export async function getDocFactureViaGed(idFacture: string, nodeRef: string): Promise<DownloadDocumentDetail> {
    const requestConfig = await getRequestConfig();
    const { data } = await axios.get<any>('/factures/' + idFacture + '/documents/download/' + nodeRef, requestConfig)
    return data
}

export async function uploadDocumentFacture(idFacture: string, nom: string, file: File | Blob, fileName?: string): Promise<FacturePieceDTO> {
    return doUploadDocumentFacture(idFacture, nom, false, file, fileName)
}

async function doUploadDocumentFacture(idFacture: string, nom: string, uploadToGed: boolean, file: File | Blob, fileName?: string): Promise<FacturePieceDTO> {
    const requestConfig = await getRequestConfig();
    requestConfig.headers['Content-type'] = 'multipart/form-data';

    const name = (fileName ?? (file as any).name ?? nom).replaceAll(/[\\/|:*?"<>]/g, '_')

    const formData = new FormData()
    formData.append('nom', nom)
    formData.append('document', file, name)
    if (uploadToGed)
        formData.append('uploadToGed', 'true')
    const { data } = await axios.post('/factures/' + idFacture + '/documents', formData, requestConfig)
    return data
}

export async function deleteDocumentFacture(idFacture: string, id: string): Promise<string[]> {
    const requestConfig = await getRequestConfig();
    const { data } = await axios.delete('/factures/' + idFacture + '/documents/' + id, requestConfig)
    return data
}

export async function sendFactureDocuments(idFacture: string): Promise<any> {
    const requestConfig = await getRequestConfig();
    const { data } = await axios.post('/factures/' + idFacture + '/documents/valider', null, requestConfig)
    return data
}

export async function getFactureDocumentsStatus(id: string): Promise<DossierSendStatus> {
    const requestConfig = await getRequestConfig()
    const { data } = await axios.get('/factures/' + id + '/resume', requestConfig)
    return {
        Id: data.Id,
        EnCoursTransmission__c: data.EnCoursTransmission__c,
        ErreurTransmission__c: data.ErreurTransmission__c,
    }
}

export async function getShouldAddRIB(): Promise<boolean> {
    const requestConfig = await getRequestConfig()
    const { data } = await axios.get<SuccessResponse>(`/entreprises/${ entreprise()?.SIREN__c }/factures`, requestConfig)
    return data.success === false
}
