
import { RootState } from 'app/rootReducer'
import { AtlasColumns, AtlasInfo, AtlasSpacer, MyConfettis } from "atlas-ds"
import Link from "components/Link"
import PageHeader from "components/PageHeader"
import { useEffect, useState } from "react"
import { useSelector } from 'react-redux'
import { useHistory, useLocation } from 'react-router-dom'
import { AppType } from 'slices/contextSlice'
import { ListeMesEntreprises } from "./ListeMesEntreprises"

export function MesEntreprises() {
    const { appTypes, profil, pending } = useSelector((state: RootState) => state.profilState)
    const history = useHistory()
    const location = useLocation()

    useEffect(() => {
        if (!appTypes?.includes(AppType.Entreprise)) {
            const params = new URLSearchParams(location.search)

            history.replace({
                pathname: '/',
                search: params.toString()
            })
        }
    })

    const [nouvelleEntrepriseSiren, setNouvelleEntrepriseSiren] = useState("")

    useEffect(() => {
        const params = new URLSearchParams(location.search)

        const nouvelleEntrepriseSirenParam = params.get("nouvelleEntrepriseSiren")
        if (nouvelleEntrepriseSirenParam) {
            setNouvelleEntrepriseSiren(nouvelleEntrepriseSirenParam)
            params.delete("nouvelleEntrepriseSiren")

            history.replace({
                pathname: location.pathname,
                search: params.toString()
            })
        }
    }, [history, location.pathname, location.search])

    return <>
        <PageHeader
            actions={<Link key="entrepriseBoutonAjout" icon="plus" to={'/mes-entreprises/attacher'}>
                Attacher une entreprise
            </Link>}
        >Mes entreprises</PageHeader>

        <AtlasColumns>
            <div>
                {nouvelleEntrepriseSiren ? <MyConfettis>
                    <AtlasInfo
                        type="success"
                        title="Entreprise attachée"
                        link={<a href={`/?contexte=entreprise&siren-adh=${nouvelleEntrepriseSiren}`}>Accéder à myAtlas Entreprise</a>}
                    >
                        Félicitations, votre entreprise a bien été attachée à votre compte.<br/>
                        Vos entreprises sont attachées par SIREN mais vous retrouverez tous les établissements ouverts sur myAtlas Entreprise pour réaliser vos demandes et dossiers.
                    </AtlasInfo>
                </MyConfettis> : <AtlasInfo
                    title="Retrouvez vos établissements sur myAtlas Entreprise"
                    link={<a href={`/?contexte=entreprise`}>Accéder à myAtlas Entreprise</a>}
                >Vos entreprises sont attachées par SIREN mais vous retrouverez tous les établissements ouverts sur myAtlas Entreprise pour réaliser vos demandes et dossiers.</AtlasInfo> }

                <AtlasSpacer size="xl" />
            </div>
        </AtlasColumns>

        <ListeMesEntreprises entreprises={profil?.Entreprises ?? null} loading={pending} type="EntrepriseCliente" />
    </>
}