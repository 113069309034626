import { EntrepriseDetail, postDetacherEntreprise } from "api/monCompteAPI";
import { TYPE_ORGANISME } from "api/references";
import { useAppDispatch } from "app/store";
import { MyModal, AtlasButton, AtlasInfo } from "atlas-ds";
import { ToastService } from "components/BandeauAlert";
import { useState } from "react";
import { useHistory } from "react-router-dom";
import { profilActions } from "slices/profilSlice";
import log from "loglevel"

interface DetacherEntrepriseProps {
    type: string
    entreprise: EntrepriseDetail
    onClose: () => void
}

export default function DetacherEntreprise(props: DetacherEntrepriseProps) {
    const [detachementPending, setDetachementPending] = useState(false)
    const dispatch = useAppDispatch();
    const history = useHistory();

    const confirmDetacherMonCompte = async () => {
        setDetachementPending(true)
        postDetacherEntreprise(props.type, props.entreprise.AccountDTO.SIREN__c).then(_ => {
            dispatch(profilActions.getServices())
            ToastService.showToast({
                type: "success",
                label: "Détachement effectué",
                content: `Votre compte a bien été détaché ${props.type === TYPE_ORGANISME ? "du prestataire" : "de l'entreprise"} ${props.entreprise.AccountDTO.Name}`
            })

            if (props.type === TYPE_ORGANISME) {
                history.push('/mes-prestataires')
            } else {
                history.push('/mes-entreprises')
            }

        }).catch(error => {
            log.error('Erreur détachement Entreprise ' + props.entreprise.AccountDTO.Name, error)
            props.onClose()
            ToastService.showToast({
                type: "error",
                label: "Détachement impossible",
                content: error?.response?.data?.message ?? "Le détachement n'a pas pu être effectué."
            })
        }).finally(() => setDetachementPending(false))
    }

    return <MyModal
        label={`Détacher mon compte de ${props.entreprise.AccountDTO.Name}`}
        isPending={detachementPending}
        okButton={<AtlasButton destructive onClick={confirmDetacherMonCompte}>Confirmer le détachement</AtlasButton>}
        cancelButton={<AtlasButton onClick= {() => props.onClose()}>Retour</AtlasButton>}
    >
        <AtlasInfo type="error" title={`Attention`}>
            Vous n'aurez plus accès à la gestion de {props.type === TYPE_ORGANISME ? "ce prestataire" : "cette entreprise"} et de ses dossiers sur myAtlas.
        </AtlasInfo>
    </MyModal>
}