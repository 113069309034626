import { DossierDetail, postRelanceRuptureDossier } from 'api/dossierAPI'
import { References } from 'api/referencesAPI'
import { date2APIDate, formatRef, parseDate } from 'components/format/Format'
import { Ignored } from 'components/yop/constraints'
import { RuptureFormMetadata, RuptureInfo } from 'pages/dossier/metadata/RuptureFormMetadata'
import { DateFieldMetadata } from 'components/metadata/DateFieldMetadata'
import { FileFieldMetadata } from 'components/metadata/FileFieldMetadata'
import { SelectRefFieldMetadata } from 'components/metadata/RefFieldMetadata'
import { ExportedOnlyTextFieldMetadata } from 'components/metadata/TextFieldMetadata'
import { nil } from 'slices/dossierCommon'


export const CAPPRuptureFormMetadata = {
    title: "",
    nomDispositif: "contrat d'apprentissage",
    showInfoStep: "ruptureInformation",
    postRupture: (numeroDossier: string, refs: References, ruptureInfo: RuptureInfo, blob: Blob) => {
        const motifLabel = formatRef(refs.REF_MOTIF_ADMINISTRATIF, ruptureInfo?.motif)
        return postRelanceRuptureDossier({
            numeroDossier, 
            motif: motifLabel,
            idMotif: ruptureInfo.motif ?? '',
            dateRupture: date2APIDate(ruptureInfo.dateRupture), 
            nom: 'DEMANDE_RUPTURE', 
            file: blob, 
            fileName: 'demande-rupture-' + numeroDossier + '-MAE.pdf',
        })
    },
    fields: {
        dateDebutContrat: Ignored,
        dateFinContrat: Ignored,
        motif: SelectRefFieldMetadata({
            props: {
                label: "Merci de préciser le motif",
                exportLabel: 'Motif de la rupture',
                options: refs => refs.REF_MOTIF_ADMINISTRATIF.filter(ref => ref.Categorie__c === '71' && ref.IsActiveFO__c),
            },
        }).required(),
        dateRupture: DateFieldMetadata({
            props: {
                label: "Date de la rupture",
            },
        }).required().mutate(yop => yop
            .min(ctx => ctx.parent!.dateDebutContrat!, 'La date de rupture est antérieure à la date du début du contrat')
            .max(ctx => ctx.parent!.dateFinContrat!, 'la date de rupture est postérieure à la date de fin de contrat'),
        ),
    },
} as RuptureFormMetadata


function createRupturePrestataireInfo(dossier: DossierDetail) { return {
    dateDebutContrat: parseDate(dossier.DateDebutContrat__c),
    dateFinContrat: parseDate(dossier.DateFinContrat__c),
    nomApprenti: dossier.Stagiaires?.[0] ? (dossier.Stagiaires[0].Nom__c + ' ' + dossier.Stagiaires[0].Prenom__c).trim() : '',
    nomEntreprise: dossier.EtablissementAdherent?.Name,
    nomCFA: dossier.Modules?.[0]?.NomOF__c ?? '(Inconnu)',
    motif: nil as string,
    dateRupture: nil as Date,
    justificatifRupture: nil as File,
}}
type rupturePrestataireInfo = ReturnType<typeof createRupturePrestataireInfo>

export const CAPPMAPRuptureFormMetadata = {
    title: "",
    nomDispositif: "contrat d'apprentissage",
    showInfoStep: "ruptureInformationPrestataire",
    disabled: dossier => dossier?.DateRuptureCA__c != null,
    createRuptureInfo: createRupturePrestataireInfo,
    postRupture: (numeroDossier: string, refs: References, ruptureInfo: rupturePrestataireInfo, blob: Blob) => {
        const motifLabel = formatRef(refs.REF_MOTIF_ADMINISTRATIF, ruptureInfo?.motif)
        return postRelanceRuptureDossier({
            numeroDossier, 
            motif: motifLabel,
            idMotif: ruptureInfo.motif ?? '',
            dateRupture: date2APIDate(ruptureInfo.dateRupture), 
            nom: 'LETTRE_DEMANDE_RUPTURE', 
            file: (ruptureInfo as rupturePrestataireInfo).justificatifRupture, 
            fileName: 'demande-rupture-' + numeroDossier + '.pdf', 
            nomCerfa: 'DEMANDE_RUPTURE', 
            fileCerfa: blob, 
            fileNameCerfa: 'declaration-rupture-' + numeroDossier + '-MAP.pdf',
        })
    },
    fields: {
        dateDebutContrat: Ignored,
        dateFinContrat: Ignored,
        nomApprenti: ExportedOnlyTextFieldMetadata({
            props: {
                label: "Nom de l'apprenti",
            },
        }),
        nomEntreprise: ExportedOnlyTextFieldMetadata({
            props: {
                label: "Nom de l'entreprise",
            },
        }),
        nomCFA: ExportedOnlyTextFieldMetadata({
            props: {
                label: "Nom du CFA",
            },
        }),
        motif: SelectRefFieldMetadata({
            props: {
                label: "Merci de préciser le motif",
                exportLabel: 'Motif de la rupture',
                options: refs => refs.REF_MOTIF_ADMINISTRATIF.filter(ref => ref.Categorie__c === '71' && ref.IsActiveFO__c),
            },
        }).required(),
        dateRupture: DateFieldMetadata({
            props: {
                label: "Date de la rupture",
            },
        }).required().mutate(yop => yop
            .min(ctx => ctx.parent!.dateDebutContrat!, 'La date de rupture est antérieure à la date du début du contrat')
            .max(ctx => ctx.parent!.dateFinContrat!, 'la date de rupture est postérieure à la date de fin de contrat'),
        ),
        justificatifRupture: FileFieldMetadata({
            props: {
                label: "Justificatif de la rupture",
                tooltip: "Merci de joindre un justificatif de la rupture",
            },
        }).required('Veuillez joindre la lettre de rupture').mutate(yop => yop.max(15 * 1024 * 1024, 'Fichier trop volumineux (15 Mo maximum)')),
    },
} as RuptureFormMetadata<rupturePrestataireInfo>
