/* eslint-disable no-template-curly-in-string */
import { Form, SetValueOptions, UseFormReturn, useForm } from '@dsid-opcoatlas/reform'
import { Document, pdf } from '@react-pdf/renderer'
import { DossierDetail, PoursuitePostRuptureDossierInfo, getPoursuitePostRuptureDossier, postPoursuitePostRuptureDossier } from 'api/dossierAPI'
import useApiState from 'api/useApiState'
import { dispatchAppEvent } from 'app/events'
import { RootState } from 'app/rootReducer'
import { AtlasInfo, AtlasLoading, MyForm, MyModal } from 'atlas-ds'
import { ToastService } from 'components/BandeauAlert'
import { date2APIDate, formatDate, formatRef, parseDate } from 'components/format/Format'
import { BooleanFieldMetadata } from 'components/metadata/BooleanFieldMetadata'
import { DateFieldMetadata } from 'components/metadata/DateFieldMetadata'
import { NumberFieldMetadata } from 'components/metadata/NumberFieldMetadata'
import { SiretFieldMetadata } from 'components/metadata/SiretFieldMetadata'
import Button from 'components/reform/Button'
import CurrencyField from 'components/reform/inputs/CurrencyField'
import { addMonths, differenceInMonths, isAfter, isBefore } from 'date-fns'
import log from 'loglevel'
import { detailDossierDocumentsRefreshEvent } from 'pages/detail-dossier/DetailDossierContrat'
import { SECTION_FORMATION_FRAIS } from 'pages/dossier-capp/CAPPFormationMetadata'
import { TOOLTIPS } from 'pages/dossier/Tooltips'
import { Value } from 'pages/dossier/cerfa/CerfaDpc'
import Infos from 'pages/dossier/cerfa/Infos'
import { exportObject } from 'pages/dossier/metadata/ExportForm'
import { SimpleFormElements } from 'pages/dossier/metadata/FormElements'
import { BaseFormMetadata, useSimpleYopSchema } from 'pages/dossier/metadata/FormMetadata'
import { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { nil } from 'slices/dossierCommon'

interface fraisAnnexes {
    restauration: number
    hebergement: number
    equipementCout: number
    majorationHandicapCout: number
    international: boolean
}

function createPoursuiteInfo(dossier: DossierDetail) {
    return {
        dateFinContrat: parseDate(dossier.DateFinContrat__c),
        dateRupture: parseDate(dossier.DateRuptureCA__c),
        fraisAnnexesInitial: {} as fraisAnnexes,
        poursuite: true as boolean,
        dateSortie: nil as (Date | null),
        nouveauContrat: nil as (boolean | null),
        nouveauContratDateDebut: nil as (Date | null),
        nouveauContratSiret: nil as (string | null),
        fraisAnnexesRestauration: nil as (number | null),
        fraisAnnexesHebergement: nil as (number | null),
        fraisAnnexesEquipementCout: nil as (number | null),
        majorationHandicapCout: nil as (number | null),
        fraisAnnexesInternational: nil as (boolean | null),
    }
}
type PoursuiteInfo = ReturnType<typeof createPoursuiteInfo>

const poursuiteInfoFormMetadata: BaseFormMetadata<Omit<PoursuiteInfo, 'dateFinContrat' | 'dateRupture' | 'fraisAnnexesInitial'>> = {
    title: 'Informations poursuite de formation après rupture',
    fields: {
        poursuite: BooleanFieldMetadata({
            props: {
                label: "Maintien ou non de la formation au sein du CFA ?",
                onChange: (value, context) => {
                    if (value === false) {
                        context.setValue('nouveauContrat', null, SetValueOptions.Untouch)
                        context.setValue('dateSortie', null, SetValueOptions.Untouch)
                        context.setValue('nouveauContratDateDebut', null, SetValueOptions.Untouch)
                        context.setValue('nouveauContratSiret', null, SetValueOptions.Untouch)
                        context.setValue('fraisAnnexesRestauration', null, SetValueOptions.Untouch)
                        context.setValue('fraisAnnexesHebergement', null, SetValueOptions.Untouch)
                        context.setValue('fraisAnnexesEquipementCout', null, SetValueOptions.Untouch)
                        context.setValue('majorationHandicapCout', null, SetValueOptions.Untouch)
                        context.setValue('fraisAnnexesInternational', null, SetValueOptions.Untouch)
                    }
                },
            },
            visibleIf: ctx => ctx.parent?.poursuite === false,
        }).required(),
        nouveauContrat: BooleanFieldMetadata({
            props: {
                label: "Signature d'un nouveau contrat ?",
                onChange: (value, context) => {
                    if (value === false) {
                        context.setValue('nouveauContratDateDebut', null, SetValueOptions.Untouch)
                        context.setValue('nouveauContratSiret', null, SetValueOptions.Untouch)
                    } else if (value === true) {
                        context.setValue('dateSortie', null, SetValueOptions.Untouch)
                    }
                },
            },
            visibleIf: ctx => ctx.parent?.poursuite === true,
        }).mutate(yop => yop.requiredIf(ctx => ctx.parent?.poursuite === true)),
        dateSortie: DateFieldMetadata({
            props: {
                label: "Sortie de la formation sans contrat : date de sortie effective",
            },
            visibleIf: ctx => ctx.parent?.poursuite === true && ctx.parent?.nouveauContrat === false,
        }).mutate(yop => yop
            .requiredIf(ctx => ctx.parent?.poursuite === true && ctx.parent?.nouveauContrat === false)
            .test<PoursuiteInfo>(ctx => {
                if (ctx.value != null) {
                    const dateSortie = ctx.value
                    if (ctx.parent?.dateRupture != null) {

                    if (isBefore(dateSortie, ctx.parent.dateRupture))
                        return ctx.createError(`Doit être postérieure ou égale à la date de rupture (${formatDate(ctx.parent?.dateRupture)})`)
                    
                    const sixMonthsAfterRupture = addMonths(ctx.parent?.dateRupture, 6)
                    
                    if (isAfter(dateSortie, sixMonthsAfterRupture)) {
                        return ctx.createError(`Doit être dans les six mois suivant la date de rupture (${formatDate(sixMonthsAfterRupture)})`);
                    }
                }
                    if (ctx.parent?.dateFinContrat != null && isAfter(dateSortie, ctx.parent.dateFinContrat))
                        return ctx.createError(`Doit être antérieure ou égale à la date de fin du contrat (${formatDate(ctx.parent?.dateFinContrat)})`)
                  
                }
                return true
            })
        ),
        nouveauContratDateDebut: DateFieldMetadata({
            props: {
                label: "Date de début d’exécution du nouveau contrat",
            },
            visibleIf: ctx => ctx.parent?.poursuite === true && ctx.parent?.nouveauContrat === true,
        }).mutate(yop => yop
            .requiredIf(ctx => ctx.parent?.poursuite === true && ctx.parent?.nouveauContrat === true)
            .test<PoursuiteInfo>(ctx => {
                if (ctx.value != null) {
                    const dateDebut = ctx.value
                    if (ctx.parent?.dateRupture != null && isBefore(dateDebut, ctx.parent.dateRupture))
                        return ctx.createError(`Doit être postérieure ou égale à la date de rupture (${formatDate(ctx.parent?.dateRupture)})`)
                    if (ctx.parent?.dateFinContrat != null && isAfter(dateDebut, ctx.parent.dateFinContrat))
                        return ctx.createError(`Doit être antérieure ou égale à la date de fin du contrat (${formatDate(ctx.parent?.dateFinContrat)})`)
                }
                return true
            })
        ),
        nouveauContratSiret: SiretFieldMetadata({
            props: {
                label: "SIRET de l’employeur du nouveau contrat conclu",
            },
            visibleIf: ctx => ctx.parent?.poursuite === true && ctx.parent?.nouveauContrat === true,
        }).mutate(yop => yop.requiredIf(ctx => ctx.parent?.poursuite === true && ctx.parent?.nouveauContrat === true)),
        fraisAnnexesRestauration: NumberFieldMetadata({
            section: SECTION_FORMATION_FRAIS,
            props: {
                label: 'Nb de repas ajusté',
                tooltip: TOOLTIPS.capp.formation.fraisAnnexesRestauration,
                suffix: 'repas',
            },
            visibleIf: ctx => ctx.parent?.fraisAnnexesInitial?.restauration != null,
        }).mutate(yop => yop
            .min(0)
            .max(ctx => ctx.parent?.fraisAnnexesInitial?.restauration, 'Le nb de repas ajusté doit être inférieur au nombre engagé ${max}')
            .ignoredIf(ctx => ctx.parent?.fraisAnnexesInitial?.restauration == null)
        ),
        fraisAnnexesHebergement: NumberFieldMetadata({
            section: SECTION_FORMATION_FRAIS,
            props: {
                label: 'Nb de nuitées ajusté',
                tooltip: TOOLTIPS.capp.formation.fraisAnnexesHebergement,
                suffix: 'nuit(s)',
            },
            visibleIf: ctx => ctx.parent?.fraisAnnexesInitial?.hebergement != null,
        }).mutate(yop => yop
            .min(0)
            .max(ctx => ctx.parent?.fraisAnnexesInitial?.hebergement, 'Le nb de nuitées ajusté doit être inférieur au nombre engagé ${max}')
            .ignoredIf(ctx => ctx.parent?.fraisAnnexesInitial?.hebergement == null)
        ),
        fraisAnnexesEquipementCout: NumberFieldMetadata({
            section: SECTION_FORMATION_FRAIS,
            props: {
                label: <span>Coût 1<sup>er</sup> équipement</span>,
                exportLabel: 'Montant 1er équipement ajusté',
                tooltip: TOOLTIPS.capp.formation.fraisAnnexesEquipementCout,
                suffix: '€',
            },
            visibleIf: ctx => ctx.parent?.fraisAnnexesInitial?.equipementCout != null,
            input: CurrencyField,
        }).mutate(yop => yop
            .min(0)
            .max(ctx => ctx.parent?.fraisAnnexesInitial?.equipementCout, 'Le montant ajusté doit être inférieur au montant engagé ${max}')
            .ignoredIf(ctx => ctx.parent?.fraisAnnexesInitial?.equipementCout == null)
        ),
        majorationHandicapCout: (
            NumberFieldMetadata({
                props: {
                    label: 'Montant majoration handicap ajusté',
                    tooltip: TOOLTIPS.capp.formation.majorationHandicapCout,
                    suffix: '€',
                },
                section: SECTION_FORMATION_FRAIS,
                input: CurrencyField,
                visibleIf: ctx => ctx.parent?.fraisAnnexesInitial?.majorationHandicapCout != null,
            })
                .mutate(yop => yop
                    .min(50, 'La majoration handicap doit être supérieure ou égale à 50 €')
                    .max(ctx => ctx.parent?.fraisAnnexesInitial?.majorationHandicapCout, 'Le montant ajusté doit être inférieur au montant engagé ${max}')
                    .test(context => {
                        const dateDebut = context.root?.contrat?.dateDebut
                        const dateFin = context.root?.contrat?.dateFin
                        if (dateDebut != null && dateFin != null) {
                            const nbMois = differenceInMonths(dateFin, dateDebut)
                            if (nbMois < 13 && (context.value ?? 0) > 4000)
                                return context.createError('La majoration handicap doit être inférieure ou égale à 4 000 € lorsque la durée du contrat est inférieure à 13 mois')
                            if (nbMois <= 24 && (context.value ?? 0) > 8000)
                                return context.createError('La majoration handicap doit être inférieure ou égale à 8 000 € lorsque la durée du contrat est inférieure ou égale à 24 mois')
                        }
                        return true
                    })
                    .ignoredIf(ctx => ctx.parent?.fraisAnnexesInitial?.majorationHandicapCout == null)
                )
        ),
        fraisAnnexesInternational: BooleanFieldMetadata({
            section: SECTION_FORMATION_FRAIS,
            props: {
                label: 'Maintien ou non de la mobilité internationale',
                full: true
            },
            visibleIf: ctx => ctx.parent?.fraisAnnexesInitial?.international === true,
        }).mutate(yop => yop.requiredIf(ctx => ctx.parent?.fraisAnnexesInitial?.international === true)),
    }
}

interface InformationPoursuitePostRuptureModalPrestataireProps {
    show: boolean
    close: () => void
    dossier: DossierDetail
}

export default function InformationPoursuitePostRuptureModalPrestataire(props: InformationPoursuitePostRuptureModalPrestataireProps) {

    const { fulfilled: refs } = useSelector((state: RootState) => state.referencesState)

    const initialValues = createPoursuiteInfo(props.dossier)
    const [error, setError] = useState('')

    const validationSchema = useSimpleYopSchema(poursuiteInfoFormMetadata, initialValues)

    const onClose = (resetForm: Function) => {
        resetForm()
        props.close()
    }

    const sendPoursuite = async (context: UseFormReturn<PoursuiteInfo>) => {
        if (context.values!.poursuite === null)
            return

        setError('')
        context.setSubmitting(true)
        const motifLabel = props.dossier?.MotifRuptureCA__c ? formatRef(refs?.REF_MOTIF_ADMINISTRATIF, props.dossier?.MotifRuptureCA__c) : ''
        try {
            const instance = pdf(<Document />)
            const data = exportObject(poursuiteInfoFormMetadata, context.values, `Contrat d'apprentissage n°${props.dossier.NumeroDossier__c}`)
            data.tables[0].values.unshift(
                [<Value row value={{
                    label: "Nom et prénom de l'apprenti(e)",
                    value: props.dossier.Stagiaires[0] ? (props.dossier.Stagiaires[0].Nom__c + ' ' + props.dossier.Stagiaires[0].Prenom__c).trim() : ''
                }}
                />],
                [<Value row value={{ label: "Nom de l'entreprise", value: props.dossier?.EtablissementAdherent?.Name }} />],
                [<Value row value={{ label: "Nom CFA", value: props.dossier?.Modules[0].NomOF__c }} />],
                [<Value row value={{ label: "Motif de rupture", value: motifLabel ?? '' }} />],
                [<Value row value={{ label: "Date de rupture", value: formatDate(props.dossier?.DateRuptureCA__c) }} />],
            )
            instance.updateContainer(<Infos data={data} />)
            const blob = await instance.toBlob()
            // Debug:
            // var blobUrl = URL.createObjectURL(blob)
            // window.location.replace(blobUrl)

            const poursuiteInfo: PoursuitePostRuptureDossierInfo = {
                numeroDossier: props.dossier?.NumeroDossier__c,
                nomCerfa: 'DEMANDE_POURSUITE',
                fileCerfa: blob,
                fileNameCerfa: 'demande-poursuite-' + props.dossier?.NumeroDossier__c + '.pdf',
                poursuite: context.values!.poursuite,
            }
            if (poursuiteInfo.poursuite) {
                poursuiteInfo.nouveauContrat = context.values!.nouveauContrat ?? undefined
                if (poursuiteInfo.nouveauContrat === false) {
                    poursuiteInfo.dateSortie = context.values!.dateSortie ? date2APIDate(context.values!.dateSortie) : undefined
                } else if (poursuiteInfo.nouveauContrat === true) {
                    poursuiteInfo.nouveauContratDateDebut = context.values!.nouveauContratDateDebut ? date2APIDate(context.values!.nouveauContratDateDebut) : undefined
                    poursuiteInfo.nouveauContratSiret = context.values!.nouveauContratSiret ?? undefined
                }
                if (context.values!.fraisAnnexesInitial?.restauration != null)
                    poursuiteInfo.fraisAnnexesRestauration = context.values!.fraisAnnexesRestauration ?? undefined
                if (context.values!.fraisAnnexesInitial?.hebergement != null)
                    poursuiteInfo.fraisAnnexesHebergement = context.values!.fraisAnnexesHebergement ?? undefined
                if (context.values!.fraisAnnexesInitial?.equipementCout != null)
                    poursuiteInfo.fraisAnnexesEquipementCout = context.values!.fraisAnnexesEquipementCout ?? undefined
                if (context.values!.fraisAnnexesInitial?.majorationHandicapCout != null)
                    poursuiteInfo.majorationHandicapCout = context.values!.majorationHandicapCout ?? undefined
                if (context.values!.fraisAnnexesInitial?.international != null)
                    poursuiteInfo.fraisAnnexesInternational = context.values!.fraisAnnexesInternational ?? undefined
            }
            await postPoursuitePostRuptureDossier(poursuiteInfo)
            log.debug(`Upload DEMANDE_POURSUITE ok`)
            onClose(context.reset)
        }
        catch (e: any) {
            log.error(`Erreur upload DEMANDE_POURSUITE pour ${props.dossier?.NumeroDossier__c}`, e)
            //setUploading(false)
            setError(e.response && e.response.data && e.response.data.message ? e.response.data.message : "Erreur lors de l'envoi de la demande")
            context.setSubmitting(false)
            window.scrollTo({ top: 0, left: 0, behavior: 'auto' })
            throw e
        }
        setTimeout(() => {
            dispatchAppEvent(detailDossierDocumentsRefreshEvent)
            ToastService.showToast({
                label: 'Demander la poursuite post-rupture',
                type: 'success',
                content: 'Votre demande a bien été prise en compte. Elle sera traitée dans les meilleurs délais',
            })
            context.setSubmitting(false)
            onClose(context.reset)
        }, 3000)
    }

    const form = useForm({
        initialValues,
        validationSchema,
        dispatchEvent: false,
        onSubmit: sendPoursuite,
    })

    const [{ pending }, withGetPoursuitePostRuptureDossier] = useApiState(getPoursuitePostRuptureDossier)
    useEffect(() => {
        if (props.dossier.NumeroDossier__c != null) {
            withGetPoursuitePostRuptureDossier(props.dossier.NumeroDossier__c).then(value => {
                form.setValue(
                    'fraisAnnexesInitial',
                    {
                        restauration: value.Nb_Repas,
                        hebergement: value.Nb_Nuite,
                        equipementCout: value.CoutEquipement,
                        majorationHandicapCout: value.MajorationHandicapIndividualisé,
                        international: value.MobilitInternationale,
                    },
                    true
                )
            })
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [withGetPoursuitePostRuptureDossier, props.dossier.NumeroDossier__c])

    return (
        <MyModal
            show={props.show}
            onClose={() => onClose(form.reset)}
            label={`Demande de poursuite de la formation après rupture du contrat d'apprentissage N° ${props.dossier.NumeroDossier__c}`}>
            <AtlasLoading loading={pending}>
                <Form context={form} noValidate autoComplete="off" disabled={pending}>
                    <MyForm>
                        <AtlasLoading.Loader />

                        <AtlasInfo
                            type="info" title={''}                    >
                            <p>
                                Cette déclaration est à réaliser sur un contrat dont la rupture a été signalée à l'Opco, dans la mesure du possible par l'employeur, et traitée par nos services, dès lors que la formation en CFA post rupture se poursuit sans contrat pendant une durée maximale de 6 mois.  </p>
                            <p>Désormais, au moment du traitement de la déclaration de rupture, l'engagement financier est revu, considérant par défaut l'absence du maintien en formation. Aussi, en cas d'abandon de la formation au moment de la rupture, vous n'avez donc pas d'action complémentaire à faire après la déclaration de la rupture. </p>
                            <p>Le nouvel échéancier de facturation est d'ores et déjà ajusté, la facture peut être transmise.</p>
                            <p>En cas de poursuite de la formation en CFA après la rupture du contrat, le CFA doit signaler le maintien du jeune en formation via ce formulaire.</p>
                            <p>Il est fortement recommandé de réaliser cette démarche au moment de la conclusion d'un nouveau contrat ou à l'issue de la réalisation de la formation sans contrat (dans la limite de 6 mois).</p>
                        </AtlasInfo>
                        <SimpleFormElements metadata={poursuiteInfoFormMetadata} section="" />

                        {(form.values?.fraisAnnexesInitial?.restauration != null
                            || form.values?.fraisAnnexesInitial?.hebergement != null
                            || form.values?.fraisAnnexesInitial?.equipementCout != null
                            || form.values?.fraisAnnexesInitial?.majorationHandicapCout != null
                            || form.values?.fraisAnnexesInitial?.international === true
                        ) && <MyForm.Fieldset legend="Frais annexes">
                                <MyForm.Grid>
                                    <SimpleFormElements metadata={poursuiteInfoFormMetadata} section={SECTION_FORMATION_FRAIS} />
                                </MyForm.Grid>
                            </MyForm.Fieldset>}

                        {error && <AtlasInfo type="error" title="Erreur" >{error}</AtlasInfo>}

                        <MyForm.Actions>
                            <Button submit={true} spinner={{ spinning: form.submitting }}>Confirmer</Button>
                            <Button level={2} onClick={() => onClose(form.reset)}>Retour</Button>
                        </MyForm.Actions>
                    </MyForm>
                </Form>
            </AtlasLoading>
        </MyModal>
    )
}

