import { Entreprise } from "api/referencesAPI"

import { TYPE_ENTREPRISE, TYPE_ORGANISME } from "api/references"
import { AtlasLoading, MyTable } from 'atlas-ds'
import Link from "components/Link"



export function ListeMesEntreprises(props: { entreprises: Entreprise[] | null, loading: boolean, type: string }) {

    const entrepriseActions = (entreprises: any) => {

        const basePath = props.type === TYPE_ENTREPRISE ? '/mes-entreprises/detail/' : '/mes-prestataires/detail/'
       
        return [
            <Link key="detail" icon="details"  level={3}  to={basePath + entreprises.Id}>Voir le détail</Link>,
        ]
    }

    return (
        <AtlasLoading loading={props.loading}>
            <MyTable
                caption={props.type === TYPE_ENTREPRISE ? "Mes entreprises" : "Mes prestataires"}
                rows={props.entreprises ?? []}
                emptyText={props.type === TYPE_ENTREPRISE ? "Aucune entreprise" : "Aucun prestataire"}
                rowActions={entrepriseActions}
                columns={[
                    {
                        id: "Name",
                        label: "Raison Sociale"
                    },
                    {
                        id: "BillingCity",
                        label: "Commune"
                    },
                    {
                        id: "SIREN__c",
                        label: "Siren"
                    },
                    {
                        id: "IdSOR",
                        label: (props.type===TYPE_ORGANISME) ? "Code OF" :"Code adhérent"
                    }
                ]}
            />
        </AtlasLoading>
    )
}