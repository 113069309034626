import { AtlasSpacer, AtlasLink, AtlasHtml } from "atlas-ds"
import PageHeader from "components/PageHeader"

export function CompteSupprime() {
    return (
        <>
            <PageHeader>
                Votre compte a bien été supprimé. Au revoir !
            </PageHeader>

            <AtlasHtml>
              <p>Une erreur, un besoin d'aide ? Vous pouvez toujours <a href="https://www.opco-atlas.fr/contact.html">nous contacter</a>.</p>
            </AtlasHtml>
            
            <AtlasSpacer size="xl" />

            <AtlasLink href="https://www.opco-atlas.fr/">Revenir au site d'Opco Atlas</AtlasLink>
        </>
    );

}