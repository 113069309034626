import { DEMANDE_EN_COURS_CORRECTION_CONTRAT, DISPOSITIF_CONTRAT_PROFESSIONNALISATION, ETAT_DOSSIER_ANNULE, ETAT_DOSSIER_EN_COURS, ETAT_DOSSIER_INCOMPLET } from 'api/references'
import { AtlasButton } from 'atlas-ds'
import DepotAvenantModal from 'components/avenant/DepotAvenantModal'
import { parseDate } from 'components/format/Format'
import { addDays, isBefore, startOfDay } from 'date-fns'
import { checkDetailAction } from 'pages/detail-common/Detail'
import { useState } from 'react'
import { DossierActionProps } from './DetailDossierContrat'


export default function DossierAvenant(props: DossierActionProps) {
    const dossier = checkDetailAction(props)
    if (!dossier) return null
    
    const [showAvenant, setShowAvenant] = useState<'cpro' | 'lettre' | undefined>()
    const titleButton = [ETAT_DOSSIER_ANNULE, ETAT_DOSSIER_EN_COURS, ETAT_DOSSIER_INCOMPLET].includes(dossier?.EtatDossierFO__c ?? "") ?
        'Corriger le contrat' :
        'Transmettre un avenant / Corriger le contrat'
    const isConditionConformite = !(
        dossier?.IndicateurConformite__c === 'Vert' &&
        dossier?.DateReception__c != null &&
        isBefore(Date.now(), addDays(startOfDay(parseDate(dossier?.DateReception__c)!), 1))
    )
    const demandes = dossier.DemandesEnCours ?? []

    return <>
        { isConditionConformite && !dossier?.cproExperimentalC &&
        <AtlasButton
            level={ 2 }
            disabled={demandes?.includes(DEMANDE_EN_COURS_CORRECTION_CONTRAT) }
            onClick={() => setShowAvenant(dossier?.DispositifFO__c === DISPOSITIF_CONTRAT_PROFESSIONNALISATION ? 'cpro' : 'lettre')}
        >Transmettre un avenant / Corriger le contrat</AtlasButton>
        }

        { dossier != null && (

        showAvenant === 'lettre' ?
        <DepotAvenantModal show={true} close={() => setShowAvenant(undefined)} dossier={dossier} title={titleButton} /> :

        showAvenant === 'cpro' ?
        <DepotAvenantModal show={true} close={() => setShowAvenant(undefined)} dossier={dossier} title={titleButton} /> :

        null )}
    </>
}
 