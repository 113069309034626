import { Dossier, DossierDetail, DossiersSearchQuery, exportDossiers, getDossiers } from 'api/dossierAPI'
import { ETAT_DOSSIER_BROUILLON, ETAT_DOSSIER_INCOMPLET, findDispositifsEligiblesParBranche, getAllDispositifFO, getAllEtatDossierFO } from 'api/references'
import { Reference } from 'api/referencesAPI'
import { RootState } from 'app/rootReducer'
import { AtlasButton, AtlasText, MyTableColumn } from 'atlas-ds'
import VoirMesRapports from 'components/blocs/VoirMesRapports'
import { formatCurrency } from 'components/format/Format'
import Link from 'components/Link'
import XLinkDossier from 'components/link/XLinkDossier'
import NewRequest from 'components/NewRequest'
import { FiltreCheckboxes, FiltrePeriode, FiltreRadioGroup, FiltreRecherche } from 'components/recherche/Filtres'
import RechercheBase, { DEFAULT_DATE_RANGES, FiltreFunctionProps, singleTab } from 'components/recherche/RechercheBase'
import DeleteModal from 'pages/dossier/DeleteModal'
import { ColonneDateSource, ColonneDateSourceFF, ColonneDispositif, ColonneEtat, ColonneFormationOrganismeFF, ColonneIntitule, ColonneMontantAccordeFF, ColonneNumero, ColonneNumeroFF, ColonneStagiaire, ColonneStagiaireFF, dateTypeOptions, defaultDateType, DossiersSearchQueryImpl } from 'pages/mes-dossiers-contrats/MesDossiersContrats'
import { useMemo, useState } from 'react'
import { useSelector } from 'react-redux'


export default function MesDossiersEntreprise() {

    const [showDeleteModal, setShowDeleteModal] = useState<{ show: boolean, dossier?: Dossier }>({ show: false })
    
    const { entreprise } = useSelector((state: RootState) => state.contextState)
    const allDispositifs = useMemo(() => {
        const parBranche = findDispositifsEligiblesParBranche(entreprise)
        return getAllDispositifFO()?.filter(d => parBranche.includes(d.Id)) ?? []
    }, [entreprise])
    const dispositifCheckboxes = allDispositifs.map((dispositif: Reference) => ({
        label: dispositif.Libelle__c,
        value: dispositif.Id
    }))

    const etatDossiersCheckboxes = (getAllEtatDossierFO() ?? []).map((etat: Reference) => ({
        label: etat.Libelle__c,
        value: etat.Id
    }))

    const headerActions = <>
        <VoirMesRapports />
        <NewRequest menu="accueil" />
    </>

    const financementVV = entreprise?.VV__c === true

    const getFiltres = (props: FiltreFunctionProps) => {
        const filtres = [
            FiltreRecherche({ label: "Recherche", placeholder: "Nom ou prénom stagiaire, intitulé, numéro..." }),
            FiltrePeriode({ label: "Par date de ", placeholder: "Choisir une période", minDate: new Date(2019, 0, 1), defaultDateType: defaultDateType, dateTypeOptions: dateTypeOptions, dateRanges: DEFAULT_DATE_RANGES }),
            FiltreCheckboxes({ name: 'dispositifIds', label: "Dispositif", valueName: 'dispositifIds', queryName: 'dispositifFo', paramName: 'dispositif-fo', checkboxes: dispositifCheckboxes }),
            FiltreCheckboxes({ name: 'etatDossierIds', label: "État du dossier", valueName: 'etatDossierIds', queryName: 'etatDossierFo', paramName: 'etat-dossier-fo', checkboxes: etatDossiersCheckboxes }),

            ... import.meta.env.REACT_APP_RELANCE === 'true'
                ? [
                    FiltreRadioGroup({
                        name: 'alerte',
                        label: "Dossier en alerte",
                        paramName: 'alerte',
                        defaultValue: '',
                        options: [
                            { label: "Oui", value: "oui" },
                            { label: "Non", value: "non" },
                            { label: "Tous", value: "" },
                        ]
                    }),
                    FiltreRadioGroup({
                        name: 'demande',
                        label: "Demande en cours de traitement par Atlas",
                        paramName: 'demande',
                        defaultValue: '',
                        options: [
                            { label: "Oui", value: "oui" },
                            { label: "Non", value: "non" },
                            { label: "Tous", value: "" },
                        ]
                    }),
                ] : [
                    FiltreRadioGroup({ 
                        name: 'utilisateur', 
                        label: "Dossiers édités par", 
                        paramName: 'utilisateur', 
                        defaultValue: 'tous', 
                        options: [
                            { label: "Tous", value: "tous" },
                            { label: "Moi", value: "moi" },
                        ]
                    }),
                ],
        ]
        if (financementVV) {
            filtres.push(
                FiltreRadioGroup({ name: 'financementVV', label: "Financement VV", paramName: 'financement-vv', defaultValue: 'tous', options: [
                    { label: "Oui", value: "oui" },
                    { label: "Non", value: "non" },
                    { label: "Tous", value: "tous" }
                ] })
            )
        }
        return filtres
    }

    const colonnes = (query: DossiersSearchQuery): MyTableColumn[] => import.meta.env.REACT_APP_RELANCE === 'true' ? [
        ColonneNumeroFF,
        ColonneStagiaireFF,
        ColonneFormationOrganismeFF,
        ColonneDateSourceFF(query),
        ColonneMontantAccordeFF,
        ...(import.meta.env.REACT_APP_SHOW_SOLDE === 'true' ? [ColonneEntrepriseSoldeFF] : []),
    ] : [
        ColonneNumero,
        ColonneStagiaire,
        ColonneIntitule,
        ColonneDispositif,
        ColonneDateSource(query),
        ColonneEtat,
    ]

    const tableActions = (dossier: Dossier) => {
        const actions = []
        if (dossier.EtatDossierFORef?.Id === ETAT_DOSSIER_BROUILLON) {
            actions.push(
                <AtlasButton
                    key="supprimer"
                    icon="trash"
                    level={ 3 }
                    onClick={() => setShowDeleteModal({show: true, dossier: dossier})}
                >Supprimer</AtlasButton>
            )
        }
        actions.push(<XLinkDossier key="voirDossier" dossier={dossier} />)
        if (dossier.EtatDossierFORef?.Id === ETAT_DOSSIER_INCOMPLET) {
            actions.push(
                <Link
                    key="ajouterDocuments"
                    icon="add"
                    level={ 3 }
                    to={{
                        pathname: 'detail-dossier-contrat/' + dossier.Id,
                        hash: '#documents',
                        state: { showIncomplete: true }
                    }}
                >Ajouter des documents</Link>
            )
        }
        return actions
    }

    const newQuery = (params: URLSearchParams) => new DossiersSearchQueryImpl(params, { withVV: financementVV })

    return (
        <RechercheBase<Dossier, DossiersSearchQueryImpl>
            name="MesDossiers"
            title="Dossiers"
            headerActions={ headerActions }
            newQuery={ newQuery }
            filtres={ getFiltres }
            colonnes={ colonnes }
            tabs={ singleTab(getDossiers) }
            executeExport={ exportDossiers }
            getErrorRowsIndexes={
                import.meta.env.REACT_APP_RELANCE === 'true' ?
                rows => new Set<number>(rows.map((row, index) => row.TypeAlerteADH__c ? index : -1).filter(index => index != -1)) :
                undefined
            }
            errorLabel="Ce dossier est en alerte"
            rowIcon={ row => (row.DemandesEnCours ?? []).length > 0 ? {
                    name: "hourglass",
                    label: "En cours de traitement",
                    tooltipContent: "Ce dossier est en cours de traitement chez Atlas"
                } : undefined
            }
            tableActions={ tableActions }>
            { (showDeleteModal?.show === true && showDeleteModal?.dossier != null) &&
            <DeleteModal dossier={ showDeleteModal?.dossier } close={ () => setShowDeleteModal({ show: false }) } />
            }
        </RechercheBase>
    )
}

const showSolde = (row: DossierDetail) => (row.MontantSoldeADH__c ?? -1) >= 0 && (row.MontantSoldeOF__c ?? -1) >= 0

export const ColonneEntrepriseSoldeFF: MyTableColumn = {
    id: "solde",
    label: "Solde",
    nowrap: true,
    sortable: true,
    value: (row: DossierDetail) => [
        showSolde(row) ? <>{ formatCurrency(row.MontantSoldeADH__c) }&nbsp;<AtlasText small>Entreprise</AtlasText></> : null,
        showSolde(row) ? <>{ formatCurrency(row.MontantSoldeOF__c) }&nbsp;<AtlasText small>OF/CFA</AtlasText></> : null,
    ].filter(x => x !== null),
}