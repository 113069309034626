import { DISPOSITIF_CONTRAT_PROFESSIONNALISATION, ETAT_DOSSIER_ANNULE, ETAT_DOSSIER_BROUILLON } from 'api/references'
import DossierDeposerFactureSelfe from '../DossierDeposerFactureSelfe'
import { DossierDetail } from 'api/dossierAPI'
import DossierConsulterEcheancier from '../DossierConsulterEcheancier'
import { dossierEstAccorde, dossierEstEnRupture } from '.'
import { DossierActionElement } from '../DetailDossierContrat'


export function actionsPrestataireDeposerProforma(dossier: DossierDetail, actions: DossierActionElement[]) {
    actions.push(<DossierDeposerFactureSelfe key="genererProforma" detail={ dossier } when={ dossierEstAccorde } mode="proforma" />)
    actions.push(<DossierConsulterEcheancier key="consulterEcheancier" detail={ dossier } when={ dossierEstAccorde } />)
}

export function actionsPrestataireRuptureDeposerFacture(dossier: DossierDetail, actions: DossierActionElement[], avecEcheancier = false) {
    actions.push(<DossierDeposerFactureSelfe key="deposerFacture" detail={ dossier } when={ dossierEstEnRupture } mode="facture" />)
    if (avecEcheancier)
        actions.push(<DossierConsulterEcheancier key="consulterEcheancier" detail={dossier} when={ dossierEstEnRupture} />)
}

export function actionsPrestataireAccordeDeposerFacture(dossier: DossierDetail, actions: DossierActionElement[]) {
    actions.push(<DossierDeposerFactureSelfe key="deposerFacture" detail={ dossier } when={ dossierEstAccorde } mode="facture"  />)
}
