import { Form, UseFormReturn, useForm } from "@dsid-opcoatlas/reform"
import { Yop } from "@dsid-opcoatlas/yop"
import { CompteSel, EntrepriseDetail, postDetacherEntrepriseExterne } from "api/monCompteAPI"
import { TYPE_ENTREPRISE } from "api/references"
import { nil } from "app/public/MamcApp"
import { AtlasInfo, MyForm, MyModal } from "atlas-ds"
import { ToastService } from "components/BandeauAlert"
import Button from "components/reform/Button"
import SelectField from "components/reform/inputs/SelectField"
import { RequiredString } from "components/yop/constraints"

interface DetacherEntrepriseExterneProps {
    type: string;
    compte: CompteSel
    entreprise: EntrepriseDetail
    onSuccess: () => void
    onClose: () => void
}

export default function DetacherEntrepriseExterne(props: DetacherEntrepriseExterneProps) {
    const initialValues = {
        raisonAction: nil as string,
    }

    type DetachementEntrepriseExterne = {
        raisonAction: string,
    }

    const validationSchema = Yop.object<DetachementEntrepriseExterne>({
        raisonAction: RequiredString
    })

    const detacherEntrepriseExterneForm = useForm({
        initialValues,
        validationSchema,
        dispatchEvent: false,
        onSubmit(context: UseFormReturn<DetachementEntrepriseExterne>) {
            postDetacherEntrepriseExterne(props.type, props.entreprise.AccountDTO.SIREN__c, props.compte!.Email, context.values!.raisonAction)
                .then(_ => {
                    ToastService.showToast({
                        label: "Détachement de compte",
                        type: 'success',
                        content: `Le compte attaché à l'adresse ${props.compte.Email} a bien été détaché.`
                    })
                    props.onSuccess();
                  }).finally(() => {
                    props.onClose();
                    context.setSubmitting(false);
                })  
        },
    })

    return <MyModal
        label={`Détacher le compte ${props.compte?.LastName} ${props.compte?.FirstName} de ${props.entreprise.AccountDTO.Name}`}
    >
        <Form context={ detacherEntrepriseExterneForm } noValidate>
            <MyForm>
                {/* Usage exceptionnel de type="error" à la place de "warning" car action critique */}
                <AtlasInfo type="error" title="Attention">
                    L'utilisateur associé à ce compte n'aura plus accès à la gestion de {props.type === TYPE_ENTREPRISE ? "cette entreprise" : "ce prestataire"} et de ses dossiers sur myAtlas.
                </AtlasInfo>

                <SelectField
                    selectionner
                    label="Raison du détachement"
                    name="raisonAction"
                    options={[
                        {
                            value: "2",
                            label: "Ne travaille plus dans l'entreprise ou dans le service"
                        },
                        {
                            value: "1",
                            label: "Compte suspect"
                        }
                    ]}
                />
    
                <MyForm.Actions>
                    <Button destructive submit={ true } spinner={{spinning: detacherEntrepriseExterneForm.submitting }}>Confirmer</Button>
                    <Button onClick={() => props.onClose()} level={2}>Annuler</Button>
                </MyForm.Actions>
            </MyForm>
        </Form>
    </MyModal>
}
