import { Form, UseFormReturn, useForm } from "@dsid-opcoatlas/reform"
import { Yop } from "@dsid-opcoatlas/yop"
import { patchContact } from "api/monCompteAPI"
import { Profil } from "api/profilAPI"
import { RootState } from "app/rootReducer"
import { useAppDispatch } from "app/store"
import { AtlasColumns, AtlasHeading, AtlasInfo, AtlasSpacer, MyContainer, MyForm } from "atlas-ds"
import { ToastService } from "components/BandeauAlert"
import Link from "components/Link"
import PageHeader from "components/PageHeader"
import Button from "components/reform/Button"
import PhoneField from "components/reform/inputs/PhoneField"
import RadioBooleanField from "components/reform/inputs/RadioBooleanField"
import RadioField from "components/reform/inputs/RadioField"
import SelectField from "components/reform/inputs/SelectField"
import TextField from "components/reform/inputs/TextField"
import { Ignored, RequiredBoolean, RequiredPhone, RequiredString } from "components/yop/constraints"
import log from "loglevel"
import { useEffect, useState } from "react"
import { useSelector } from "react-redux"
import { profilActions } from "slices/profilSlice"
import SupprimerMonCompteModal from "./SupprimerMonCompteModal"


const createMesInformationsValues = (profil?: Profil | null) => {
    return {
        civilite: profil?.Contact?.Salutation,
        prenom: profil?.Contact?.FirstName,
        nom: profil?.Contact?.LastName,
        email: profil?.Contact?.Email,
        telephone: profil?.Contact?.Phone?.replaceAll(/\s/g, '') ?? null,
        emailFrequency: profil?.Contact?.FrequenceNotificationEmail__c ?? "Jamais",
        newsletterNotification: profil?.Contact?.AbonnementNewsletter__c ?? false
    }
}

type MesInformationsValues = ReturnType<typeof createMesInformationsValues>



export function MesInformations() {
    const dispatch = useAppDispatch()

    useEffect(() => {
        dispatch(profilActions.get())
    }, [dispatch])

    const { pending: loading, profil, appTypes } = useSelector((state: RootState) => state.profilState)
    const { fulfilled: references } = useSelector((state: RootState) => state.referencesState)

    const [backendError, setBackendError] = useState<string | null>(null)
    
    const initialValues = createMesInformationsValues(profil)
    const validationSchema = Yop.object<MesInformationsValues>({
        civilite: Ignored,
        prenom: Ignored,
        nom: Ignored,
        email: Ignored,
        telephone: RequiredPhone,
        newsletterNotification: RequiredBoolean,
        emailFrequency: RequiredString.oneOf(["Quotidien", "Hebdomadaire", "Mensuel", "Jamais"])
    })

    const civiliteOptions = references?.REF_CIVILITE?.map(civilite => (
        {value: civilite.Id, label: civilite.LibelleReduit__c}
    )) ?? []

    const form = useForm<MesInformationsValues>({
        initialValues,
        validationSchema,
        dispatchEvent: false,
        onSubmit: async (context: UseFormReturn<MesInformationsValues>) => {
            const compte = context.values!
            patchContact(JSON.stringify({
                'Phone': compte.telephone,
                'NotificationEmail__c': compte.emailFrequency !== "Jamais",
                'AbonnementNewsletter__c': compte.newsletterNotification,
                'FrequenceNotificationEmail__c': compte.emailFrequency !== "Jamais" ? compte.emailFrequency : undefined
            }))
                .then(_ => {
                    ToastService.showToast({
                        type: "success",
                        label: "Mon Compte",
                        content: "Vos informations ont été mises à jour"
                    })
                })
                .catch(error => {
                    log.error(error)
                    const message = error?.response?.data?.message ?? "Une erreur inattendue s'est produite."
                    setBackendError(message)
                })
                .finally(() => context.setSubmitting(false))
        }
    })

    const [showSupprimerMonCompteModal, setShowSupprimerMonCompteModal] = useState(false)

    return <>
        <main id="main">
            <AtlasColumns>
                <div>
                    <PageHeader
                        actions={[
                            <Link key="modifier-mdp" level={2} to="/mon-compte/mot-de-passe">Modifier mon mot de passe</Link>,
                            <Button key="supprimer-compte" level={2} onClick={() => setShowSupprimerMonCompteModal(true)}>Supprimer mon compte</Button>
                        ]}
                    >Mon compte</PageHeader>

                    <Form context={form} autoComplete="off" noValidate disabled={loading}>
                        <MyContainer>
                            <MyForm>
                                <MyForm.Grid>
                                    <MyForm.Field size="short" alone>
                                        <SelectField disabled label="Civilité" name="civilite" options={civiliteOptions} />
                                    </MyForm.Field>

                                    <TextField disabled name="prenom" label="Prénom"  />

                                    <TextField disabled name="nom" label="Nom" />

                                    <TextField disabled name="email" label="Email" />

                                    <PhoneField name="telephone" label="Téléphone" />
                                </MyForm.Grid>
                            </MyForm>
                        </MyContainer>

                        <AtlasSpacer size="xl" />

                        <AtlasHeading tag="h2" size="m">Abonnements</AtlasHeading>

                        <MyContainer>
                            <MyForm>
                                <RadioBooleanField label="Je souhaite m’abonner à la newsletter Atlas" name="newsletterNotification" />

                                {(appTypes ?? []).length > 0 && <RadioField
                                    label="Je souhaite recevoir mes notifications par email à une fréquence :"
                                    name="emailFrequency"
                                    options={[
                                        { label: "Quotidienne", value: "Quotidien" },
                                        { label: "Hebdomadaire", value: "Hebdomadaire" },
                                        { label: "Mensuelle", value: "Mensuel" },
                                        { label: "Jamais", value: "Jamais" }
                                    ]}
                                />}
                            </MyForm>
                        </MyContainer>

                        <AtlasSpacer size="xl" />

                        {backendError && <>
                            <AtlasInfo type="error" title="Erreur">{ backendError }</AtlasInfo>
                            <AtlasSpacer size="xl" />
                        </>}

                        <MyForm>
                            <MyForm.Actions>
                                <Button submit={true} spinner={{ spinning: form.submitting }}>Valider</Button>
                            </MyForm.Actions>
                        </MyForm>
                    </Form>
                </div>
            </AtlasColumns>

            { showSupprimerMonCompteModal && <SupprimerMonCompteModal
                onClose={ () => setShowSupprimerMonCompteModal(false) }
                profil={ profil }
            /> }
        </main>
    </>
}