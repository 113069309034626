
import { getEtablissementsPrestataire } from 'api/commonAPI'
import { TYPE_ORIGIN_PRESTATAIRE } from 'api/references'
import { AppInfo, AppState } from 'app/appInfo'
import { AtlasLogo } from 'atlas-ds'
import CompanySelector from 'components/CompanySelector'
import { NavNofications } from 'components/Nav'
import accueil from 'pages/accueil'
import aide from 'pages/aide-contacts'
import gestionCompteEntreprise from 'pages/gestion-compte-entreprise'
import cleCfa from 'pages/ma-cle-cfa'
import documents from 'pages/mes-documents'
import dossiers from 'pages/mes-dossiers-contrats'
import factures from 'pages/mes-factures'
import formations from 'pages/mes-formations'
import refsBancaires from 'pages/mes-refs-bancaires'
import reglements from 'pages/mes-reglements'
import { AppType } from 'slices/contextSlice'
import FinancialSummary from '../../components/FinancialSummary'
import AccesRapidesPrestataire from './accueil/AccesRapidesPrestataire'
import DetailDossierPrestataire from './mes-dossiers/DetailDossierPrestataire'
import dossiersContextPrestataire from './mes-dossiers/dossiersContextPrestataire'
import MesDossiersPrestataire from './mes-dossiers/MesDossiersPrestataire'
import MesFacturesPrestataire from './mes-factures/MesFacturesPrestataire'


const AppPrestataire: AppInfo = {
    id: 'MAP',
    app: AppType.Prestataire,
    name: 'Prestataire',
    logo: <AtlasLogo logo="prestataire" />,
    theme: 'prestataire',
    navLinks: [ <NavNofications isPrestataire={true} isCompte={false} /> ],
    header: <CompanySelector label="Prestataire" labelCode="Code OF" />,
    menuDisabled: (appState: AppState) => appState.entreprise == null,
    modules: [
        [
            accueil(
                AccesRapidesPrestataire,
                [ <FinancialSummary key="fs" /> ],
            ),
            gestionCompteEntreprise(),
        ], [
            dossiers("Dossiers", dossiersContextPrestataire, MesDossiersPrestataire, DetailDossierPrestataire),
            factures(MesFacturesPrestataire, 'Factures Déposées'),
            formations(),
            reglements(true),
            documents(),
            refsBancaires('refBancairesOF', 'Ajouter un RIB\xa0prestataire'), 
            cleCfa(),
            aide("Aide & Contacts", ["facture", "assistance"]),
        ]
    ],
    options: {
        typeOrigine: TYPE_ORIGIN_PRESTATAIRE,
        getEtablissements: getEtablissementsPrestataire
    },
    env: {
        selfeUrl: import.meta.env.REACT_APP_SELFE_PROFORMA,
        matomoIDSite: import.meta.env.REACT_APP_MATOMO_ID_SITE_PRESTATAIRE,
    },
}

export default AppPrestataire
