import {
  AtlasButton,
  AtlasIcon,
  AtlasIconButton,
  AtlasLoading,
  AtlasSelect,
  AtlasSelectProps,
} from "atlas-ds";
import classNames from "classnames";
import { MouseEventHandler, useEffect } from "react";
import InfiniteScroll from "react-infinite-scroll-component";

export interface MyNotificationsProps {
  /**
   * Les notifications
   */
  notifications: React.ReactElement<MyNotificationsItemProps>[];
  /**
   * L'action de fermeture
   */
  onClose: MouseEventHandler<HTMLButtonElement>;
  /**
   * L'action de suppression de toutes les notifications
   */
  onDeleteAll: MouseEventHandler<HTMLButtonElement>;
  /**
   * L'action à éxécuter quand la fin des notifications présentées est atteinte
   */
  onScrollEnd: () => void;
  /**
   * Le nombre total de notifications existantes
   */
  totalCount: number;
  /**
   * Le nombre de notifications chargées
   */
  loadedCount: number;
  /**
   * Une liste de sélection permettant de filtrer les notifications
   */
  select?: React.ReactElement<AtlasSelectProps>;
}

/**
 * Une liste de notifications
 */
export function MyNotifications(props: MyNotificationsProps) {
  useEffect(() => {
    document.body.style.overflow = "hidden";

    return () => {
      document.body.style.overflow = "initial";
    };
  }, []);

  return (
    <aside
      id="my-notifications"
      className="my-notifications"
      aria-label="Notifications"
    >
      <header className="my-notifications__header">
        <div className="my-notifications__title">Notifications</div>
        <AtlasIconButton
          ariaLabel="Fermer les notifications"
          onClick={props.onClose}
        >
          <AtlasIcon name="close" />
        </AtlasIconButton>
        <div className="my-notifications__actions">
          {props.select && <AtlasSelect {...props.select.props} mini />}

          <div className="my-notifications__deleteAll">
            <AtlasButton
              level={3}
              onClick={props.onDeleteAll}
              disabled={props.loadedCount > 0 ? false : true}
              icon="trash"
            >
              Tout effacer
            </AtlasButton>
          </div>
        </div>
      </header>
      <div className="my-notifications__inner">
        <InfiniteScroll
          dataLength={props.loadedCount}
          next={props.onScrollEnd}
          hasMore={props.loadedCount < props.totalCount}
          scrollableTarget="my-notifications"
          loader={<></>}
        >
          <div className="my-notifications__list">{props.notifications}</div>
        </InfiniteScroll>

        <AtlasLoading.Loaders>
          <div className="my-notifications__list">
            <AtlasLoading.Loader />
            <AtlasLoading.Loader />
            <AtlasLoading.Loader />
          </div>
        </AtlasLoading.Loaders>
      </div>
    </aside>
  );
}

export interface MyNotificationsItemProps {
  /**
   * Le contenu de la notification
   */
  children: React.ReactNode;
  /**
   * La date d'émission de la notification
   */
  date: string;
  /**
   * L'action à éxécuter lorsque l'utilisateur marque la notification comme lue
   */
  onMarkAsRead: React.MouseEventHandler<HTMLButtonElement>;
  /**
   * Signaler la notification comme étant une erreur
   */
  error?: boolean;
  /**
   * Si la notification est liée à un autre service, le logo de ce service
   */
  linkedServiceLogo?: React.ReactNode;
}

MyNotifications.Item = (props: MyNotificationsItemProps) => {
  return (
    <article
      className={classNames("my-notifications__item", {
        "my-notifications__item--error": props.error,
      })}
    >
      <AtlasIconButton
        circled
        ariaLabel="Marquer comme lu"
        onClick={props.onMarkAsRead}
      >
        <AtlasIcon name="close" />
      </AtlasIconButton>

      {props.linkedServiceLogo && (
        <div className="my-notifications__itemLinkedServiceLogo">
          {props.linkedServiceLogo}
        </div>
      )}

      <div className="my-notifications__itemInner">
        {props.error && (
          <AtlasIcon
            name="error"
            size="xs"
            title="Cet élément requiert votre attention"
          />
        )}

        <div>{props.children}</div>

        <div className="my-notifications__itemDate">{props.date}</div>
      </div>
    </article>
  );
};
