import TableauViz from 'app/tableau/TableauViz'
import { AtlasButton, AtlasInfo, MyCategories, MyCategoriesCategory } from 'atlas-ds'
import { ToastService } from 'components/BandeauAlert'
import PageHeader from 'components/PageHeader'
import { useThirdPartyCookieCheck } from 'components/useCheckThirdPartyCookies'
import log from 'loglevel'
import { useState } from 'react'

interface MesRapportsCategory extends MyCategoriesCategory {
    src: string
    name: string
    export: 'pdf' | 'xlsx'
}

/*
"Suivi Alternance"
"KPI_TotalDoss_alt"
"Suivi Formation"
"Suivi Reg Eng"
"Détails Apprentis"
"Détails Pro"
"Détails Pro-A"
"AF"
"Suivi Factures"
"Détails Factures"
"Relevé Compte"
"Engagement"
"Suivi"
"FAQ"
*/

// https://eu-west-1a.online.tableau.com/#/site/myatlasdashboards/views/classeurSuiviAlternance/dashSuiviAlternance?:iid=1
export default function MesRapports() {

    const [categories] = useState<MesRapportsCategory[]>([
        {label: "Suivi de l'alternance", src: 'https://eu-west-1a.online.tableau.com/#/site/myatlasdashboards/views/Classeur_Suivi_Services_En_Ligne_V2/SuiviAlternance', name: 'Suivi Alternance', export: 'pdf', active: true},
        {label: "Suivi des actions de formation", src: 'https://eu-west-1a.online.tableau.com/#/site/myatlasdashboards/views/Classeur_Suivi_Services_En_Ligne_V2/SuiviFormation', name: 'Suivi Formation', export: 'pdf'},
        /* {label: "Suivi des règlements & engagements"},
        {label: "Suivi des Versements Volontaires"},
        {label: "Détails des contrats d'apprentissage"},
        {label: "Détails des contrats de professionnalisation"},
        {label: "Détails des contrats PRO-A"}, */
    ])
    const [tpcCookiesSupported, setTpcCookiesSupported] = useState<boolean | undefined>(undefined)
    const [exporting, setExporting] = useState(false)

    useThirdPartyCookieCheck(supported => setTpcCookiesSupported(supported))
    
    const [currentCategory, setCurrentCategory] = useState<MesRapportsCategory>(categories[0])

    const onSelect = (category?: MyCategoriesCategory) => {
        currentCategory.active = false
        if (category) category.active = true
        setCurrentCategory(category as MesRapportsCategory)
    }

    const exportData = async () => {
        const tableauViz = document.getElementById('tableauViz') as any
        const activeSheet = tableauViz.workbook.activeSheet
        if (currentCategory.export === 'pdf') {
            const publishedSheetsInfo = tableauViz.workbook.publishedSheetsInfo
            const selectedWorkbookSheetsForExport = publishedSheetsInfo
                .map((publishedSheetInfo: any) => publishedSheetInfo.name)
                .filter((name: string) => name === currentCategory.name)
            const exportPDFOptions = {
              scaling: 'automatic',
              pageSize: 'A4',
              orientation: 'landscape',
            }
            setExporting(true)
            tableauViz.exportPDFAsync(selectedWorkbookSheetsForExport, exportPDFOptions)
                .then(
                    () => {
                        log.info(`Workbook: ${selectedWorkbookSheetsForExport.toString()}`)
                        log.info('Options:', exportPDFOptions)
                        setExporting(false)
                    }, 
                    () => setExporting(false),
                )  
        } else {
            const worksheets = activeSheet.worksheets
            const selectedWorksheetForExport = worksheets[0]
            setExporting(true)
            tableauViz.exportDataAsync(selectedWorksheetForExport.name)
                .then(
                    () => {
                        log.info(`${selectedWorksheetForExport.name}: xlsx`)
                        ToastService.showToast({
                            label: "Export",
                            type: "success",
                            content: "Export réussi",
                        })
                        setExporting(false)
                    },
                    () => setExporting(false),
                )
        }
    }

    return (<>
        { tpcCookiesSupported === false && <>
            <PageHeader>Tableaux de bord</PageHeader>
            
            <AtlasInfo type="warning" title="Cookies désactivés">
                Les cookies tiers sont désactivés. Veuillez les activer pour pouvoir afficher les rapports.
            </AtlasInfo>
        </> }
        
        { tpcCookiesSupported === true && <>
            <MyCategories categories={categories} onSelect={onSelect} />

            <PageHeader 
                actions={
                    <AtlasButton level={2} onClick={exportData} spinner={{ spinning: exporting }}>
                        Exporter les données
                    </AtlasButton>
                }
                helmet={ [
                    <script key="tableau" type="module" src="https://online.tableau.com/javascripts/api/tableau.embedding.3.latest.min.js"></script>
                ] } 
            >{ currentCategory.label }</PageHeader>

            <TableauViz src={ currentCategory.src }/>
        </> }
    </>)
}

// https://eu-west-1a.online.tableau.com/#/site/myatlasdashboards/views/classeurSuiviAlternance/dashSuiviAlternance?:iid=1