
import { getEtablissements } from 'api/commonAPI'
import { TYPE_ORIGIN_ENTREPRISE } from 'api/references'
import { AppInfo, AppState } from 'app/appInfo'
import { AtlasLogo } from 'atlas-ds'
import CompanySelector from 'components/CompanySelector'
import { NavNofications } from 'components/Nav'
import accueil from 'pages/accueil'
import aide from 'pages/aide-contacts'
import alternance from 'pages/alternance'
import documents from 'pages/mes-documents'
import dossiers from 'pages/mes-dossiers-contrats'
import factures from 'pages/mes-factures'
import rapports from 'pages/mes-rapports'
import refsBancaires from 'pages/mes-refs-bancaires'
import reglements from 'pages/mes-reglements'
import simulations from 'pages/mes-simulations'
import versements from 'pages/mes-versements'
import diagAtlas from 'pages/mon-diagnostic'
import strategiePlus from 'pages/strategie-plus'
import { AppType } from 'slices/contextSlice'
import AccesRapidesEntreprise from './Accueil/AccesRapidesEntreprise'
import DetailDossierEntreprise from './mes-dossiers/DetailDossierEntreprise'
import dossiersContextEntreprise from './mes-dossiers/dossiersContextEntreprise'
import MesDossiersEntreprise from './mes-dossiers/MesDossiersEntreprise'
import MesFacturesEntreprise from './mes-factures/MesFacturesEntreprise'
import climateSchoolAtlas from 'pages/mon-climate-school'


const AppEntreprise: AppInfo = {
    id: 'MAE',
    app: AppType.Entreprise,
    name: 'Entreprise',
    logo: <AtlasLogo logo="entreprise" />,
    theme: 'entreprise',
    navLinks: [ <NavNofications isPrestataire={false} isCompte={false} /> ],
    header: <CompanySelector label="Entreprise" labelCode="Code Adhérent" />,
    menuDisabled: (appState: AppState) => appState.entreprise == null,
    modules: [
        [
            accueil(AccesRapidesEntreprise),
        ], [
            alternance,
            strategiePlus,
        ], [
            dossiers("Dossiers & Contrats", dossiersContextEntreprise, MesDossiersEntreprise, DetailDossierEntreprise),
            simulations(),
            diagAtlas(),
            climateSchoolAtlas(),
            factures(MesFacturesEntreprise),
            reglements(false), 
            documents(),
            rapports(),
            refsBancaires('refBancaires', 'Ajouter un RIB\xa0adhérent'),
            versements(),
            aide("Aide & Contacts", ["facture", "conseil", "assistance", "cotisation"]),
        ],
    ],
    options: {
        typeOrigine: TYPE_ORIGIN_ENTREPRISE,
        getEtablissements: getEtablissements,
    },
    env: {
        selfeUrl: import.meta.env.REACT_APP_SELFE,
        matomoIDSite: import.meta.env.REACT_APP_MATOMO_ID_SITE_ENTREPRISE,
    },
}

export default AppEntreprise