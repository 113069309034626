import { Notification as NotificationModel } from "api/notificationAPI"
import { DISPOSITIF_ACN, TYPE_ENTREPRISE } from "api/references"
import { AtlasLogo, MyNotifications } from "atlas-ds"
import State from "components/State"
import { intervalToDuration } from "date-fns"
import { Link as ReactLink } from "react-router-dom"
import Link from "./Link"

interface NotificationContent {
    message: JSX.Element | string | null
    logo?: JSX.Element | null
}

const notificationDefaut = () => {
    return {
        message: '',
        logo: null
    }
}

interface NotificationHandlers {
    [index: string]: (notification: NotificationModel, isPrestataire: boolean) => NotificationContent
}

const notificationDiagnostic = (notification: NotificationModel) => {
    const notif = {
        message: null as JSX.Element | null,
        logo: <AtlasLogo logo="diagnostic" />
    }

    switch (notification.TypeEvenement__c) {
        case "FinValidite":
            notif.message = <>
                Certaines de vos actions ne sont plus disponibles. <ReactLink to="/mon-diagnostic-plan">Voir mon plan d'action.</ReactLink>
            </>
            break
        case "FinValiditeAvant60":
            notif.message = <>
                Certaines de vos actions ne seront bientôt plus disponibles. Bénéficiez-en dès à présent ! <ReactLink to="/mon-diagnostic-plan">Voir mon plan d'action.</ReactLink>
            </>
            break
    }

    return notif
}

const notificationDossier = (notification: NotificationModel, isPrestataire: boolean) => {
    const notif = {
        message: null as JSX.Element | null,
        logo: notification.DispositifFO__c === DISPOSITIF_ACN ? <AtlasLogo logo="campus" /> : null
    }

    let link;

    if (!(isPrestataire && ['DossierIncomplet', 'DossierAnnule'].includes(notification.TypeEvenement__c))) {
        link = '/detail-dossier-contrat/' + notification.RefId__c
        if (notification.TypeEvenement__c === 'DossierIncomplet')
            link += '#documents'
    }

    if (import.meta.env.REACT_APP_RELANCE === 'true') {
        const states = new Map<string, JSX.Element>([
            [ 'DossierAccorde', <State small type="file" id="4">accordé</State> ],
            [ 'DossierIncomplet', <State small type="file" id="3">incomplet</State> ],
            [ 'DossierRefuse', <State small type="file" id="5">refusé</State> ],
            [ 'DossierAnnule', <State small type="file" id="8">annulé</State> ],
            [ 'DossierRupture',  <State small type="file" id="7">rupture</State> ],
        ])

        notif.message =
            <p>
                Dossier {
                link ? <ReactLink to={link}> {notification.Name}</ReactLink> : notification.Name } {
                states.get(notification.TypeEvenement__c) } {
                notification.Complement__c }
            </p>
    }
    else {
        switch (notification.TypeEvenement__c) {
            case 'DossierAccorde':
                notif.message = <p>Votre dossier {link ? <ReactLink to={link}> {notification.Name}</ReactLink> : notification.Name} a été <State small type="file" id="4">accordé</State></p>
                break
            case 'DossierIncomplet':
                notif.message = <p>Votre dossier {link ? <ReactLink to={link}> {notification.Name}</ReactLink> : notification.Name} est <State small type="file" id="3">incomplet</State>, merci de prendre connaissance du courrier de relance</p>
                break
            case 'DossierRefuse':
                notif.message = <p>Votre dossier {link ? <ReactLink to={link}> {notification.Name}</ReactLink> : notification.Name} a été <State small type="file" id="5">refusé</State></p>
                break
            case 'DossierAnnule':
                notif.message = <p>Votre dossier {link ? <ReactLink to={link}> {notification.Name}</ReactLink> : notification.Name} a été <State small type="file" id="8">annulé</State></p>
                break
            case 'DossierRupture':
                notif.message = <p>Votre dossier {link ? <ReactLink to={link}> {notification.Name}</ReactLink> : notification.Name} est en <State small type="file" id="7">rupture</State></p>
                break
        }
    }

    return notif
}

const notificationFacture = (notification: NotificationModel) => {
    const notif = {
        message: null as JSX.Element | null,
    }

    let link = '/detail-facture/' + notification.RefId__c
    if (notification.TypeEvenement__c === 'FactureIncomplete')
        link += '#documents'

    if (import.meta.env.REACT_APP_RELANCE === 'true') {
        const states = new Map<string, JSX.Element>([
            [ 'FacturePayee', <State small type="bill" id="3">payée</State> ],
            [ 'FactureRefusee', <State small type="bill" id="4">refusée</State> ],
            [ 'FactureIncomplete', <State small type="bill" id="2">incomplète</State> ],
        ])
    
        notif.message =
            <p>
                Facture {
                link ? <ReactLink to={link}> {notification.Name}</ReactLink> : notification.Name } {
                states.get(notification.TypeEvenement__c) } {
                notification.Complement__c }
            </p>
    }
    else {
        switch (notification.TypeEvenement__c) {
            case 'FacturePayee':
                notif.message = <p>Votre facture {link ? <ReactLink to={link}> {notification.Name}</ReactLink> : notification.Name} a été <State small type="bill" id="3">payée</State></p>
                break
            case 'FactureRefusee':
                notif.message = <p>Votre facture {link ? <ReactLink to={link}> {notification.Name}</ReactLink> : notification.Name} a été <State small type="bill" id="4">refusée</State></p>
                break
            case 'FactureIncomplete':
                notif.message = <p>Votre facture {link ? <ReactLink to={link}> {notification.Name}</ReactLink> : notification.Name} est <State small type="bill" id="2">incomplète</State>, merci de prendre connaissance du courrier de relance</p>
                break
        }
    }

    return notif
}

const notificationReglement = (notification: NotificationModel) => {
    const notif = {
        message: null as JSX.Element | null,
    }

    switch (notification.TypeEvenement__c) {
        case 'ReglementEmis':
            notif.message = <p>Un règlement a été émis</p>
            break
    }

    return notif
}

const notificationCompte = (notification: NotificationModel) => {
    const notif = {
        message: null as JSX.Element | null,
    }

    switch (notification.TypeEvenement__c) {
        case 'Attachement':
            const page = notification.Entreprise?.TypeEnregistrement === TYPE_ENTREPRISE ? "mes-entreprises" : "mes-prestataires"
            const link = `/${page}/detail/${notification.EntrepriseId__c}?highlight-account=${notification.OrigineAction?.Id}`
            notif.message = <>
                <p>Un nouvel utilisateur a attaché <strong>{notification.Name}</strong> à son compte myAtlas.</p>
                <Link icon="search" to={link} level={4}>Vérifier l'utilisateur</Link>
            </>
            break
        case 'Detachement':
            notif.message = <>
                <p>Votre compte a été détaché de <strong>{notification.Name}</strong> par un autre utilisateur.</p>
                <p>Si ce détachement est une erreur, veuillez contacter <a href="https://www.opco-atlas.fr/contact/formulaire-webassistance.html" target="_blank">l'assistance technique</a>.</p>
            </>
            break
    }

    return notif
}

const NOTIFICATIONS: NotificationHandlers = {
    'Diagnostic': notificationDiagnostic,
    'Dossier': notificationDossier,
    'Facture': notificationFacture,
    'Reglement': notificationReglement,
    'HistoryAttachment': notificationCompte,
}

interface DatedNotification extends NotificationModel {
    date: Date
}

interface NotificationProps {
    notification: DatedNotification
    update: ((id: string, lu: boolean) => void)
    isPrestataire: boolean
}

const getDaysSinceDate = (date: Date): string => {
    const { years, months, days} =  intervalToDuration({
        start: date,
        end: new Date()
    });

    if (years) {
        return `${years} an${years > 1 ? 's' : ''}`
    }

    if (months) {
        return `${months} mois`
    }

    return days == null || days === 0 ? "Auj." : days === 1 ? "1 jour" : `${days} jours`
}

export default function Notification(props: NotificationProps) {
    const notifHandler = NOTIFICATIONS[props.notification.TypeReference__c] ?? notificationDefaut
    const notif = notifHandler(props.notification, props.isPrestataire)

    return <MyNotifications.Item
        error={ import.meta.env.REACT_APP_RELANCE === 'true' && props.notification.Alerte__c === true }
        onMarkAsRead={() => props.update(props.notification.Id, true)}
        linkedServiceLogo={notif.logo}
        date={getDaysSinceDate(props.notification.date)}
    >{notif.message}</MyNotifications.Item>
}
