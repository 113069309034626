import { Form, UseFormReturn, useForm } from "@dsid-opcoatlas/reform"
import { Yop } from "@dsid-opcoatlas/yop"
import { Etablissement, getEntrepriseMamc } from "api/commonAPI"
import { postAttacherEntreprise } from "api/monCompteAPI"
import { TYPE_ENTREPRISE } from "api/references"
import { RootState } from "app/rootReducer"
import { useAppDispatch } from "app/store"
import { AtlasColumns, AtlasInfo, AtlasInfoProps, AtlasSpacer, MyForm } from "atlas-ds"
import Link from "components/Link"
import PageHeader from "components/PageHeader"
import Button from "components/reform/Button"
import NumberField from "components/reform/inputs/NumberField"
import TextField from "components/reform/inputs/TextField"
import { Ignored, RequiredNumber } from "components/yop/constraints"
import { useState } from "react"
import { useSelector } from "react-redux"
import { Link as ReactLink, useHistory, useLocation } from "react-router-dom"
import { nil } from "slices/dossierCommon"
import { profilActions } from "slices/profilSlice"
import AttacherEtablissementField from "../components/AttacherEtablissementField"

function createAttachementEntreprise() {
    return {
        etablissement: nil as Etablissement,
        codeAdh: nil as number,
        masseSalariale: nil as number,
    };
}

type AttachementEntreprise = ReturnType<typeof createAttachementEntreprise>

export function AttacherUneEntreprise() {
    
    const { profil } = useSelector((state: RootState) => state.profilState)
    const { fulfilled: config } = useSelector((state: RootState) => state.configState)

    const dispatch = useAppDispatch()
    const history = useHistory()
    const location = useLocation()

    const [info, setInfo] = useState<AtlasInfoProps | null>(null)
    const [backendError, setBackendError] = useState<string | null>(null)

    const initialValues = createAttachementEntreprise()
    const validationSchema = Yop.object<AttachementEntreprise>({
        etablissement: Ignored,
        codeAdh: RequiredNumber.max(999_999, "Le code adhérent doit être composé de 1 à 6 chiffres"),
        masseSalariale: RequiredNumber.min(1),
    })

    const form = useForm<AttachementEntreprise>({
        initialValues,
        validationSchema,
        dispatchEvent: false,
        onSubmit: (context: UseFormReturn<AttachementEntreprise>) => {
            const entreprise = context.values!
            postAttacherEntreprise(JSON.stringify({
                type: TYPE_ENTREPRISE,
                siren: entreprise.etablissement.SIREN__c,
                codeAdh: entreprise.codeAdh,
                masseSalariale: entreprise.masseSalariale,
                montantTTC: null,
            }))
            .then((_) => {
                const params = new URLSearchParams(location.search)
                params.append("nouvelleEntrepriseSiren", entreprise.etablissement.SIREN__c)
                dispatch(profilActions.getServices()).then(() => {
                    history.replace({ pathname: "/mes-entreprises", search: params.toString() })
                })
            })
            .catch((error) => {
                const message = error?.response?.data?.message ?? "Une erreur inattendue s'est produite."
                setBackendError(message)
                context.setSubmitting(false)
            })
        },
    })

    const searchEtablissement = async (recherche: string) => {
        const etablissements = await getEntrepriseMamc(recherche)
        setInfo(
            etablissements.length === 0 ?
            {
                type: "warning",
                title: "Entreprise non référencée",
                children: `L'entreprise possédant le SIREN ${recherche} n'est pas référencée chez Atlas.`,
                link: <ReactLink to={`/mes-entreprises/web-adhesion/${recherche}`}>Faire adhérer l'entreprise à Atlas</ReactLink>,
            } :
            null
        )
        return etablissements
    }

    const onSelect = (etablissement: Etablissement) => {
        setInfo(
            profil?.Entreprises.find((entreprise) => entreprise.SIREN__c === etablissement.SIREN__c ) ?
            {
                title: `L'entreprise "${etablissement.Name}" (SIREN : ${etablissement.SIREN__c}) est déjà attachée à votre compte.`,
                children: "Vous pouvez la retrouver dans votre liste d'entreprises.",
                link: (
                    <ReactLink to="/mes-entreprises">Retour à mes entreprises</ReactLink>
                ),
            } :

            etablissement.TypeEnregistrement === "Prospect" ?
            {
                title: `Votre entreprise "${etablissement.Name}" (SIREN : ${etablissement.SIREN__c}) est en cours d'adhésion`,
                children: "Vous recevrez prochainement un email avec votre code adhérent qui vous permettra d'attacher cette entreprise à votre compte.",
            } :

            etablissement.Actif === null || etablissement.Actif === false ?
            {
                type: "error",
                title: `L'entreprise "${etablissement.Name}" (SIREN : ${etablissement.SIREN__c}) inactive`,
                children: "L'entreprise sélectionnée n'est pas active dans le référentiel, merci de contacter le support.",
            } :

            (etablissement.SituationJuridique__c === "18" || etablissement.SituationJuridique__c === null) && etablissement.TypeEnregistrement !== "Prospect" ?
            {
                type: "error",
                title: `L'entreprise "${etablissement.Name}" (SIREN : ${etablissement.SIREN__c}) à un statut juridique invalide`,
                children: `La situation juridique (${etablissement.SituationJuridique__c === "18" ? "Hors champ" : "Non définie"}) de l'entreprise sélectionnée ne permet pas d'effectuer un attachement à votre compte, merci de contacter le support.`,
            } :

            null
        )
    }

    return (
        <>
            <PageHeader>Attacher une entreprise</PageHeader>

            <AtlasColumns>
                <div>
                    <AtlasInfo title="Attachement d'une entreprise par SIREN">
                        L'attachement par SIREN vous permet d'avoir accès à tous les établissements de l'entreprise
                        lors de vos demandes de prise en charge et dossiers sur myAtlas Entreprise.
                    </AtlasInfo>

                    <AtlasSpacer size="xl" />

                    <Form context={form} autoComplete="off" noValidate>
                        <MyForm>
                            <AttacherEtablissementField
                                name="etablissement"
                                label="Rechercher une entreprise par SIREN"
                                search={ searchEtablissement }
                                onChange={ _ => setInfo(null) }
                                onSelect={ onSelect }
                            />

                            {
                            info != null ?
                            <AtlasInfo { ...info } /> :
                            
                            form.values?.etablissement?.SIREN__c != null ?
                            <MyForm.Grid>
                                <TextField disabled name="etablissement.Name" label="Raison sociale" />

                                <TextField disabled name="etablissement.SIREN__c" label="Siren" />

                                <NumberField name="codeAdh" label="Code adhérent" formatDisplayedValue={ false } />

                                <NumberField
                                    name="masseSalariale"
                                    label={`Masse salariale ${ config?.Mamc.AnneeMasseSalariale }`}
                                    suffix="€"
                                    tooltip={<>
                                        (Salaires bruts soumis à cotisations) = base N4DS code S80.G62.00.007, c’est aussi le montant que vous déclarez sur le bordereau de collecte. Si vous n’avez pas le montant de votre Masse Salariale, rapprochez-vous de votre expert-comptable pour l’obtenir ou le service habilité dans votre organisation. Pour des raisons de sécurité informatique et de confidentialité, Atlas ne vous communiquera pas cette donnée.<br/><br/>
                                        Pas de masse salariale pour l'année demandée ? <a href="https://www.opco-atlas.fr/contact/formulaire-webassistance.html" target="_blank" rel="noopener noreferrer">Contactez-nous</a>.
                                    </>}
                                />
                            </MyForm.Grid> :
                            
                            null
                            }

                            { backendError &&
                                <AtlasInfo type="error" title="Erreur">{ backendError }</AtlasInfo>
                            }

                            <MyForm.Actions>
                                <Link to="/mes-entreprises" level={ 2 } disabled={ form.submitting }>Précédent</Link>
                                <Button
                                    submit
                                    spinner={{ spinning: form.submitting }}
                                    disabled={ info != null || form.submitting || form.values?.etablissement?.SIREN__c == null }
                                >Valider</Button>
                            </MyForm.Actions>
                        </MyForm>
                    </Form>
                </div>
            </AtlasColumns>
        </>
    )
}
