import { RootState } from 'app/rootReducer'
import { useAppDispatch } from 'app/store'
import { AtlasNav } from 'atlas-ds'
import Notifications from 'components/Notifications'
import { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { notificationsActions } from 'slices/notificationsSlice'
import UserButton from './AtlasCta'

export interface NavProps {
    isPrestataire?: boolean
    isCompte?: boolean
    toggleMenuOpen?: (() => void)
    links?: JSX.Element[]
}

export default function Nav(props: NavProps) {
    return <>
        <AtlasNav
            userButton={<UserButton />}
            onMenuOpen={ props.toggleMenuOpen }
            additionalItems={ props.links }
        />
    </>
}

export function NavNofications({ isPrestataire, isCompte }: { isPrestataire: boolean, isCompte: boolean }) {
    if (import.meta.env.REACT_APP_SHOW_NOTIFICATIONS === 'false') return undefined

    const [notificationsOpen, setNotificationsOpen] = useState(false)

    const dispatch = useAppDispatch()
    const { entreprise } = useSelector((state: RootState) => state.contextState)

    const { notifications } = useSelector((state: RootState) => state.notificationsState)
    useEffect(() => {
        if (!notifications || entreprise?.SIREN__c !== notifications.siren) {
            dispatch(notificationsActions.load({ lu: false }))
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [entreprise])

    const notificationsCount = notifications?.totalCount ?? 0

    const toggleNotificationsOpen = () => {
        setNotificationsOpen(!notificationsOpen)
    }

    return (
        <>
            <AtlasNav.Notifications count={ notificationsCount } onClick={toggleNotificationsOpen} />

            { notificationsOpen && <Notifications isCompte={isCompte} isPrestataire={isPrestataire} toggleNotificationsOpen={toggleNotificationsOpen} /> }
        </>
    )
}
