import { Answer, ContenuAnswers, getContenuArticle } from "api/aideContactsAPI";
import { AtlasLoading, MyQuestions, MyQuestionsQuestion } from "atlas-ds";
import log from "loglevel";
import { useEffect, useState } from "react";

interface QuestionsProps {
    loading: boolean;
    answers: Answer[] | null
}

export function Questions(props: QuestionsProps) {
    const [questions, setQuestions] = useState<MyQuestionsQuestion[]>([])

    useEffect(() => {
        setQuestions(props.answers?.map(answer => ({
            id: answer.urlName,
            label: answer.title,
            //answer: answer.contenuPublic ?? answer.summary,
            answer: '',
        })) ?? [])
    }, [props.answers]);

    const onClick = (question: MyQuestionsQuestion) => {
        getContenuArticle(question.id ?? "").then((data: ContenuAnswers) => {
            const contenuPublic = data.layoutItems.find(
                (contenu) => contenu.name === 'ContenuPublic__c'
            )

            const contenu = contenuPublic?.value
                ? contenuPublic
                : data.layoutItems.find((contenu) => contenu.name === 'Contenu__c')

            setQuestions(questions.map(questionItem => questionItem.id === question.id
                ? {...questionItem, answer: contenu ? contenu.value : "Erreur : le contenu n'est pas disponible" }
                : questionItem
            ))
        }).catch(e => {
            log.error('Erreur getContenuArticle ' + question?.id, e)
        })
    }

    return <AtlasLoading loading={props.loading}>
        { (questions.length || props.loading) ? <MyQuestions
            questions={questions}
            onClick={onClick}
        /> : <p>Aucun résultat ne correspond à votre recherche.</p> }
    </AtlasLoading>
}
