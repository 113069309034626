import { postRelanceRuptureDossier } from 'api/dossierAPI'
import { References } from 'api/referencesAPI'
import { date2APIDate } from 'components/format/Format'
import { Ignored } from 'components/yop/constraints'
import { RuptureFormMetadata, RuptureInfo } from 'pages/dossier/metadata/RuptureFormMetadata'
import { DateFieldMetadata } from 'components/metadata/DateFieldMetadata'
import { SelectRefFieldMetadata } from 'components/metadata/RefFieldMetadata'

export const CPRORuptureFormMetadata = {
    title: "",
    nomDispositif: "contrat de professionnalisation",
    showInfoStep: '',
    postRupture: (numeroDossier: string, refs: References, ruptureInfo: RuptureInfo, blob: Blob) => {
        return postRelanceRuptureDossier({
            numeroDossier, 
            motif: ruptureInfo.motif ?? '', 
            dateRupture: date2APIDate(ruptureInfo.dateRupture), 
            nom: 'DEMANDE_RUPTURE', 
            file: blob, 
            fileName: 'demande-rupture-' + numeroDossier + '-MAE.pdf',
        })
    },
    fields: {
        dateDebutContrat: Ignored,
        dateFinContrat: Ignored,
        motif: SelectRefFieldMetadata({
            props: {
                label: "Merci de préciser le motif",
                exportLabel: 'Motif de la rupture',
                options: refs => refs.REF_MOTIF_ADMINISTRATIF.filter(ref => ref.Categorie__c === '56' && ref.IsActiveFO__c),
            },
        }).required(),
        dateRupture: DateFieldMetadata({
            props: {
                label: "Date de la rupture",
            },
        }).required().mutate(yop => yop
            .min(ctx => ctx.parent!.dateDebutContrat!, 'La date de rupture est antérieure à la date du début du contrat')
            .max(ctx => ctx.parent!.dateFinContrat!, 'la date de rupture est postérieure à la date de fin de contrat'),
        ),
    },
} as RuptureFormMetadata

export const CPROMAPRuptureFormMetadata = {
    title: "",
    nomDispositif: "contrat de professionnalisation",
    showInfoStep: '',
    postRupture: (numeroDossier: string, refs: References, ruptureInfo: RuptureInfo, blob: Blob) => {
        return postRelanceRuptureDossier({
            numeroDossier, 
            motif: ruptureInfo.motif ?? '', 
            dateRupture: date2APIDate(ruptureInfo.dateRupture), 
            nom: 'DEMANDE_RUPTURE', 
            file: blob, 
            fileName: 'demande-rupture-' + numeroDossier + '-MAP.pdf',
        })
    },
    fields: {
        dateDebutContrat: Ignored,
        dateFinContrat: Ignored,
        motif: SelectRefFieldMetadata({
            props: {
                label: "Merci de préciser le motif",
                exportLabel: 'Motif de la rupture',
                options: refs => refs.REF_MOTIF_ADMINISTRATIF.filter(ref => ref.Categorie__c === '56' && ref.IsActiveFO__c),
            },
        }).required(),
        dateRupture: DateFieldMetadata({
            props: {
                label: "Date de la rupture",
            },
        }).required().mutate(yop => yop
            .min(ctx => ctx.parent!.dateDebutContrat!, 'La date de rupture est antérieure à la date du début du contrat')
            .max(ctx => ctx.parent!.dateFinContrat!, 'la date de rupture est postérieure à la date de fin de contrat'),
        ),
    },
} as RuptureFormMetadata