import { useOidc } from "@axa-fr/react-oidc"
import { Form, UseFormReturn, useForm } from "@dsid-opcoatlas/reform"
import { Yop } from "@dsid-opcoatlas/yop"
import * as Sentry from '@sentry/react'
import { delSupprimerCompte } from "api/monCompteAPI"
import { AtlasInfo, AtlasSpacer, MyForm, MyModal } from "atlas-ds"
import Button from "components/reform/Button"
import PasswordField from "components/reform/inputs/PasswordField"
import TextField from "components/reform/inputs/TextField"
import { RequiredEmail, RequiredString } from "components/yop/constraints"
import { useState } from "react"
import { useHistory } from "react-router-dom"
import { removeConfigFromStorage } from "slices/configSlice"
import { nil } from "slices/dossierCommon"
import { removeReferencesFromStorage } from "slices/referencesSlice"

interface SupprimerMonCompteProps {
    onClose: () => void
    profil: any
}

export default function SupprimerMonCompteModal(props: SupprimerMonCompteProps) {
    const history = useHistory();
    const { logout } = useOidc()

    const initialValues = {
        email: nil as string,
        password: nil as string,
    }

    type SuppressionMonCompte = {
        email: string,
        password: string
    }

    const validationSchema = Yop.object<SuppressionMonCompte>({
        email: RequiredEmail,
        password: RequiredString
    })

    const [backendError, setBackendError] = useState<string | null>(null)

    const supprimerCompteForm = useForm({
        initialValues,
        validationSchema,
        dispatchEvent: false,
        onSubmit(context: UseFormReturn<SuppressionMonCompte>) {
            delSupprimerCompte(context.values!.email, context.values!.password)
                .then(response => {
                    if(response.success) {
                        removeConfigFromStorage()
                        removeReferencesFromStorage()
                        logout("/compte-supprime")
                        Sentry.setUser(null)
                    } else {
                        setBackendError(response?.message ?? "Une erreur inattendue s'est produite.")
                    }
                }).catch(error => {
                    setBackendError(error?.response?.data?.message ?? "Une erreur inattendue s'est produite.")

                }).finally(() => {
                    context.setSubmitting(false);
                })
        }
    })

    const name = `${props.profil.Contact.FirstName} ${props.profil.Contact.LastName}`
    return <MyModal label={`Suppression du compte ${name}`}>
        <Form context={supprimerCompteForm} noValidate>
            <AtlasInfo type="error" title="Cette action est irréversible">
                Une fois votre compte supprimé, vous ne pourrez plus accéder aux services en ligne.<br />
                Pour confirmer votre choix, veuillez saisir vos identifiants.
            </AtlasInfo>
            <AtlasSpacer size="l" />
            <MyForm>
                <MyForm.Grid>
                    <TextField type="email" label="Email" name="email" />
                    <PasswordField label="Mot de passe" name="password" autocomplete="current-password" />
                </MyForm.Grid>

                {backendError && <AtlasInfo type="error" title="Erreur de suppression" >{ backendError }</AtlasInfo>}

                <MyForm.Actions>
                    <Button submit={true} spinner={{ spinning: supprimerCompteForm.submitting }} destructive>Confirmer la suppression</Button>
                    <Button onClick={() => props.onClose()}>Retour</Button>
                </MyForm.Actions>
            </MyForm>
        </Form>
    </MyModal>
}