import { DATE_FORMAT, SIREN_ADH } from 'app/constants'
import store from 'app/store'
import axios, { AxiosRequestConfig } from 'axios'
import { format, startOfToday, subYears } from 'date-fns'
import { BaseDossier } from 'slices/dossierCommon'
import { BaseSearchQuery, Page, createFormData, getRequestConfig } from './baseAPI'
import { EtablissementAdherent, EtablissementAdherentDetail } from './commonAPI'
import { PieceDTO as DossierPieceDTO, PieceTypeDTO as DossierPieceTypeDTO, getDocumentAlfresco, getDocumentSalesforce, openDocumentAlfresco, openDocumentSalesforce } from './documentAPI'
import { findCSP, findCategorieAction, findDispositifFO, findDispositifsEligiblesParBranche, findEtatDossierFO, findNatureContrat, findTypeDossier, findTypeFormation, getAllDispositifFO } from './references'
import { Reference } from './referencesAPI'


export { emptyPage } from 'api/baseAPI'

export type { Page } from 'api/baseAPI'

export type { PieceDTO as DossierPieceDTO, PieceTypeDTO as DossierPieceTypeDTO } from 'api/documentAPI'

export interface Identifiable {
    Id: string
}

export interface Dossier extends Identifiable {
    NumeroDossier__c: string
    TypeDossier__c: string
    TypeDossierRef?: Reference
    Dispositif__c: string
    DispositifFO__c: string
    DispositifFORef?: Reference
    EtatDossier__c: string
    EtatDossierFO__c: string
    EtatDossierFORef?: Reference
    SourceFo?: string
    EnCoursTransmissionSOR__c?: boolean
    ErreurTransmissionSOR__c?: string
    Intitule__c: string
    DateCreationFO__c: string
    DateModificationFO__c: string
    DateReception__c: string
    DateDebutContrat__c: string
    DateDebutFormation__c: string
    DateFinContrat__c: string
    DateFinFormation__c: string
    EtablissementAdherentId__c: string
    EntrepriseAdherentId__c: string
    EntrepriseOFId__c: string
    EtablissementAdherent: EtablissementAdherent
    SubrogationPaiement__c: boolean
    RuptureCA__c?: boolean
    DateRuptureCA__c?: string
    MotifRuptureCA__c?: string
    TypeAlerteADH__c?: string
    TypeAlerteOF__c?: string
    DemandesEnCours?: number[]
    etablissementOf?: {
        Name: string
        /*
        "ConvertirProspect__c": false,
        "IdHeroku": 204430,
        "Actif": true,
        "BillingCity": "ISSY-LES-MOULINEAUX  CEDEX 9",
        "BillingCountry": "FRANCE",
        "BillingPostalCode": "92798",
        "BillingStreet": "19 RUE RENE JACQUES",
        "Adresse3__c": "19 RUE RENE JACQUES",
        "CodeEtablissement__c": "7.2960",
        "IdSOR": "2960",
        "Name": "CEGOS",
        "Id": "0019E00001FBNz3QAH",
        "SIREN__c": "552024671",
        "SIRET__c": "55202467100091",
        "ParentId": "0019E00001FBNUoQAP",
        "VV__c": false,
        "Region__c": "11",
        "NumeroDA__c": "11920012692",
        "OFCampus__c": false,
        "TiersFacilitateur__c": true,
        "StatutQualiopi__c": "SUSPENDU",
        "CertificationCA__c": false,
        "TVAEntreprise__c": false,
        "EtatEtablissement__c": "0",
        "NomCommercial__c": "CEGOS",
        "WebAdhesionRefusee__c": false,
        "TypeEnregistrement": "EtablissementOF"
        */
    }
}

export interface Reglement extends Identifiable {
    Facture__c: string
    Name: string
    ModeReglement__c: string
    DateReglement__c: string
    MontantHT__c: string
    MontantTTC__c: string
    ReferencePaiement__c: string
}

export interface Facture extends Identifiable {
    Name: string
    RefFactureClient__c: string
    NumeroDossier__c: string
    DateReception__c: string
    DateEmission__c: string
    EtatFactureFO__c: string
    EtatFactureFORef?: Reference
    EnCoursTransmission__c?: boolean
    EntrepriseAdherente__c?: string
    ErreurTransmission__c?: string
    EtablissementAdherent__c?: string
    ModeArriveeFacture__c: string
    MontantFactureHT__c: string
    MontantTTC__c: string
    MontantPayeTTC__c: string
    Type__c: string
    TypeRef?: Reference
    TypeEmetteur: string
    Emetteur: string
    EmetteurEntreprise__c: string
    Dossier?: Dossier
    Reglements?: Reglement[]
    TypeAlerteADH__c?: string
    TypeAlerteOF__c?: string
}

export interface Stagiaire extends Identifiable {
    Civilite__c: string
    Nom__c: string
    Prenom__c: string
    DateNaissance__c: string
    Dossier__c: string
    Particulier__c: string
}

export interface Formation extends Identifiable {
    Intitule__c: string
    DateDebut__c: string
    DateFin__c: string
    Type__c: string
}

export interface Module extends Identifiable {
    IntituleProgramme__c: string
    Principal__c: boolean
    TypeFormation__c: string
    TypeFormationRef?: Reference
    DateDebutFormation__c: string
    DateFinFormation__c: string
    NombreHeureFormation__c: number
    SubrogationPaiement__c: boolean
    Adresse1OF__c: string
    Adresse2OF__c: string
    Adresse3OF__c: string
    Adresse4OF__c: string
    Certification__c: boolean
    CodeDiplome__c: string
    CodeEtablissementOF__c: string
    CodePostalOF__c: string
    CommuneOF__c: string
    DateCreationFO__c: string
    DateCreation__c: string | null
    DateModificationFO__c: string
    DateModification__c: string | null
    Dossier__c: string
    EtablissementOFId__c: string
    NdaOF__c: string
    NiveauFormationVise__c: string | null
    NomOF__c: string
    SiretOF__c: string
    SupportCours__c: boolean
    UaiOF__c: string
    UaiSiteOF__c: string | null
}

export interface DossierDetail extends Omit<Dossier, "EtablissementAdherent"> {
    DateModificationFO__c: string
    DateDebutFormation__c: string
    DateFinFormation__c: string
    DateConclusionContrat__c: string
    MontantCoutFormationEngage__c: number | null
    MontantCoutFormationPaye__c: number | null
    MontantDemande__c: number
    MontantTotalEngage__c: number
    MontantTotalPaye__c: number
    NombreHeureFormation__c: number
    EtablissementOFId__c: string
    DossierAnneeAccord__c: string,
    EtablissementAdherent: EtablissementAdherentDetail
    Modules: Module[]
    Stagiaires: Stagiaire[]
    pieces: DossierPieceDTO[]
    IndicateurConformite__c: string | null
    TypeModificationContrat__c: string | null
    StatutModificationContrat__c: string | null
    NumModificationContrat__c: string | null
    DateModificationContrat__c: string | null
    cproExperimentalC: boolean | null
    NumeroDepot__c: string | null
    CommentaireFO__c: string | null
    ExisteFacture: boolean | null
    MontantSoldeADH__c: number | null
    MontantSoldeOF__c: number | null
}

export interface StagiaireDetail extends Stagiaire {
    Email__c: string
    CSP__c: string
    CSPRef?: Reference
    NatureContrat__c: string
    NatureContratRef?: Reference
    CategorieAction__c: string
    CategorieActionRef?: Reference
    Handicap__c: string
    Dossiers: Dossier[]
    FormationsSuggerees?: Formation[]
}

export interface DossiersSearchQuery extends BaseSearchQuery {
    recherche?: string
    dateType?: string
    dateDebut?: string
    dateFin?: string
    dispositifFo?: string[]
    etatDossierFo?: string[]
    utilisateur?: string
    financementVV?: string
    organisme?: string
    subrogation?: string
    alerte?: string
    demande?: string
}

export interface DossierReporterDates {
    DateDebutInitiale: string
    DateDebutSouhaitee: string
    DateFinInitiale: string
    DateFinSouhaitee: string
}

export interface DelaiTraitementDossier {
    DateDerniereModification: string,
    DelaiTraitement: number,
    DispositifFOIds: string[]
}

export interface DossierSendStatus {
    Id?: string | null
    EnCoursTransmission__c: boolean | null
    ErreurTransmission__c: string | null
}




export const DATE_TYPE_CREATION = 'c'
export const DATE_TYPE_TRANSMISSION = 't'
export const DATE_TYPE_DEBUT_FORMATION = 'df'
export const DATE_TYPE_FIN_FORMATION = 'ff'
export const DATE_TYPE_DEBUT_CONTRAT = 'dc'


export async function getDossiers(query: DossiersSearchQuery): Promise<Page<Dossier>> {
    const requestConfig = await getRequestConfig()

    const params = getSearchDossiersParams(query)
    params.append('page', query.page.toFixed(0))
    params.append('par-page', (query.parPage ?? 20).toFixed(0))

    requestConfig.params = params

    const { data, headers } = await axios.get<Dossier[]>('/dossiers/recherche', requestConfig)

    data.forEach(dossier => {
        dossier.TypeDossierRef = findTypeDossier(dossier.TypeDossier__c)
        dossier.DispositifFORef = findDispositifFO(dossier.DispositifFO__c)
        dossier.EtatDossierFORef = findEtatDossierFO(dossier.EtatDossierFO__c)
    })

    const totalCount = parseInt(headers['x-total-count'], 10) || data.length
    const alertCount = parseInt(headers['x-alertes-total-count'], 10) || 0

    return {
        alertCount,
        totalCount,
        items: data
    }
}

export async function exportDossiers(query: DossiersSearchQuery, email: string): Promise<string> {
    const requestConfig = await getRequestConfig()

    const params = getSearchDossiersParams(query)
    params.append('mail', email)

    await axios.post('/dossiers/exporter', params, requestConfig)

    return `Le fichier de données est en cours d'export et vous sera envoyé par email à l'adresse ${email}`
}

function initRequestConfig(requestConfig: AxiosRequestConfig) {
    const params = new URLSearchParams();
    params.append('utilisateur', store.getState().profilState.profil?.Contact.GuidIAM ?? '*');
    params.append(SIREN_ADH, store.getState().contextState.entreprise?.SIREN__c ?? '');
    requestConfig.params = params;
}

export async function getDossier(id: string): Promise<DossierDetail> {
    const requestConfig = await getRequestConfig()
    initRequestConfig(requestConfig)

    const { data } = await axios.get<DossierDetail>('dossiers/' + id, requestConfig);

    data.DispositifFORef = findDispositifFO(data.DispositifFO__c)
    data.EtatDossierFORef = findEtatDossierFO(data.EtatDossierFO__c)

    if (data.Modules) {
        data.Modules.forEach(m => {
            m.TypeFormationRef = findTypeFormation(m.TypeFormation__c)
        })
    }

    return data
}

export async function getDossierPourOF(id: string, sfidOF: string): Promise<DossierDetail> {
    const requestConfig = await getRequestConfig()
    initRequestConfig(requestConfig)

    const { data } = await axios.get<DossierDetail>(`dossiers/${id}?id-of=${sfidOF}`, requestConfig);

    data.DispositifFORef = findDispositifFO(data.DispositifFO__c)
    data.EtatDossierFORef = findEtatDossierFO(data.EtatDossierFO__c)

    if (data.Modules) {
        data.Modules.forEach(m => {
            m.TypeFormationRef = findTypeFormation(m.TypeFormation__c)
        })
    }

    return data
}

export async function getDossierDetailDocuments(id: string): Promise<DossierPieceDTO[]> {
    const requestConfig = await getRequestConfig()
    const { data } = await axios.get<DossierPieceDTO[]>('/dossiers/' + id + '/documents', requestConfig)
    return data
}

export async function getDossierDetailFactures(id: string): Promise<Facture[]> {
    const requestConfig = await getRequestConfig()
    const { data } = await axios.get<Facture[]>('/dossiers/' + id + '/factures?type-emetteur=EntrepriseCliente', requestConfig)
    return data
}

export async function getDossierDetailReglements(id: string): Promise<Facture[]> {
    const requestConfig = await getRequestConfig()
    const { data } = await axios.get<Facture[]>('/dossiers/' + id + '/reglements', requestConfig)
    return data
}

export async function getStagiaire(id: string, idcontact: string): Promise<StagiaireDetail> {
    const requestConfig = await getRequestConfig()
    initRequestConfig(requestConfig)

    const { data } = await axios.get<StagiaireDetail>('stagiaires/' + id + '?contact-id=' + (idcontact ?? ''), requestConfig)

    data.NatureContratRef = findNatureContrat(data.NatureContrat__c)
    data.CSPRef = findCSP(data.CSP__c)
    data.CategorieActionRef = findCategorieAction(data.CategorieAction__c)

    if (data.Dossiers) {
        data.Dossiers.forEach(d => {
            d.DispositifFORef = findDispositifFO(d.DispositifFO__c)
        })
    }

    return data
}

function getSearchDossiersParams(query: DossiersSearchQuery): URLSearchParams {
    const params = new URLSearchParams()
    const entreprise = store.getState().contextState.entreprise

    if (query.recherche)
        params.append('recherche', query.recherche.trim())
    if (query.tri) {
        params.append('tri', query.tri)
        params.append('ordre', query.ordre ?? 'desc')
    }
    if (query.dispositifFo && query.dispositifFo.length > 0) {
        query.dispositifFo.forEach(value => {
            params.append('dispositif-fo', value)
        })
    } else if (!query.dispositifFo || query.dispositifFo.length === 0) {
        const parBranche = findDispositifsEligiblesParBranche(entreprise)
        const allDispositifsFo = getAllDispositifFO()?.filter(d => parBranche.includes(d.Id)) ?? []
        allDispositifsFo?.forEach(value => {
            params.append('dispositif-fo', value.Id)
        })
    }
    if (query.etatDossierFo && query.etatDossierFo.length > 0) {
        query.etatDossierFo.forEach(value => {
            params.append('etat-dossier-fo', value)
        })
    }
    if (query.dateType) {
        switch (query.dateType) {
            case DATE_TYPE_DEBUT_FORMATION: params.append('type-date', 'DateDebutFormation__c'); break
            case DATE_TYPE_FIN_FORMATION: params.append('type-date', 'DateFinFormation__c'); break
            case DATE_TYPE_DEBUT_CONTRAT: params.append('type-date', 'DateDebutContrat__c'); break
            case DATE_TYPE_TRANSMISSION: params.append('type-date', 'DateReception__c'); break
            default: params.append('type-date', 'DateCreationFO__c'); break
        }
    }
    if (query.subrogation === 'oui') {
        params.append('subrogationPaiement', 'true')
    } else if (query.subrogation === 'non') {
        params.append('subrogationPaiement', 'false')
    }
    if (query.organisme) {
        params.append('etablissementOFId', query.organisme)
    }
    const today = startOfToday()
    params.append('date-debut', query.dateDebut ?? format(subYears(today, 5), DATE_FORMAT))
    params.append('date-fin', query.dateFin ?? format(today, DATE_FORMAT))

    params.append('utilisateur', '')
    if (import.meta.env.REACT_APP_RELANCE === 'true') {
        if (query.alerte != null && query.alerte !== '') {
            params.append('alerte', query.alerte === 'oui' ? 'true' : 'false')
        }
        if (query.demande != null && query.demande !== '') {
            params.append('demande-en-cours', query.demande === 'oui' ? 'true' : 'false')    
        }
    }
    else if (query.utilisateur === 'moi')
        params.set('utilisateur', store.getState().profilState.profil?.Contact.GuidIAM ?? '')

    if (query.financementVV && query.financementVV !== 'tous') {
        params.append('vv', query.financementVV === 'oui' ? 'true' : 'false')
    }
    params.append(SIREN_ADH, store.getState().contextState.entreprise?.SIREN__c ?? '')

    return params
}


export async function getDossierDocumentTypes(iddossier: string): Promise<DossierPieceTypeDTO[]> {
    const requestConfig = await getRequestConfig()
    const { data } = await axios.get<DossierPieceTypeDTO[]>('/dossiers/num/' + iddossier + '/document-types', requestConfig)
    return data
}

export async function getDossierDocuments(iddossier: string): Promise<DossierPieceDTO[]> {
    const requestConfig = await getRequestConfig()
    const { data } = await axios.get<DossierPieceDTO[]>('/dossiers/num/' + iddossier + '/documents', requestConfig)
    return data
}

export async function downloadDossierDoc(doc: DossierPieceDTO, dossier: DossierDetail | BaseDossier) {
    if (doc.ContentVersionId) {
        return getDocumentSalesforce(dossier.NumeroDossier__c ?? '', doc.ContentVersionId).then(response => {
            openDocumentSalesforce(doc.fichier, response)
        })
    } else if (doc.Id && doc.Id.startsWith('workspace://')) {
        return getDocumentAlfresco(doc.Id).then(response => {
            openDocumentAlfresco(doc.fichier, response)
        })
    }
    return Promise.resolve()
}

export async function loadDossierPieces(numDossier: string): Promise<DossierPieceDTO[]> {
    const pieceTypes = await getDossierDocumentTypes(numDossier)

    const pieces = await getDossierDocuments(numDossier)
    pieces?.forEach(piece => {
        const pieceType = pieceTypes?.find(pt => pt.nom === piece.type)
        if (pieceType) {
            piece.description = pieceType.description
            piece.obligatoire = pieceType.obligatoire
            piece.actif = true
        } else {
            piece.description = ''
            piece.obligatoire = false
            piece.actif = false
        }
    })
    return pieces
}

export async function loadAndMergeDossierPieces(iddossier: string, dossierPieces: DossierPieceDTO[]): Promise<DossierPieceDTO[]> {
    const pieceTypes = await getDossierDocumentTypes(iddossier)

    pieceTypes.forEach(pieceType => {
        if (!dossierPieces?.find(p => p.type === pieceType.nom)) {
            dossierPieces?.push({
                Id: 'piece-' + pieceType.id,
                type: pieceType.nom,
                description: pieceType.description ?? '',
                obligatoire: pieceType.obligatoire,
                actif: true, dateAjout: '', fichier: ''
            } as DossierPieceDTO)
        }
    })

    const pieces = await getDossierDocuments(iddossier)
    pieces?.forEach(piece => {
        const d = dossierPieces?.find(p => p.type === piece.type)
        if (d && (
            (d.obligatoire && (!d.dateAjout || (piece.dateAjout && piece.dateAjout >= d.dateAjout))) 
            || (!d.obligatoire && !d.dateAjout)
        )) {
            d.Id = piece.Id
            d.dateAjout = piece.dateAjout
            d.fichier = piece.fichier
            d.ContentVersionId = piece.ContentVersionId
            d.uploadedToGed = piece.uploadedToGed
        } else if (!d || !d.obligatoire) {
            let index = dossierPieces?.findIndex(p => p.type === piece.type && (!p.dateAjout || p.dateAjout <= piece.dateAjout))
            if (index == null || index < 0)
                index = dossierPieces?.length
            dossierPieces?.splice(index, 0, {
                Id: piece.Id,
                type: piece.type,
                description: d?.description ?? '',
                obligatoire: false,
                actif: false,
                dateAjout: piece.dateAjout,
                fichier: piece.fichier,
                ContentVersionId: piece.ContentVersionId,
                uploadedToGed: piece.uploadedToGed,
            })
        }
    })
    return dossierPieces
}

export async function getDossierFactures(iddossier: string): Promise<Facture[]> {
    const requestConfig = await getRequestConfig();
    const { data } = await axios.get<Facture[]>(`/dossiers/num/${iddossier}/factures`, requestConfig)
    return data
}

export async function sendDossierDocuments(numeroDossierC: string): Promise<any> {
    const requestConfig = await getRequestConfig();
    const { data } = await axios.post(`/dossiers/num/${numeroDossierC}/documents/valider`, null, requestConfig)
    return data
}

export async function getDossierDocumentsStatus(id: string): Promise<DossierSendStatus> {
    const requestConfig = await getRequestConfig()
    const { data } = await axios.get(`/dossiers/${id}/resume`, requestConfig)
    return {
        Id: data.Id,
        EnCoursTransmission__c: data.EnCoursTransmissionSOR__c,
        ErreurTransmission__c: data.ErreurTransmissionSOR__c,
    }
}

export async function patchAnnulerDossier(numeroDossierC: string, formData: any): Promise<any> {
    const requestConfig = await getRequestConfig();
    requestConfig.headers['Content-type'] = 'application/json'
    const { data } = await axios.patch(`/dossiers/num/${numeroDossierC}/annuler`, formData, requestConfig)
    return data
}

export async function postRelanceCloturerDossier(numeroDossierC: string, formData: any): Promise<any> {
    const requestConfig = await getRequestConfig()
    requestConfig.headers['Content-type'] = 'application/json'
    const { data } = await axios.post(`/dossiers/num/${numeroDossierC}/cloturer`, formData, requestConfig)
    return data
}

export async function postRelanceModifierDateFormationDossier(numeroDossierC: string, formData: any): Promise<any> {
    const requestConfig = await getRequestConfig()
    requestConfig.headers['Content-type'] = 'application/json'
    const { data } = await axios.post(`/dossiers/num/${numeroDossierC}/modifier-date-formation`, formData, requestConfig)
    return data
}

export async function postRelanceAnnulerDossier(numeroDossierC: string, formData: any): Promise<any> {
    const requestConfig = await getRequestConfig()
    requestConfig.headers['Content-type'] = 'application/json'
    const { data } = await axios.post(`/dossiers/num/${numeroDossierC}/demander-annulation`, formData, requestConfig)
    return data
}

export async function postRelanceRuptureDossier(info: RuptureDossierInfo): Promise<any> {
    const requestConfig = await getRequestConfig()
    requestConfig.headers['Content-type'] = 'multipart/form-data'
    
    const formData = new FormData()
    formData.append('nom', info.nom)
    formData.append('motif', info.motif)
    formData.append('date', info.dateRupture)

    const name = (info.fileName ?? (info.file as any).name ?? info.nom).replaceAll(/[\\/|:*?"<>]/g, '_')
    formData.append('document', info.file, name)
    
    if (info.idMotif) formData.append('id-motif', info.idMotif)
    if (info.nomCerfa) formData.append('nom-information', info.nomCerfa)
    if (info.fileCerfa) formData.append('document-information', info.fileCerfa, info.fileNameCerfa)

    const { data } = await axios.post(`/dossiers/num/${ info.numeroDossier }/informer-rupture`, formData, requestConfig)
    return data
}

export async function postRelanceDemanderSubrogationDossier(numeroDossierC: string, formData: any): Promise<any> {
    const requestConfig = await getRequestConfig()
    requestConfig.headers['Content-type'] = 'application/json'
    const { data } = await axios.post(`/dossiers/num/${numeroDossierC}/demander-subrogation`, formData, requestConfig)
    return data
}

export async function postRelanceAnnulerSubrogationDossier(numeroDossierC: string, formData: any): Promise<any> {
    const requestConfig = await getRequestConfig()
    requestConfig.headers['Content-type'] = 'application/json'
    const { data } = await axios.post(`/dossiers/num/${numeroDossierC}/annuler-subrogation`, formData, requestConfig)
    return data
}

export async function patchReporterDateDossier(idDossier: string, formData: DossierReporterDates): Promise<DossierReporterDates> {
    const requestConfig = await getRequestConfig()
    requestConfig.headers['Content-type'] = 'application/json'
    const { data } = await axios.patch(`/dossiers/${idDossier}/reporter-dates-formation`, formData, requestConfig)
    return data
}

export async function deleteDossier(idDossier: string): Promise<any> {
    const requestConfig = await getRequestConfig()
    const { data } = await axios.delete(`/dossiers/${idDossier}`, requestConfig)
    return data
}

export async function getDelaisTraitementDossier(prestataire = false): Promise<DelaiTraitementDossier[]> {
    const requestConfig = await getRequestConfig()
    const { data } = await axios.get<DelaiTraitementDossier[]>(`/delai-traitement-dossier${ prestataire ? '-prestataire' : ''}`, 
        Object.assign(requestConfig, { ignoreErrors: true }))
    return data
}

export interface RuptureDossierInfo {
    numeroDossier: string
    motif: string
    idMotif?: string
    dateRupture: string
    nom: string
    file: File | Blob
    fileName?: string
    nomCerfa?: string
    fileCerfa?: File | Blob
    fileNameCerfa?: string
    poursuite?: boolean
    newContrat?: boolean
    dateDebutStage?: string
    dateFinFormation?: string
    coutPedagogique?: string
}

export interface PoursuitePostRuptureDossierInfo {
    numeroDossier: string
    nomCerfa: string
    fileCerfa: File | Blob
    fileNameCerfa?: string
    poursuite?: boolean
    dateSortie?: string
    nouveauContrat?: boolean
    nouveauContratDateDebut?: string
    nouveauContratSiret?: string
    fraisAnnexesRestauration?: number
    fraisAnnexesHebergement?: number
    fraisAnnexesEquipementCout?: number
    majorationHandicapCout?: number
    fraisAnnexesInternational?: boolean
}

export type PostRuptureDossierInfo = {
    CoutEquipement: number | null
    MajorationHandicapIndividualisé: number | null
    MobilitInternationale: boolean
    Nb_Nuite: number | null
    Nb_Repas: number | null
}

export async function getPoursuitePostRuptureDossier(numeroDossier: string) {
    const requestConfig = await getRequestConfig()
    const { data } = await axios.get<PostRuptureDossierInfo>('/dossiers/capp/num/' + numeroDossier + '/demander-poursuite', requestConfig)
    return data
}

export async function postPoursuitePostRuptureDossier(info: PoursuitePostRuptureDossierInfo): Promise<any> {
    const requestConfig = await getRequestConfig()
    requestConfig.headers['Content-type'] = 'multipart/form-data'
    
    const name = (info.fileNameCerfa ?? (info.fileCerfa as any).name ?? info.nomCerfa).replaceAll(/[\\/|:*?"<>]/g, '_')
    const formData = createFormData({
        'nom-information': info.nomCerfa,
        'document-information': { file: info.fileCerfa, name },
        'poursuite-formation': info.poursuite?.toString(),
        'date-sortie': info.dateSortie,
        'contrat-autre-entreprise': info.nouveauContrat?.toString(),
        'contrat-date-debut': info.nouveauContratDateDebut,
        'contrat-siret': info.nouveauContratSiret,
        'frais-restauration': info.fraisAnnexesRestauration?.toFixed(0),
        'frais-hebergement': info.fraisAnnexesHebergement?.toFixed(0),
        'frais-equipement': info.fraisAnnexesEquipementCout?.toFixed(2),
        'majoration-handicap': info.majorationHandicapCout?.toFixed(2),
        'frais-international': info.fraisAnnexesInternational?.toString(),
    })
 
    const { data } = await axios.post('/dossiers/capp/num/' + info.numeroDossier + '/demander-poursuite', formData, requestConfig)
    
    return data
}

export async function postAnnulerDossier(numeroDossierC: string, nom: string,motif: string, file: File | Blob, fileName?: string): Promise<any> {

    const requestConfig = await getRequestConfig()
    requestConfig.headers['Content-type'] = 'multipart/form-data';
    const name = (fileName ?? (file as any).name ?? nom).replaceAll(/[\\/|:*?"<>]/g, '_')

    const formData = new FormData()
    formData.append('nom', nom)
    formData.append('motif', motif)
    formData.append('document', file, name)

    const { data } = await axios.post('/dossiers/capp/num/' + numeroDossierC + '/demander-annulation', formData, requestConfig)
    return data
}

export interface FNEData {
    Account: {
        Name: string
        Siren__c: string
        Adresse1__c: string
        Adresse2__c: string
        Adresse3__c: string
        Adresse4__c: string
        BillingPostalCode: string
        BillingCity: string
        Effectiftotal__c: number
        Categorie: string
    },
    Contact: {
        FirstName: string
        LastName: string
        Salutation: string
        FonctionMetier__c: string
        MobilePhone: string
        Phone: string
        Email: string
        DateNaissance__c: string
    },
    Modules: [{
        IntituleProgramme__c: string
        NomOF__c: string
        DateDebutFormation__c: string
        DateFinFormation__c: string
        DureeHeure__c: number
        MontantDemande__c: number
        MontantSubventionFNE: number
    }],
    Stagiaires: [{
        Nom__c: string
        Prenom__c: string
        DateNaissance__c: string
        NatureContrat__c: number
    }],
    Session: {
        Adresse1__c: string
        Adresse2__c: string
        Adresse3__c: string
        Adresse4__c: string
        CodePostal__c: string
        Ville__c: string
        Modalite__c: string
    }
}

export async function getFneData(numeroDossierC: string): Promise<FNEData> {
    const requestConfig = await getRequestConfig()
    const { data } = await axios.get<FNEData>('/dossiers/af/' + numeroDossierC + '/pdf', requestConfig)
    return data
}

export interface StagiaireDTO {
    IdHeroku: number | null
    Particulier__c: string | null
    Dossier__c: string | null
    Civilite__c: string | null
    Nom__c: string | null
    NomNaissance__c: string | null
    Prenom__c: string | null
    DateNaissance__c: Date | null
    CommuneNaissance__c: string | null
    DepartementNaissance__c: string | null
    CodePostalNaissance__c: string | null
    Email__c: string | null
    Telephone__c: string | null
    Sexe__c: string | null
    Nationalite__c: string | null
    Nir__c: string | null
    NumeroTechniqueTemporaire: boolean | null
    NirNonVerifieSNGI: boolean | null
    Pays__c: string | null
    CSP__c: string | null
    Situation__c: string | null
    Statut__c: string | null
    CategorieAction__c: string | null
    Handicap__c: '1' | '2' | null // 1 pour Oui, 2 pour Non
    SituationHandicap__c: string | null
    DateCreationFO__c: Date | null
    DateModificationFO__c: Date | null
    Adresse1__c: string | null
    Adresse2__c: string | null
    Adresse3__c: string | null
    Adresse4__c: string | null
    CodePostal__c: string | null
    Ville__c: string | null
    NomRepresentantLegal__c: string | null
    PrenomRepresentantLegal__c: string | null
    Adresse1RepresentantLegal__c: string | null
    Adresse2RepresentantLegal__c: string | null
    Adresse3RepresentantLegal__c: string | null
    Adresse4RepresentantLegal__c: string | null
    CodePostalRepresentantLegal__c: string | null
    CommuneRepresentantLegal__c: string | null
    DiplomePlusEleveObtenu__c: string | null
    DiplomeVise__c: string | null
    IntitulePrecisDiplome__c: string | null
    RegimeSocial__c: string | null
    ClasseApprenti__c: string | null
    DiplomeObtenu__c: string | null
    NatureContrat__c: string | null
    InscritPoleEmploi__c: boolean | null
    NumeroInscriptionPoleEmploi__c: string | null
    DureeMoisPoleEmploi__c: number | null
    TypeMinimumSocial__c: string | null
    InscriptionListeSportifs__c: boolean | null
    StatutEntreeEnFormationAgora__c: string | null
    NiveauMaximumClasseEntreeFormationAgora__c: string | null
    PublicSpecifique__c: boolean | null
}

export interface SalarieAFDTO extends StagiaireDTO {
    IdDossier?: string | null
    CSP__c: string | null
    NatureContrat__c: string | null
    NiveauFormation__c: string | null
    CategorieAction__c: string | null
    DateEntree__c: Date | null
    DureeHeure__c: number | null
    DureeHttHeure__c: number | null
    DureeFoadHeure__c: number | null
    DureeAFESTDemandee__c: number | null
    CoefficientHierarchique__c: string | null
    Metier__c: string | null
}

export type SalarieAFAgoraDTO = Omit<SalarieAFDTO, 'CategorieAction__c'>

export interface SalarieProADTO extends SalarieAFDTO {
    DureeHttHeure__c: number | null
    ObjectifParcours__c: string | null
    ContexteParcours__c: string | null
    DateFinParcours__c: Date | null
}

export interface DossierModuleDTO {
    IdHeroku: number | null
    EtablissementOFId__c: string | null
    CodeEtablissementOF__c: string | null
    EtatEtablissementOF__c: string | null
    Principal__c: boolean | null
    TypeFormation__c: string | null
    NomOF__c: string | null
    SiretOF__c: string | null
    Adresse1OF__c: string | null
    Adresse2OF__c: string | null
    Adresse3OF__c: string | null
    Adresse4OF__c: string | null
    CodePostalOF__c: string | null
    CommuneOF__c: string | null
    DateDebutFormation__c: Date | null
    DateFinFormation__c: Date | null
    NdaOF__c: string | null
    UaiOF__c: string | null
    NombreHeureFormation__c: number | null
    SubrogationPaiement__c: boolean | null
    CodeDiplome__c: string | null
    CodeRNCP__c: string | null
    FormationReferentielLBA__c: boolean | null
    RncpEligible__c: boolean | null
    // TypeQualification__c: string | null
    IntituleProgramme__c: string | null
    // UaiSiteOF__c: string | null
    // Dossier__c: string | null
    MobiliteInternationale__c: boolean | null
    ConditionRealisation__c: string | null
}

export interface DossierModuleCoutDTO {
    IdHeroku: number | null
    TypeCout__c: string | null
    MontantDemande__c: number | null
}

export interface DossierAFModuleDTO extends DossierModuleDTO {
    AttestationEmployeurFormationInterne__c: boolean | null
    TypeFormation__c: string | null
    TypesCout: DossierModuleCoutDTO[] | null
    // CommentaireFO__c: string | null
    NiveauFormationVise__c: string | null
    // ACN
    Reference__c: string | null
    SupportCours__c: boolean | null
    Certification__c: boolean | null
    // ACN (champs renseignés depuis l'API /stage)
    CoutStage__c: number | null
    CoutSupportCours__c: number | null
    CoutCertification__c: number | null
    CertificationDisponible__c: boolean | null
    CertificationObligatoire__c: boolean | null
    CoutCertificationRevise__c: number | null
    CoutSupportDeCoursRevise__c: number | null
    MinStagiaires__c: number | null
    MaxStagiaires__c: number | null
    DateDebutValidite__c: string | null
    DateFinValidite__c: string | null
    Modalite__c: string | null
    // CDIC
    Remuneration__c: number | null
    // FMA
    NumeroDossierApprentissage__c: string | null
    // PDC
    ContexteSpecifique__c: string | null
}

export interface DossierAFModuleAgoraDTO extends DossierAFModuleDTO {
    FormationCertifianteAgora__c: string | null
    ObjectifGeneralOfAgora__c: string | null
    ObjectifGeneralFormationAgora__c: string | null
}


// Dossier professionnalisation
export interface DossierProModuleDTO extends DossierModuleDTO {
    /* AttestationEmployeurFormationInterne__c: boolean | null
    TypeFormation__c: string | null
    SpecialiteFormation__c: string | null
    DureeHeureEvaluation__c: number | null
    DureeHeureEnseignement__c: number | null */
    TypesCout: DossierModuleCoutDTO[] | null
    ModalitePedagogiqueAgora__c: string | null
    AdresseRealisationIdentiqueAgora__c: boolean | null
    LieuPrincipalFormationAdresse1Agora__c: string | null
    LieuPrincipalFormationAdresse2Agora__c: string | null
    LieuPrincipalFormationAdresse3Agora__c: string | null
    LieuPrincipalFormationAdresse4Agora__c: string | null
    LieuPrincipalFormationCodePostalAgora__c: string | null
    LieuPrincipalFormationCommuneAgora__c: string | null
    FormationAFEST__c: boolean | null
}

// Module CAPP 
export interface DossierCappModuleDTO extends DossierModuleDTO {
    MontantMajorationHandicap__c: number | null
    ModalitePedagogiqueAgora__c: string | null
    AdresseRealisationIdentiqueAgora__c: boolean | null
    LieuPrincipalFormationAdresse1Agora__c: string | null
    LieuPrincipalFormationAdresse2Agora__c: string | null
    LieuPrincipalFormationAdresse3Agora__c: string | null
    LieuPrincipalFormationAdresse4Agora__c: string | null
    LieuPrincipalFormationCodePostalAgora__c: string | null
    LieuPrincipalFormationCommuneAgora__c: string | null
    AnneeFormationEntreeContrat__c: number | null
    NiveauOuEchelon__c: string | null
    CoefficientHierarchique__c: string | null
}

// Dossier professionnalisation
export interface DossierProAModuleDTO extends DossierModuleDTO {
    DateExamen__c: string | null
    CoutPedagogique__c: number | null    
    TypesCout: DossierModuleCoutDTO[] | null

    ModalitePedagogiqueAgora__c: string | null
    AdresseRealisationIdentiqueAgora__c: boolean | null
    LieuPrincipalFormationAdresse1Agora__c: string | null
    LieuPrincipalFormationAdresse2Agora__c: string | null
    LieuPrincipalFormationAdresse3Agora__c: string | null
    LieuPrincipalFormationAdresse4Agora__c: string | null
    LieuPrincipalFormationCodePostalAgora__c: string | null
    LieuPrincipalFormationCommuneAgora__c: string | null
}

export interface DossierSalairePeriodeDTO {
    IdHeroku: number | null
    Actif__c: boolean | null
    DateDebut__c: Date | null
    DateFin__c: Date | null
    Dossier__c: string | null
    Montant__c: number | null
    Periode__c: number | null
    Taux__c: number | null
    TypeMontant__c: '1' | '2' | null
}

export interface DossierCoreDTO {
    Id?: string | null
    IdHeroku: number | null
    NumeroDossier__c: string | null
    EnCoursTransmissionSOR__c?: boolean | null
    ErreurTransmissionSOR__c?: string | null
    DispositifFO__c: string | null
    EtatDossierFO__c: string | null
    EtablissementAdherentId__c: string | null
    EntrepriseAdherentId__c: string | null
    TypeDossier__c: string | null
    Intitule__c: string | null
    NomADH__c: string | null
    SiretADH__c: string | null
    Adresse1ADH__c: string | null
    Adresse2ADH__c: string | null
    Adresse3ADH__c: string | null
    Adresse4ADH__c: string | null
    CodePostalADH__c: string | null
    CommuneADH__c: string | null
    CodeIDCC__c: string | null
    CaisseRetraiteComplementaire__c: string | null
    CodeNAF__c: string | null
    NbSalariesEntreprise__c: number | null
    Pieces?: DossierPieceDTO[] | null
    TelephoneADH__c: string | null
    EmailADH__c: string | null
}

export interface DossierSendStatusDTO {
    Id?: string | null
    IdHeroku: number | null
    NumeroDossier__c: string | null
    Externalid_Heroku__c: string | null
    EnCoursTransmissionSOR__c: boolean | null
    ErreurTransmissionSOR__c: string | null
}

export interface PointReserve {
    CommentaireExtranet: string
    Id: number
    Type: string
    Rubrique: string | null
    Message: string
}

export interface DossierApprentissageDTO extends DossierCoreDTO {
    TypeEmployeur__c: string | null
    EmployeurSpecifique__c: string | null
    EmployeurPublic__c: boolean | null
    EmployeurAdhesionAuRegimeSpecifique__c: boolean | null
    DateDebutContrat__c: Date | null
    DateFinContrat__c: Date | null
    AutresAvantages__c: number | null
    AvantagesLogement__c: number | null
    AvantagesNourriture__c: number | null
    CFAEntreprise__c: boolean | null
    CoutEquipement__c: number | null
    DateEffetAvenantContrat__c: Date | null
    DateConclusionContrat__c: Date | null
    DateFormationPrealableTuteur__c: Date | null
    DateNaissanceTuteur__c: Date | null
    DateNaissanceTuteur2__c: Date | null
    DateSignature__c: Date | null
    EmailTuteur__c: string | null
    EmploiTuteur__c: string | null
    EmploiTuteur2__c: string | null
    EmployeurAttestePiecesJustificatives__c: boolean | null
    EmployeurAttesteSurHonneurTuteur__c: boolean | null
    EmployeurDirigeantTuteur__c: boolean | null
    FormationPrealableTuteur__c: boolean | null
    LieuSignature__c: string | null
    MachinesDangereuses__c: boolean | null
    MobiliteInternationale__c: boolean | null
    MobiliteLieu__c: number | null
    NatureEquipement__c: string | null
    NumeroPrecedentContrat__c: string | null
    NbAnneeExperienceTuteur__c: number | null
    NbNuite__c: number | null
    NbRepas__c: number | null
    NbSalariesSuiviTuteur__c: number | null
    NomNaissanceTuteur__c: string | null
    NomNaissanceTuteur2__c: string | null
    NomPrenomSignature__c: string | null
    Tuteur__c: string | null
    Tuteur2__c: string | null
    NomTuteur__c: string | null
    NomTuteur2__c: string | null
    PrenomTuteur__c: string | null
    PrenomTuteur2__c: string | null
    SexeTuteur__c: string | null
    SexeTuteur2__c: string | null
    FonctionTutoraleTuteur__c: boolean | null
    ExerciceFonctionMATuteurDeuxAnneesPrecedentes__c: boolean | null
    SpecialiteFormation__c: string | null
    TypeContrat__c: string | null
    TypeDerogation__c: string | null
    MotifReductionDureeContrat__c: string | null
    TypeModeContractuel__c: string | null
    CoutPedagogique__c: number | null
    
    Stagiaires: StagiaireDTO[] | null
    Modules: DossierCappModuleDTO[] | null
    Remunerations: DossierSalairePeriodeDTO[] | null
    RemunerationApprentissageModeManuelFO__c: boolean | null
    EmployeurAttesteSalaireplusFavorable__c: boolean | null

    DureeHebdomadaireTravailHeures__c: number | null
    DureeHebdomadaireTravailMinutes__c: number | null
    MontantSalaire__c: number | null    
    NomContactADH__c: string | null
    PrenomContactADH__c: string | null
    EtatDossierFO__c: string | null
    NumModificationContrat__c: number | null

    ConditionRealisation__c: string | null
    IndicateurConformite__c: string | null

    PointsReserve__c: PointReserve[] | null
}

export async function loadDossierApprentissage(numDossier: string): Promise<DossierApprentissageDTO> {
    const requestConfig = await getRequestConfig()
    const { data } = await axios.get<DossierApprentissageDTO>('/dossiers/capp/num/' + numDossier, requestConfig)
    return data
}

export async function saveDossierApprentissage(dossier: DossierApprentissageDTO): Promise<DossierApprentissageDTO> {
    const requestConfig = await getRequestConfig()
    const { data } = await (dossier.NumeroDossier__c ? 
        axios.patch<DossierApprentissageDTO>('/dossiers/capp/num/' + dossier.NumeroDossier__c, dossier, requestConfig) 
    :
        axios.post<DossierApprentissageDTO>('/dossiers/capp', dossier, requestConfig)
    )
    return data
}

export async function createDossierApprentissage(dossier: DossierApprentissageDTO): Promise<DossierApprentissageDTO> {
    const requestConfig = await getRequestConfig();
    const { data } = await axios.post<DossierApprentissageDTO>('/dossiers/capp', dossier, requestConfig);
    return data
}

export async function updateDossierApprentissage(dossier: DossierApprentissageDTO): Promise<DossierApprentissageDTO> {
    const requestConfig = await getRequestConfig();
    const { data } = await axios.patch<DossierApprentissageDTO>('/dossiers/capp/num/' + dossier.NumeroDossier__c, dossier, requestConfig);
    return data
}

export async function sendDossierApprentissage(dossier: DossierApprentissageDTO): Promise<DossierApprentissageDTO> {
    const requestConfig = await getRequestConfig();
    const { data } = await axios.post<DossierApprentissageDTO>(`/dossiers/capp/num/${dossier.NumeroDossier__c}/valider`, dossier, requestConfig);
    return data
}


export interface DossierProfessionnalisationDTO extends DossierCoreDTO {
    TypeDossier__c: string | null // DossierCoreDTO
    NumeroVersionCerfaCPRO__c: string | null // DossierCProDTO

    // Employeur
    OrganismePrevoyance__c: string | null // DossierCProDTO

    // Tuteur
    Tuteur_TT__c: boolean | null // DossierCProDTO
    Tuteur__c: string | null // DossierCProDTO
    NomTuteur__c: string | null // DossierCProDTO
    NomNaissanceTuteur__c: string | null // DossierCProDTO
    PrenomTuteur__c: string | null // DossierCProDTO
    DateNaissanceTuteur__c: Date | null // DossierCProDTO
    EmploiTuteur__c: string | null // DossierCProDTO
    CodeNAFEntrepriseUtilisatrice__c: string | null // DossierCProDTO
    EmployeurDirigeantTuteur__c: boolean | null // DossierCProDTO
    TutoratExerceTuteur__c: boolean | null // DossierCProDTO
    NbAnneeExperienceTuteur__c: number | null // --> Apprentissage
    NbSalariesSuiviTuteur__c: number | null // --> Apprentissage
    EmailTuteur__c: string | null // DossierCProDTO
    FormationPrealableTuteur__c: boolean | null // DossierCProDTO
    DateFormationPrealableTuteur__c: Date | null // DossierCProDTO
    FonctionTutoraleTuteur__c: boolean | null
    EmployeurAttesteSurHonneurTuteur__c: boolean | null

    // Formation
    TypeQualification__c: string | null
    DureeHeureEnseignement__c: number | null // DossierCProDTO
    DureeHeureEvaluation__c: number | null // DossierCProDTO
    SpecialiteFormation__c: string | null // DossierCProDTO
    EmploiOccupe__c: string | null
    DureeHebdomadaireTravailHeures__c: number | null
    DureeHebdomadaireTravailMinutes__c: number | null
    MontantSalaire__c: number | null
    NiveauCCN__c: string | null
    CoefficientCCN__c: string | null
    AttestationEmployeurFormationInterne__c: boolean | null
    DateDebutCycleFormation__c: Date | null
    DateExamen__c: Date | null
    NumeroProjet__c: string | null

    // Contrat
    NatureContrat__c: string | null // ???
    TypeContrat__c: string | null // DossierCProDTO
    DateDebutContrat__c: Date | null // DossierCoreDTO
    DateFinContrat__c: Date | null // DossierCoreDTO
    DureePeriodeEssai__c: number | null
    CoutPedagogique__c: number | null // DossierCProDTO
    DateEffetAvenantContrat__c: Date | null
    DateConclusionContrat__c: Date | null
    ClassificationEmploi__c: string | null
    AvenantContratTravailInitial__c: boolean | null

    // Nouveaux champs...
    NbOf__c: number | null
    FinancementRacPDC__c: boolean | null
    FinancementRacVV__c: boolean | null
    CPROExperimental__c: boolean | null
    EmployeurAttesteCPROExperimental__c: boolean | null
    EncadrementTiersFacilitateur__c: boolean | null
    EmployeurAttesteAFEST__c: boolean | null

    Stagiaires: StagiaireDTO[] | null
    Modules: DossierProModuleDTO[] | null

    // Signature 
    NomPrenomSignature__c: string | null // DossierCProDTO
    LieuSignature__c: string | null // DossierCProDTO
    DateSignature__c: Date | null // DossierCProDTO
    EtatDossierFO__c: string | null 
    NumModificationContrat__c: number | null 

    PointsReserve__c: PointReserve[] | null
}

export async function loadDossierProfessionnalisation(numDossier: string): Promise<DossierProfessionnalisationDTO> {
    const requestConfig = await getRequestConfig();
    const { data } = await axios.get<DossierProfessionnalisationDTO>('/dossiers/cpro/num/' + numDossier, requestConfig)
    return data
}

export async function saveDossierProfessionnalisation(dossier: DossierProfessionnalisationDTO): Promise<DossierProfessionnalisationDTO> {
    const requestConfig = await getRequestConfig()
    const { data } = await (dossier.NumeroDossier__c ? 
        axios.patch<DossierProfessionnalisationDTO>('/dossiers/cpro/num/' + dossier.NumeroDossier__c, dossier, requestConfig) 
    :
        axios.post<DossierProfessionnalisationDTO>('/dossiers/cpro', dossier, requestConfig)
    )
    return data
}

export async function createDossierProfessionnalisation(dossier: DossierProfessionnalisationDTO): Promise<DossierProfessionnalisationDTO> {
    const requestConfig = await getRequestConfig();
    const { data } = await axios.post<DossierProfessionnalisationDTO>('/dossiers/cpro', dossier, requestConfig);
    return data
}

export async function updateDossierProfessionnalisation(dossier: DossierProfessionnalisationDTO): Promise<DossierProfessionnalisationDTO> {
    const requestConfig = await getRequestConfig();
    const { data } = await axios.patch<DossierProfessionnalisationDTO>('/dossiers/cpro/num/' + dossier.NumeroDossier__c, dossier, requestConfig);
    return data
}

export async function sendDossierProfessionnalisation(dossier: DossierProfessionnalisationDTO): Promise<DossierProfessionnalisationDTO> {
    const requestConfig = await getRequestConfig();
    const { data } = await axios.post<DossierProfessionnalisationDTO>(`/dossiers/cpro/num/${dossier.NumeroDossier__c}/valider`, dossier, requestConfig);
    return data
}


export interface DossierAFDTO extends DossierCoreDTO {
    Stagiaires: SalarieAFDTO[] | null
    Modules: DossierAFModuleDTO[] | null
    CommentaireFO__c: string | null

    // ACN
    ReferenceSource__c: string | null
    CampusSession__c: string | null

    NomPrenomSignature__c: string | null // DossierCProDTO
    DateSignature__c: Date | null // DossierCProDTO
    LieuSignature__c: string | null // DossierCProDTO
}

// export interface DossierAFAgoraDTO extends DossierAFDTO {
//     Modules: DossierAFModuleAgoraDTO[] | null
// }

export async function loadDossierAF(numDossier: string): Promise<DossierAFDTO> {
    const requestConfig = await getRequestConfig()
    const { data } = await axios.get<DossierAFDTO>('/dossiers/af/num/' + numDossier, requestConfig)
    if ((data?.Stagiaires?.length ?? 0) > 1) {
        data!.Stagiaires!.sort((a, b) => {
            if (a.Nom__c === null)
                return -1
            if  (b.Nom__c === null)
                return 1
            let compared = a.Nom__c.localeCompare(b.Nom__c)
            if (compared === 0) {
                if (a.Prenom__c === null)
                    return -1
                if  (b.Prenom__c === null)
                    return 1
                compared = a.Prenom__c.localeCompare(b.Prenom__c)
            }
            return compared
        })
    }
    return data
}

export async function saveDossierAF(dossier: DossierAFDTO): Promise<DossierAFDTO> {
    const requestConfig = await getRequestConfig()
    const { data } = await (dossier.NumeroDossier__c ? 
        axios.patch<DossierAFDTO>('/dossiers/af/num/' + dossier.NumeroDossier__c, dossier, requestConfig) 
    :
        axios.post<DossierAFDTO>('/dossiers/af', dossier, requestConfig)
    )
    return data
}

export async function createDossierAF(dossier: DossierAFDTO): Promise<DossierAFDTO> {
    const requestConfig = await getRequestConfig()
    const { data } = await axios.post<DossierAFDTO>('/dossiers/af', dossier, requestConfig)
    // data.NumeroDossier__c = 'B-000001'
    return data
}

export async function updateDossierAF(dossier: DossierAFDTO): Promise<DossierAFDTO> {
    const requestConfig = await getRequestConfig()
    const { data } = await axios.patch<DossierAFDTO>('/dossiers/af/num/' + dossier.NumeroDossier__c, dossier, requestConfig)
    // data.NumeroDossier__c = dossier.NumeroDossier__c
    return data
}

export async function sendDossierAF(dossier: DossierAFDTO): Promise<DossierAFDTO> {
    const requestConfig = await getRequestConfig()
    const { data } = await axios.post<DossierAFDTO>(`/dossiers/af/num/${dossier.NumeroDossier__c}/valider`, dossier, requestConfig)
    return data
}


export interface DossierProADTO extends DossierCoreDTO {
    // Employeur
    LibelleCCN__c: string | null
    NomContactADH__c: string | null
    PrenomContactADH__c: string | null

    Stagiaires: StagiaireDTO[]
    Modules: DossierProAModuleDTO[]

    // Tuteur
    Tuteur__c: string | null
    NomTuteur__c: string | null
    NomNaissanceTuteur__c: string | null
    PrenomTuteur__c: string | null
    DateNaissanceTuteur__c: Date | null
    NbAnneeExperienceTuteur__c: number | null
    NbSalariesSuiviTuteur__c: number | null
    EmployeurDirigeantTuteur__c: boolean | null
    EmployeurAttesteSurHonneurTuteur__c: boolean | null

    // Formation
    AttestationEmployeurFormationInterne__c: boolean | null
    TypeQualification__c: string | null
    SpecialiteFormation__c: string | null // DossierCProDTO
    DureeHeureEnseignement__c: number | null // DossierCProDTO
    DureeHeureEvaluation__c: number | null // DossierCProDTO
    DateExamen__c: Date | null
    DureeHebdomadaireTravailHeures__c: number | null
    DureeHebdomadaireTravailMinutes__c: number | null
    CoutPedagogique__c: number | null // DossierCProDTO

    // Contrat
    EmploiOccupe__c: string | null
    DateDebutExecutionContrat__c: Date | null
    DateFinContrat__c: Date | null
    ClassificationEmploi__c: string | null
    NiveauCCN__c: string | null
    CoefficientCCN__c: string | null
    TypeProA__c: string | null
    VAEProA__c: boolean | null
    TempsTravailProA__c: boolean | null
    ClassificationEmploiProA__c: string | null
    EmploiViseProA__c: string | null
    NiveauCCNProA__c: string | null
    CoefficientCCNProA__c: string | null

    // Signature 
    NomPrenomSignature__c: string | null // DossierCProDTO
    LieuSignature__c: string | null // DossierCProDTO
    DateSignature__c: Date | null // DossierCProDTO

    // Autres
    EtatDossierFO__c: string | null 
    NumModificationContrat__c: number | null 
}

export async function loadDossierProA(numDossier: string): Promise<DossierProADTO> {
    const requestConfig = await getRequestConfig()
    const { data } = await axios.get<DossierProADTO>('/dossiers/proa/num/' + numDossier, requestConfig)
    return data
}

export async function saveDossierProA(dossier: DossierProADTO): Promise<DossierProADTO> {
    const requestConfig = await getRequestConfig()
    const { data } = await (dossier.NumeroDossier__c ? 
        axios.patch<DossierProADTO>('/dossiers/proa/num/' + dossier.NumeroDossier__c, dossier, requestConfig) :
        axios.post<DossierProADTO>('/dossiers/proa', dossier, requestConfig)
    )
    return data
}

export async function createDossierProA(dossier: DossierProADTO): Promise<DossierProADTO> {
    const requestConfig = await getRequestConfig()
    const { data } = await axios.post<DossierProADTO>('/dossiers/proa', dossier, requestConfig)
    // data.NumeroDossier__c = 'B-000001'
    return data
}

export async function updateDossierProA(dossier: DossierProADTO): Promise<DossierProADTO> {
    const requestConfig = await getRequestConfig()
    const { data } = await axios.patch<DossierProADTO>('/dossiers/proa/num/' + dossier.NumeroDossier__c, dossier, requestConfig)
    // data.NumeroDossier__c = dossier.NumeroDossier__c
    return data
}

export async function sendDossierProA(dossier: DossierProADTO): Promise<DossierProADTO> {
    const requestConfig = await getRequestConfig()
    const { data } = await axios.post<DossierProADTO>(`/dossiers/proa/num/${dossier.NumeroDossier__c}/valider`, dossier, requestConfig)
    return data
}


export async function getDossierSendStatus(id: string): Promise<DossierSendStatusDTO> {
    const requestConfig = await getRequestConfig();
    const { data } = await axios.get<DossierSendStatusDTO>('/dossiers/' + id + '/resume', requestConfig)
    return data
}

export async function sendAvenantCorrection(idDosssier: string, typeModificationContrat: string, entrepriseAdherentId: string, numModificationContrat:string, nom: string, file: File | Blob, fileName?: string): Promise<any> {
    const requestConfig = await getRequestConfig()
    requestConfig.headers['Content-type'] = 'multipart/form-data'

    const name = (fileName ?? (file as any).name ?? nom).replaceAll(/[\\/|:*?"<>]/g, '_')
    
    const formData = new FormData()
    formData.append('nom', nom)
    formData.append('document', file, name)
    formData.append('TypeModificationContrat__c', typeModificationContrat)
    formData.append('EntrepriseAdherentId__c', entrepriseAdherentId)
    formData.append('NumModificationContrat__c', numModificationContrat)
    const { data } = await axios.post(`/dossiers/num/${idDosssier}/corriger-contrat`, formData, requestConfig)
    return data
}

export async function postTransmettrecertificatDossier(numeroDossierC: string, nom: string, file: File , fileName?: string): Promise<any> {

    const requestConfig = await getRequestConfig()
    requestConfig.headers['Content-type'] = 'multipart/form-data';
    const name = (fileName ?? (file as any).name ?? nom).replaceAll(/[\\/|:*?"<>]/g, '_')

    const formData = new FormData()
    formData.append('nom', nom)
    formData.append('document', file, name)

    const { data } = await axios.post('/dossiers/capp/num/' + numeroDossierC + '/transmettre-certificat-realisation-final', formData, requestConfig)
    return data
}