import { Service } from "api/referencesAPI"
import ExtensionPoint from 'app/ExtensionPoint'
import { useAppState } from 'app/useAppState'
import { AtlasColumns, AtlasFlex, AtlasGrid, AtlasHeading, AtlasInfo, AtlasSpacer, MyAppChoice, MyCentered, MyProduct, MyTemplate } from "atlas-ds"
import barometreAtlasImg from "atlas-ds/my/assets/img/services/barometre.svg"
import cCertifImg from "atlas-ds/my/assets/img/services/c_certif.svg"
import eCertifImg from "atlas-ds/my/assets/img/services/e_certif.svg"
import Link from "components/Link"
import PageHeader from "components/PageHeader"

export type AccueilCompteExtension = {
    accueilCompte: {
        appAccess?: JSX.Element
        attach?: JSX.Element
    }
}

export function AccueilCompte() {
    const appState = useAppState()
    const { services, profil, appTypes } = appState
 
    const servicesAvailable = [
        {
            Id: "bprix",
            img: barometreAtlasImg
        },
        {
            Id: "parccert",
            img: cCertifImg
        },
        {
            Id: "e-certif",
            img: eCertifImg
        }
    ]

    // Construire la liste de service proposée à l'utilisateur en combinant
    // servicesAvailable et les services renvoyés par le back
    const servicesEnabled = servicesAvailable.reduce((_servicesEnabled: (Service & { img: string })[], serviceAvailable) => {
        const service = services?.find(_service => _service.Id === serviceAvailable.Id)

        if (service) {
            _servicesEnabled.push({ ...serviceAvailable, ...service })
        }

        return _servicesEnabled
    }, [])

    const firstName = profil?.Contact.FirstName
        ? profil.Contact.FirstName[0].toUpperCase() + profil.Contact.FirstName.slice(1)
        : '';

    return <>
        <PageHeader>
            {`Bonjour <strong>${firstName}</strong>, <br/>bienvenue sur votre espace myAtlas`}
        </PageHeader>

        {profil?.EntreprisesProspect && profil.EntreprisesProspect.length > 0 && (
            <>
                <AtlasColumns>
                    <AtlasInfo
                        type="info"
                        title={`Entreprise${profil.EntreprisesProspect.length > 1 ? 's' : ''} en cours de webadhésion`}
                    >
                        <p>Vous avez fait une demande de webadhésion pour {profil.EntreprisesProspect.length > 1 ? "les entreprises suivantes" : "l'entreprise suivante"} :</p>
                        <ul>
                            {profil.EntreprisesProspect.map(entreprise => (
                                entreprise.WebAdhesionRefusee__c === false && (
                                    <li key={entreprise.Id}>
                                        <strong>{entreprise.Name}</strong> ({entreprise.SIREN__c})
                                    </li>
                                )
                            ))}
                        </ul>
                        <p>Vous recevrez prochainement un mail vous informant de {profil.EntreprisesProspect.length > 1 ? "leur" : "son"} traitement.</p>
                    </AtlasInfo>
                </AtlasColumns>

                <AtlasSpacer size="xl" />
            </>
        )}

        {(appTypes?.length ?? 0) === 0 ? <>
            {(profil?.EntreprisesProspect ?? []).length === 0 && <AtlasColumns>
                <p>Pour profiter des services en ligne Atlas (myAtlas Entreprise et myAtlas Prestataire), attachez votre première entreprise ou prestataire de formation à votre compte&nbsp;:</p>
            </AtlasColumns>}

            <AtlasSpacer size="l" />

            <MyTemplate.Breakout>
                <MyAppChoice
                    options={[
                        {
                            app: "entreprise",
                            label: "Vous représentez une entreprise adhérente&nbsp;? Attachez votre entreprise et bénéficiez de nombreux services en ligne et du suivi de toutes vos demandes.",
                            link: <Link to="/mes-entreprises/attacher" icon="plus">Attacher une entreprise</Link>
                        },
                        {
                            app: "prestataire",
                            label: "Vous représentez un prestataire de formation (organisme de formation, CFA...)&nbsp;? Simplifiez vos démarches en attachant votre organisme en tant que prestataire.",
                            link: <Link to="/mes-prestataires/attacher" icon="plus">Attacher un prestataire</Link>
                        }
                    ]}
                />
            </MyTemplate.Breakout>
        </> : <>
            <AtlasSpacer size="l" />

            <MyTemplate.Breakout>
                <AtlasFlex column gap="xxl">
                    <ExtensionPoint<AccueilCompteExtension> name="accueilCompte" point="appAccess" />
                </AtlasFlex>
            </MyTemplate.Breakout>

            {(appTypes?.length ?? 0) === 1 && <>
                <AtlasSpacer />
                <MyCentered>
                    <ExtensionPoint<AccueilCompteExtension> name="accueilCompte" point="attach" />
                </MyCentered>
            </>}

            <AtlasSpacer size="xxl" />

            {servicesEnabled.length > 0 && <>
                <AtlasHeading size="m" tag="h2">Mes produits</AtlasHeading>

                <AtlasGrid columnsCount={4} gap="m">
                    {servicesEnabled.map(service =>
                        <MyProduct
                            key={service.Id}
                            href={process.env.NODE_ENV === 'production' ? service.URL__c : service.URLRecette__c}
                            logo={service.img}
                            label={service.LibelleReduit__c}
                        >{ service.Libelle__c }</MyProduct>
                    )}
                </AtlasGrid>
            </>}
        </>}
    </>
}

