import { useEffect, useState } from 'react'

type CheckCallback = (isSupported: boolean) => void

var IsSupported: boolean | undefined

// Adapté de https://levelup.gitconnected.com/react-hook-to-check-third-party-cookies-enabled-in-the-browser-e542ce73fff3
export const useThirdPartyCookieCheck = (callback: CheckCallback) => {

    useEffect(() => {
        if (IsSupported !== undefined) {
            callback(IsSupported)
            return
        }
        
        const frame = document.createElement("iframe")
        frame.id = "3pc"
        frame.src = "https://service-si-opco-atlas.github.io/check-third-party-cookie/"
        frame.style.display = "none"
        frame.style.position = "fixed"
        document.body.appendChild(frame)

        window.addEventListener(
            "message",
            function listen(event) {
                if (event.data === "3pcSupported" || event.data === "3pcUnsupported") {
                    const supported = event.data === "3pcSupported"
                    IsSupported = supported
                    document.body.removeChild(frame)
                    window.removeEventListener("message", listen)
                    callback(supported)
                }
            },
            false
        )
    }, [])
}