import { CompteSel, getDetailEntreprise } from "api/monCompteAPI"
import { TYPE_ENTREPRISE } from "api/references"
import useApiState from "api/useApiState"
import { useAppState } from "app/useAppState"
import { AtlasBreadcrumb, AtlasButton, AtlasColumns, AtlasHeading, AtlasInfo, AtlasLoading, AtlasSpacer, AtlasText, AtlasValue, AtlasValues, MyContainer, MyTable } from "atlas-ds"
import { linkConstructor } from "components/Link"
import PageHeader from "components/PageHeader"
import { useEffect, useState } from "react"
import { useParams } from "react-router-dom"
import DetacherEntreprise from "./DetacherEntreprise"
import DetacherEntrepriseExterne from "./DetacherEntrepriseExterne"

interface DetailEntrepriseProps {
    type: string
}

export default function DetailEntreprise(props: DetailEntrepriseProps) {
    const [{ pending, value, error }, withGetDetailEntreprise] = useApiState(getDetailEntreprise)
    const { idEntreprise } = useParams<{ idEntreprise: string }>()
    const { profil } = useAppState()
    const highlightAccountId = new URLSearchParams(document.location.search).get("highlight-account")

    useEffect(() => {
        if (idEntreprise) {
            withGetDetailEntreprise(idEntreprise, props.type === TYPE_ENTREPRISE ? "entreprise" : "prestataire");
        }
    }, [idEntreprise, withGetDetailEntreprise])

    const [detacherCompte, setDetacherCompte] = useState<CompteSel | null>(null);

    const entrepriseName = error
        ? (props.type === TYPE_ENTREPRISE ? "Entreprise inconnue" : "Prestataire inconnu")
        : (value?.AccountDTO.Name ?? '')

    return <AtlasLoading loading={pending}>
        <PageHeader
            breadcrumb={ <AtlasBreadcrumb
                currentPageLabel={ entrepriseName }
                parts={[{
                    label: props.type === TYPE_ENTREPRISE ? "Mes entreprises" : "Mes prestataires",
                    href: props.type === TYPE_ENTREPRISE ? "/mes-entreprises" : "/mes-prestataires"
                }]}
                linkConstructor={linkConstructor}
            />}
        >{ entrepriseName }</PageHeader>

        <AtlasColumns>
            { error ? <AtlasInfo type="error" title="Entreprise inconnue">{ error }</AtlasInfo> : <div>
                <AtlasHeading size="m" tag="h2">Informations principales</AtlasHeading>
                <MyContainer>
                    <AtlasValues>
                        <AtlasValues.Grid>
                            <AtlasValue label="Raison sociale">{ value?.AccountDTO.Name }</AtlasValue>
                            <AtlasValue label="Siren">{value?.AccountDTO.SIREN__c}</AtlasValue>
                            <AtlasValue label={props.type === TYPE_ENTREPRISE ? "Code adhérent" : "Code OF"}>
                                {value?.AccountDTO.CodeAdherent__c}
                            </AtlasValue>
                        </AtlasValues.Grid>
                    </AtlasValues>
                </MyContainer>

                <AtlasSpacer size="xl" />

                <AtlasHeading tag="h3" size="m">Comptes myAtlas attachés</AtlasHeading>

                    <MyTable
                        caption={"Mes entreprises"}
                        rows={value?.AccountDTO.ComptesSEL ?? []}
                        emptyText={"Aucun compte myAtlas attaché"}
                        rowActions={((row, index) => [<AtlasButton
                            key="detacher"
                            level={3}
                            icon="logout"
                            onClick={() => setDetacherCompte(row)}
                        >{`Détacher ${ index === 0 ? "mon" : "le" } compte`}</AtlasButton>] )}
                        rowHighlight={(row) => row.Id === highlightAccountId}
                        columns={[
                            {
                                id: "LastName",
                                label: "Nom",
                                value: (row) => <AtlasText bold={row.Id === profil?.Contact.Id  }>{row.LastName}</AtlasText>
                            },
                            {
                                id: "FirstName",
                                label: "Prénom",
                                value: (row) => <AtlasText bold={row.Id === profil?.Contact.Id  }>{row.FirstName}</AtlasText>
                            },
                            {
                                id: "Email",
                                label: "E-Mail",
                                value: (row) => <AtlasText bold={row.Id === profil?.Contact.Id  }>{row.Email}</AtlasText>
                            },

                        ]}
                    />

                <AtlasSpacer size="xl" />
            </div> }
        </AtlasColumns>

        { detacherCompte && detacherCompte?.Id !== profil?.Contact.Id && value && <DetacherEntrepriseExterne
            type={ props.type }
            entreprise={ value }
            compte={ detacherCompte }
            onClose={ () => setDetacherCompte(null) }
            onSuccess={ () => withGetDetailEntreprise(idEntreprise, props.type === TYPE_ENTREPRISE ? "entreprise" : "prestataire") }
        /> }

        { detacherCompte && detacherCompte?.Id === profil?.Contact.Id && value && <DetacherEntreprise
            type={ props.type }
            entreprise={ value }
            onClose={ () => setDetacherCompte(null) }
        /> }
    </AtlasLoading>
}
