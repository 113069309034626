import { AtlasInfo } from 'atlas-ds'
import { DetailDossierExtension } from 'pages/detail-dossier/DetailDossierContrat'
import { DetailDossierNumeroDepot } from 'pages/detail-dossier/DetailDossierNumeroDepot'
import { AppDossierModule } from 'pages/detail-dossier/modules'
import { actionsAnnulerDossier, actionsEntrepriseCorrectionAvenant, actionsEntrepriseDeposerFacture } from 'pages/detail-dossier/modules/entreprise'
import { actionsPrestataireDeposerProforma, actionsPrestataireRuptureDeposerFacture } from 'pages/detail-dossier/modules/prestataire'
import { actionsEntrepriseRelanceAnnulerSubrogation, actionsEntrepriseRelanceCloture, actionsEntrepriseRelanceDemanderSubrogation, actionsEntrepriseRelanceRupture } from 'pages/detail-dossier/relanceff/entreprise'
import { actionsPrestataireRelanceAnnulation, actionsPrestataireRelanceAnnulerSubrogation, actionsPrestataireRelanceCloture, actionsPrestataireRelanceRupture } from 'pages/detail-dossier/relanceff/prestataire'
import { MetadataEditCPRO } from 'pages/dossier-cpro/CPROEditFormMetadata'
import { MetadataCPRO } from 'pages/dossier-cpro/CPROFormMetadata'
import { CPROMAPRuptureFormMetadata, CPRORuptureFormMetadata } from 'pages/dossier-cpro/CPRORuptureFormMetadata'


const dossierCPROModule: AppDossierModule = {
    name: 'CPRO',
    dispositifId: MetadataCPRO.dispositif,
    metadata: {
        entreprise: { create: MetadataCPRO, edit: MetadataEditCPRO },
    },
    extensions: {
        DetailDossierContrat: {
            infos: <DetailDossierNumeroDepot key="numeroDepot" />,
        },
        entreprise: {
            DetailDossierContrat: {
                actions: (dossier, actions) => {
                    actionsEntrepriseCorrectionAvenant(dossier, actions)
                    
                    actionsAnnulerDossier(dossier, actions)
                    actionsEntrepriseDeposerFacture(dossier, actions)
     
                    actionsEntrepriseRelanceRupture(dossier, CPRORuptureFormMetadata, actions)
                    actionsEntrepriseRelanceCloture(dossier, actions)               
                    actionsEntrepriseRelanceDemanderSubrogation(dossier, actions)
                    actionsEntrepriseRelanceAnnulerSubrogation(dossier, actions)
                },
            },
        } as DetailDossierExtension,
        prestataire: {
            DetailDossierContrat: {
                actions: (dossier, actions) => {
                    actionsPrestataireDeposerProforma(dossier, actions)
                    actionsPrestataireRuptureDeposerFacture(dossier, actions)

                    actionsPrestataireRelanceRupture(dossier, CPROMAPRuptureFormMetadata, actions)
                    actionsPrestataireRelanceCloture(dossier, actions)
                    actionsPrestataireRelanceAnnulerSubrogation(dossier, actions)
                    actionsPrestataireRelanceAnnulation(dossier, actions)
                }
            },
        } as DetailDossierExtension,
    } as DetailDossierExtension,
    documentTypeFilter: docType => {
        return docType.nom !== 'CERFA' && docType.nom !== 'AVENANT AU CONTRAT' && docType.nom !== 'COPIE LETTRE RUPTURE CONTRAT' 
    },
    documentWarningMessage: <AtlasInfo type="warning" title="Le CERFA a été automatiquement généré et joint à votre demande">
        <p>Merci de ne pas ajouter le CERFA original signé dans les pièces. En effet, les informations transmises sur myAtlas sont réputées conformes au contrat conclu avec le salarié.</p>
        <details>
            <summary>Une modification à transmettre ?</summary>
            <p>Pour toute modification de la demande à transmettre à l’Opco Atlas, merci d’utiliser le formulaire dédié, accessible dans la partie « Actions » en haut à droite de cette page :</p>
            <ul>
                <li>Avenant/correction : formulaire « Transmettre avenant / Corriger contrat »</li>
                <li>Rupture du contrat : formulaire « Demander la rupture du contrat »</li>
                <li>Annulation du contrat : formulaire « Annuler le dossier »</li>
            </ul>
        </details>
    </AtlasInfo>
}

export default dossierCPROModule