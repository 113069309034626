import { DossierDetail } from 'api/dossierAPI'
import { DEMANDE_EN_COURS_AJOUT_SUBROGATION, DEMANDE_EN_COURS_ANNULATION, DEMANDE_EN_COURS_ANNULATION_SUBROGATION, DEMANDE_EN_COURS_CLOTURE, DEMANDE_EN_COURS_MODIFICATION_DATES, DEMANDE_EN_COURS_RUPTURE, ETAT_DOSSIER_CLOTURE, ETAT_DOSSIER_RUPTURE } from 'api/references'
import DossierRelanceModifierDates from './DossierRelanceModifierDates'
import { DossierRelanceCloturer } from './DossierRelanceCloturer'
import { DossierRelanceAnnuler } from './DossierRelanceAnnuler'
import { DossierRelanceSubrogationAnnuler } from './DossierRelanceSubrogation'
import { peutRelancerDossier } from '.'
import { FactureDetail } from 'api/factureAPI'
import { DossierRelanceRupture } from './DossierRelanceRupture'
import { RuptureFormMetadata } from 'pages/dossier/metadata/RuptureFormMetadata'
import { peutRompreDossier } from '../modules'

const alertesMessagesPrestataire = (dossier: DossierDetail | FactureDetail | null) => {
    const messages = []
    const alertes = dossier?.TypeAlerteOF__c?.split(',')?.map(t => parseInt(t))
    if (alertes?.some(type => type >= 1326 && type <= 1329))
        messages.push("Formation terminée mais solde OF restant")
    if (alertes?.some(type => type === 5000))
        messages.push("Dossier incomplet")
    if (alertes?.some(type => type === 6000))
        messages.push("Facture incomplète")
    return messages
}

const demandesMessagesPrestataire = (dossier: DossierDetail | null) => {
    const messages: string[] = []
    dossier?.DemandesEnCours?.forEach(type => {
        if (type === DEMANDE_EN_COURS_ANNULATION)
            messages.push("Demande d'annulation")
        else if (type === DEMANDE_EN_COURS_CLOTURE)
            messages.push("Demande de clôture - réalisation partielle")
        else if (type === DEMANDE_EN_COURS_ANNULATION_SUBROGATION)
            messages.push("Demande d'annulation de subrogation")
        else if (type === DEMANDE_EN_COURS_AJOUT_SUBROGATION)
            messages.push("Demande de subrogation")
        else if (type === DEMANDE_EN_COURS_MODIFICATION_DATES)
            messages.push("Demande de modification des dates de formation")
        else if (type === DEMANDE_EN_COURS_RUPTURE)
            messages.push("Information de rupture")
    })
    return messages
}

export const relanceMessagesPrestataire = (dossier: DossierDetail) => {
    return {
        demandes: demandesMessagesPrestataire(dossier),
        alertes: alertesMessagesPrestataire(dossier),
    }
}

const alertesPrestataireTermineNonSolde = (dossier: DossierDetail) => {
    const alertes = dossier?.TypeAlerteOF__c?.split(',')?.map(t => parseInt(t))
    return alertes?.some(type => type >= 1326 && type <= 1329) === true
}

const peutCloturerDossier = (dossier: DossierDetail) => {
    if (!peutRelancerDossier(dossier)) return false

    return dossier.EtatDossierFO__c !== ETAT_DOSSIER_CLOTURE &&
        dossier.SubrogationPaiement__c === true &&
        // dossier.DemandesEnCours?.includes(DEMANDE_EN_COURS_CLOTURE) !== true &&
        alertesPrestataireTermineNonSolde(dossier) &&
        (dossier.MontantCoutFormationPaye__c ?? 0) > 0.01
}

const peutAnnulerSubrogationDossier = (dossier: DossierDetail) => {
    if (!peutRelancerDossier(dossier)) return false

    return dossier.SubrogationPaiement__c === true &&
        // dossier.DemandesEnCours?.includes(DEMANDE_EN_COURS_ANNULATION_SUBROGATION) !== true &&
        alertesPrestataireTermineNonSolde(dossier) &&
        (dossier.MontantCoutFormationPaye__c ?? 0) < 0.01
}

const peutModifierDatesDossier = (dossier: DossierDetail) => {
    if (!peutRelancerDossier(dossier)) return false

    return dossier.SubrogationPaiement__c === true &&
        // dossier.DemandesEnCours?.includes(DEMANDE_EN_COURS_MODIFICATION_DATES) !== true &&
        alertesPrestataireTermineNonSolde(dossier)
}

const peutAnnulerDossier = (dossier: DossierDetail) => {
    if (!peutRelancerDossier(dossier)) return false

    return dossier.SubrogationPaiement__c === true &&
        alertesPrestataireTermineNonSolde(dossier) &&
        (dossier.MontantCoutFormationPaye__c ?? 0) < 0.01
}

export const actionsPrestataireRelanceCloture = (dossier: DossierDetail, actions: JSX.Element[]) => {
    actions.push(<DossierRelanceCloturer key="cloturer" of detail={ dossier } when={ peutCloturerDossier } />)
}

export const actionsPrestataireRelanceAnnulerSubrogation = (dossier: DossierDetail, actions: JSX.Element[]) => {
    actions.push(<DossierRelanceSubrogationAnnuler key="annulerSubrogation" of detail={ dossier } when={ peutAnnulerSubrogationDossier } />)
}

export const actionsPrestataireRelanceModifierDates = (dossier: DossierDetail, actions: JSX.Element[]) => {
    actions.push(<DossierRelanceModifierDates key="modifierDatesFormation" of detail={ dossier } when={ peutModifierDatesDossier } />)
}

export const actionsPrestataireRelanceAnnulation = (dossier: DossierDetail, actions: JSX.Element[]) => {
    actions.push(<DossierRelanceAnnuler key="annuler" of detail={ dossier } when={ peutAnnulerDossier } />)
}

export const actionsPrestataireRelanceRupture = (dossier: DossierDetail, metadata: RuptureFormMetadata<any>, actions: JSX.Element[]) => {
    actions.push(<DossierRelanceRupture key="rupture" of detail={ dossier } when={ peutRompreDossier } formMetadata={ metadata } />)
}
